<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="card mb-5 w-100">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4
            class="mb-0 text-primary"
          >
            <i
              class="ion ion-ios-paper"
            /> {{ $t('opportunities.editOpportunity.content.textBox.title') }}
            <b-badge v-if="content.tempId">
              {{ $t('opportunities.editOpportunity.content.newText') }}
            </b-badge>
          </h4>
        </div>
        <div class="d-flex p-2 w-50 flex-row justify-content-end">
          <opp-edit-buttons
            v-if="canEdit"
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="save"
          />
          <cp-button
            v-if="canDelete"
            class=""
            variant="primary"
            icon="ion ion-ios-trash"
            @click="deleteAction"
          >
            {{ $t('opportunities.editOpportunity.content.deleteConfirmation.deleteButton') }}
          </cp-button>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
      >
        <div class="row">
          <div class="col-md-12">
            <cp-input
              key="title"
              v-model="model.title"
              :label="$t('opportunities.editOpportunity.content.textBox.inputs.title.label')"
              :placeholder="$t('opportunities.editOpportunity.content.textBox.inputs.title.placeholder')"
              name="title"
              :max-length="content.side === 'right' ? 40 : null"
              validate="required"
            />
          </div>
          <div class="col-md-12">
            <cp-html-editor
              v-if="content.side === 'left'"
              :value="model.content"
              name="content"
              :label="$t('opportunities.editOpportunity.content.textBox.inputs.description.label')"
              validate="required"
              @input="changeContent"
            />
            <cp-textarea
              v-if="content.side === 'right'"
              v-model="model.content"
              :label="$t('opportunities.editOpportunity.content.videoBox.inputs.description.label')"
              :placeholder="$t('opportunities.editOpportunity.content.textBox.inputs.description.label')"
              name="content"
              :max-length="405"
              :rows="5"
            />
            <cp-input
              key="order"
              v-model="model.order"
              class="mb-3"
              input-type="number"
              :label="$t('opportunities.editOpportunity.content.videoBox.inputs.order.label')"
              :placeholder="$t('opportunities.editOpportunity.content.videoBox.inputs.order.placeholder')"
              name="order"
              validate="required"
            />

            <content-visibility
              :is-edit="editMode"
              :show-to-verified-investors-only="model.showToVerifiedInvestorsOnly"
              :show-in-opportunity="model.showInOpportunity"
              :show-in-token-details="model.showInTokenDetails"
              @valueChanged="onContentVisibilityChanged"
            />
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-else
      class="card-body"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>{{ content.title }}</h5>
        </div>
        <div class="col-md-12">
          <p v-html="content.content" />
          <p> {{ content.order }} </p>
          <content-visibility
            :is-edit="editMode"
            :show-to-verified-investors-only="content.showToVerifiedInvestorsOnly"
            :show-in-opportunity="content.showInOpportunity"
            :show-in-token-details="content.showInTokenDetails"
          />
          <ContentSide
            :side="content.side"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CpEditableMixin from '~/mixins/editable-component';
import OppEditButtons from './edit-buttons';
import CpInputContainer from '~/components/common/cpInputContainer';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpButton from '~/components/common/standalone-components/cp-button';
import ContentVisibility from '@/pages/_idIssuer/configuration/opportunities/components/content-visibility';
import ContentSide from '@/pages/_idIssuer/configuration/opportunities/components/content-side';
import CpHtmlEditor from '@/components/common/standalone-components/inputs/cp-html-textarea';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'ContentTextBox',
  components: {
    ContentVisibility,
    OppEditButtons,
    CpInputContainer,
    CpInput,
    CpButton,
    CpHtmlEditor,
    ContentSide,
    CpTextarea,
  },
  mixins: [CpEditableMixin],
  props: {
    content: {
      type: undefined,
      required: true,
    },
    editContentMode: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.editMode = this.editContentMode;
    this.setConfigState();
  },
  methods: {
    changeModeProxy() {
      if (this.editMode) {
        // on cancel clear the image IDs to prevent accidental upload
        // this.clearTouched();
      } else {
        // this.$emit('onEditActivated');
        this.setConfigState();
      }
      this.changeMode();
    },
    setConfigState() {
      this.model = JSON.parse(JSON.stringify(this.content || {}));
    },
    save() {
      this.$refs.cpInputContainer.validateForm()
        .then(async () => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.$emit('onSave', this.model);
            this.changeMode();
          }
        });
    },
    deleteAction() {
      this.$emit('onDeleteAction', this.model);
    },
    onContentVisibilityChanged({ visibilityType, value }) {
      this.model[visibilityType] = value;
      this.content[visibilityType] = value;
    },
    changeContent(value) {
      this.model.content = value;
    },
  },
};
</script>
