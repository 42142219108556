<template>
  <div>
    <div>
      <cp-head-table
        :title="$t('clientConfiguration.title')"
        :button-title="$t('clientConfiguration.button.add')"
        title-icon="ios-build"
        button-icon="ios-add-circle-outline"
        :show-button="$can(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_CLIENT_CONFIGURATION)"
        @onCreateElem="addClientConfigModal"
      />
      <cp-client-configuration-filter
        ref="auditLogFilter"
        @updateFilter="updateFilter"
      />
      <cp-client-configuration-table
        ref="clientConfigurationTable"
        :filters="activeFilters"
        @onDeleteConfigs="showConfirmModal"
        @onEditConfigs="editClientConfigModal"
      />
    </div>
    <cp-confirm-modal
      ref="cpConfirmModal"
      :item="currentItem"
      :title="$t('clientConfiguration.confirmModal.title')"
      @onOk="deleteClientConfig"
    >
      {{ $t('clientConfiguration.confirmModal.bodyText', [currentItem.domain]) }}
    </cp-confirm-modal>
    <add-edit-client-configuration-modal
      ref="addEditClientConfigurationModal"
      :domain-options="allDomainOptions"
      :selected-domain="currentItem.domain"
      :fields="currentItem"
      :model-view-type="modelViewType"
      @onClientConfiguration="clientConfigurationAction"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../utilities/ability/abilities';
import CpClientConfigurationTable from './components/table';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import addEditClientConfigurationModal from './components/add-edit-client-configuration-modal';
import CpClientConfigurationFilter from './components/filter';
import CpHeadTable from '~/components/common/head';
import CpMixPanel, { TrackingTypes } from '../../../mixins/mix-panel';

export default {
  name: 'ClientConfiguration',
  components: {
    CpClientConfigurationTable,
    CpClientConfigurationFilter,
    CpConfirmModal,
    CpHeadTable,
    addEditClientConfigurationModal,
  },
  metaInfo: {
    title: 'Client Configuration',
  },
  mixins: [CpMixPanel],
  data() {
    return {
      domain: '',
      currentItem: {
        domain: '',
        value: '',
        name: '',
      },
      activeFilters: {},
      modelViewType: 'addConfig',
      selectedDomain: '',
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapState('issuerList', ['allIssuersList']),
    allDomainOptions() {
      return this.allIssuersList.map(domain => ({ value: domain.id, text: domain.name }));
    },
  },
  mounted() {
    this.trackEvent(TrackingTypes.SYSTEM_CONFIG_CLIENT_CONFIG_LAND);
  },
  created() {
    this.getAllIssuersList().then();
  },
  methods: {
    ...mapActions('clientConfigurations', [
      'deleteClientConfigById',
      'getAllClientConfigs',
      'getAllClientConfigFilters',
      'createClientConfig',
      'updateClientConfigById',
    ]),
    ...mapActions('issuerList', [
      'getAllIssuersList',
    ]),
    showConfirmModal(item) {
      this.currentItem = item;
      return this.$refs.cpConfirmModal.show();
    },
    updateFilter(data) {
      this.activeFilters = data;
    },
    deleteClientConfig(item) {
      this.deleteClientConfigById({ issuerId: item.issuerId, configId: item.id }).then(() => {
        this.updatePageData();
      });
    },
    addClientConfigModal() {
      const domain = this.domainOptions && this.domainOptions[0] && this.domainOptions[0].value;
      this.currentItem = { domain };
      this.modelViewType = 'addConfig';
      this.showAddEditClientConfigModal();
    },
    editClientConfigModal(item) {
      this.currentItem = { ...item, domain: item.issuerId };
      this.modelViewType = 'editConfig';
      this.showAddEditClientConfigModal();
    },
    showAddEditClientConfigModal() {
      this.$refs.addEditClientConfigurationModal.$refs.clientConfigurationModal.show();
    },
    updatePageData() {
      this.updateFilter({ value: '', text: 'All' });
      this.$refs.clientConfigurationTable.$refs.cpTable.updateTableData();
      this.getAllClientConfigFilters().then();
    },
    clientConfigurationAction(data, actionType) {
      this.clientAction()[actionType](data);
    },
    clientAction() {
      return {
        addConfig: (body) => {
          this.createClientConfig({ issuerId: body.domain, body }).then(() => {
            this.updatePageData();
          });
        },
        editConfig: (body) => {
          const { issuerId, id: configId, value, name } = body;
          this.updateClientConfigById({ issuerId, configId, body: { value, name } }).then(() => {
            this.updatePageData();
          });
        },
      };
    },
  },
};
</script>
