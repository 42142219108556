<template>
  <div>
    <cp-head-table
      title="System Agreements"
      title-icon="ios-build"
      button-title="Add Agreement"
      button-icon="ios-add-circle-outline"
      :show-button="$can(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_AGREEMENTS)"
      @onCreateElem="toCreateModal"
    />

    <cp-table
      ref="cpTable"
      get-data-action="systemAgreements/getDefaultAgreementsList"
      :fields="fields"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>
      <template
        slot="updatedAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.updatedAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.SYSTEM_CONFIG_AGREEMENTS"
        >
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            title="Edit"
            @click="toEditModal(rowData.item)"
          >
            <i
              class="ion ion-md-create"
              :style="{ color: '#04bec4' }"
            />
            Edit
          </b-btn>
        </Can>
      </template>
    </cp-table>
    <cp-system-agreement-modal
      ref="CpSystemAgreement"
      :title-data="titleData"
      :agreement-data="agreementData"
      @onSaveNewAgreement="saveNewAgreement"
      @onSaveChanging="saveChanging"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../utilities/ability/abilities';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import CpSystemAgreementModal from './system-agreement-modal';
import CpMixPanel, { TrackingTypes } from '../../../mixins/mix-panel';
import fields from './fields';

export default {
  name: 'SystemAgreements',

  metaInfo: {
    title: 'System Agreements',
  },

  components: {
    Can,
    CpTable,
    CpHeadTable,
    CpSystemAgreementModal,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      agreementData: {},
      titleData: {
        type: '',
        icon: '',
      },
      fields,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  mounted() {
    this.trackEvent(TrackingTypes.SYSTEM_CONFIG_AGREEMENTS_LAND);
  },
  methods: {
    ...mapActions('systemAgreements',
      [
        'createDefaultAgreement',
        'updateDefaultAgreementById',
      ]),

    hideModal() {
      this.$refs.CpSystemAgreement.$refs.systemAgreement.hide();
    },

    showModal() {
      this.$refs.CpSystemAgreement.$refs.systemAgreement.show();
    },

    saveNewAgreement(params) {
      this.createDefaultAgreement(params).then(() => {
        this.hideModal();
        this.$refs.cpTable.updateTableData();
      });
    },

    saveChanging(body) {
      const { agreementId, ...data } = body;
      data.text = data.text || '';
      const params = {
        id: agreementId,
        data,
      };

      this.updateDefaultAgreementById(params).then(() => {
        this.hideModal();
        this.$refs.cpTable.updateTableData();
      });
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        icon: 'ios-add-circle-outline',
      };

      this.agreementData = { type: 'yes' };
      this.showModal();
    },

    toEditModal(itemData) {
      this.titleData = {
        type: 'Edit',
        icon: 'md-create',
      };
      this.agreementData = Object.assign({}, itemData);
      this.showModal();
    },
  },
};
</script>
