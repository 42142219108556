import globalStore from '@/store/modules/global';
import currentOperatorStore from '@/store/modules/current-operator';

const {
  state: { MixPanel },
} = globalStore;

export default {
  methods: {
    trackEvent(eventType, data) {
      const {
        state: { id, name, email },
      } = currentOperatorStore;

      MixPanel.track(eventType, {
        ...(data || {}),
        operatorId: id,
        operatorName: name,
        operatorEmail: email,
      });
    },
    identify() {
      MixPanel.identify(currentOperatorStore.state);
    },
    set_once() {
      MixPanel.people.set_once(currentOperatorStore.state.idUUID);
    },
    alias: () => {
      MixPanel.alias(currentOperatorStore.state.idUUID);
    },
  },
};

export const TrackingTypes = {
  // Issuer
  ISSUER_LIST_LAND: 'issuer-list-land',
  ISSUER_CONFIG_GENERAL_LAND: 'issuer-config-general-land',
  ISSUER_CONFIG_JURISDICTIONS_LAND: 'issuer-config-jurisdictions-land',
  ISSUER_CONFIG_FUNDRAISE_CONTENT_LAND: 'issuer-config-fundraise-content-land',
  ISSUER_CONFIG_OPPORTUNITIES_LAND: 'issuer-config-opportunities-land',
  ISSUER_AUDIT_LOG_LAND: 'issuer-audit-log-land',

  // Investor Details
  INVESTOR_DETAILS_VERIFICATION_LAND: 'investor-details-verification-land',

  // Dashboard
  DASHBOARD_LAND: 'dashboard-land',

  // Fundraise
  FUNDRAISE_LAND: 'fundraise-land',

  // Holders
  HOLDERS_LAND: 'holders-land',
  HOLDERS_HOLDERS_OF_RECORD_LAND: 'holders-holders-of-record-land',
  HOLDERS_BENEFICIAL_OWNERS_LAND: 'holders-beneficial-owners-land',
  HOLDERS_CUSTODIAN_MANAGER_LAND: 'holders-custodian-manager-land',

  // Signatures
  SIGNATURES_LAND: 'signatures-land',

  // Issue Details
  ISSUE_DETAILS_LAND: 'issue-details-land',
  ISSUE_DETAIL_CONTROL_BOOK_LAND: 'issue-detail-control-book-land',
  ISSUE_DETAIL_TRANSACTIONS_LAND: 'issue-detail-transactions-land',

  // Investor Engagement
  INVESTOR_ENGAGEMENT_CONTACT_SALES_LAND:
    'investor-engagement-contact-sales-land',

  // Distributions
  DISTRIBUTIONS_LAND: 'distributions-land',

  // Snapshots
  SNAPSHOTS_LAND: 'snapshots-land',

  // Configurations
  CONFIG_FUNDRAISE_LAND: 'config-fundraise-land',
  CONFIG_TOKEN_LAND: 'config-token-land',
  CONFIG_ASSET_CONTENT_LAND: 'config-asset-content-land',
  CONFIG_JURISDICTIONS_LAND: 'config-jurisdictions-land',

  // Transfer Agent
  TA_CONTROL_BOOK_LAND: 'ta-control-book-land',
  TA_MSF_LAND: 'ta-msf-land',
  TA_SECURITIES_TRANSACTIONS_LAND: 'ta-securities-transactions-land',
  TA_OPERATIONAL_PROCEDURES_LAND: 'ta-operational-procedures-land',
  TA_AFFILIATE_MANAGEMENT_LAND: 'ta-affiliate-management-land',

  // Advanced
  ADVANCED_ISSUER_AFFILIATES_LAND: 'advanced-issuer-affiliates-land',
  ADVANCED_ISSUER_AGREEMENTS_LAND: 'advanced-issuer-agreements-land',
  ADVANCED_ISSUER_BANK_ACCOUNTS_LAND: 'advanced-issuer-bank-accounts-land',
  ADVANCED_ISSUER_CONFIGURATION_VARIABLES_LAND:
    'advanced-issuer-configuration-variables-land',
  ADVANCED_ISSUER_CURRENCIES_LAND: 'advanced-issuer-currencies-land',
  ADVANCED_ISSUER_EMAIL_TEMPLATES_LAND: 'advanced-issuer-email-templates-land',
  ADVANCED_ISSUER_PLATFORM_DOCUMENTS_LAND:
    'advanced-issuer-platform-documents-land',
  ADVANCED_ISSUER_QUESTIONS_LAND: 'advanced-issuer-questions-land',
  ADVANCED_ISSUER_TEXTS_LAND: 'advanced-issuer-texts-land',
  ADVANCED_ISSUER_PRERENDERED_ADDRESSES_LAND:
    'advanced-issuer-prerendered-addresses-land',

  // Privacy
  PRIVACY_CONTROL_LAND: 'privacy-control-land',

  // SiD
  SID_INVESTORS_LAND: 'sid-investors-land',
  SID_TRANSLATIONS_LAND: 'sid-translations-land',
  SID_WALLETS_LAND: 'sid-wallets-land',
  SID_PROMOTIONS_LAND: 'sid-promotions-land',

  // Panel Administration
  PANEL_ADMINISTRATION_LAND: 'panel-administration-land',

  // System Config
  SYSTEM_CONFIG_AGREEMENTS_LAND: 'system-config-agreements-land',
  SYSTEM_CONFIG_QUESTIONS_LAND: 'system-config-questions-land',
  SYSTEM_CONFIG_COUNTRIES_LAND: 'system-config-countries-land',
  SYSTEM_CONFIG_EMAIL_TEMPLATES_LAND: 'system-config-email-templates-land',
  SYSTEM_CONFIG_SYSTEM_TEXTS_LAND: 'system-config-system-texts-land',
  SYSTEM_CONFIG_CLIENT_CONFIG_LAND: 'system-config-client-config-land',

  // Markets
  MARKETS_OVERVIEW_LAND: 'markets-overview-land',
  MARKETS_INVESTORS_LAND: 'markets-investors-land',
  MARKETS_OPPORTUNITIES_LAND: 'markets-opportunities-land',
  MARKETS_SYSTEM_CONFIG_LAND: 'markets-system-config-land',
  MARKETS_ACCREDITATION_LAND: 'markets-accreditation-land',
  MARKETS_AUDIT_LOG_LAND: 'audit-log-land',

  // Audit log
  AUDIT_LOG_LAND: 'audit-log-land',
};
