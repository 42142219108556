<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('configurationGeneral.investorsArea.title') }}
          </h4>
        </div>
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL"
        >
          <cp-edit-buttons
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="update"
          />
        </Can>
      </div>
    </div>
    <div class="card-body">
      <cp-error-notices
        :errors="errors"
      />
      <div class="card border-light">
        <div class="card-header">
          <h4 class="mb-0">
            {{ $t('configurationGeneral.investorsArea.subTitle.announcement') }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
              class="col-md-4"
            >
              <h5>{{ $t('configurationGeneral.investorsArea.input.backgroundImage') }}</h5>
              <cp-file-uploader
                id="announcements-background-uploader"
                :default-url="titleImage"
                @onChange="onAnnouncementsUploaded"
              />
              <table class="table table-striped table-borderless mb-0 mt-3">
                <tbody>
                  <tr>
                    <td>{{ $t('configurationGeneral.investorsArea.label.size') }}</td>
                    <td><strong>{{ $t('configurationGeneral.investorsArea.label.sizeValue.announcement') }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{ $t('configurationGeneral.investorsArea.label.format') }}</td>
                    <td><strong>{{ $t('configurationGeneral.investorsArea.label.formatValue') }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              class="col-md-4"
            >
              <h5>{{ $t('configurationGeneral.investorsArea.input.backgroundImage') }}</h5>
              <div class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview">
                <img
                  v-b-modal.investor-announcement-bg
                  :class="`d-inline-block ${ getInvestorsAbout.background ? 'link': '' }`"
                  :src="titleImage"
                  alt="Title / Announcement"
                >
              </div>
              <b-modal
                v-if="getInvestorsAnnouncements.background"
                id="investor-announcement-bg"
                size="lg"
                class="cp-preview-modal"
                :title="$t('configurationGeneral.previewModal.title.announcement')"
                hide-footer
              >
                <img
                  class="d-inline-block"
                  :src="titleImage"
                  alt="Investor Area - Title / Announcement Background"
                >
              </b-modal>
            </div>
            <div class="col-md-8">
              <h5>{{ $t('configurationGeneral.investorsArea.input.title') }}</h5>
              <cp-editable-input
                :value="getInvestorsAnnouncements.title"
                root-class="card border-light mt-3"
                text-class="card-body"
                :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                :text="getInvestorsAnnouncements.title"
                @input="changeAnnouncement('title', $event)"
              />
              <h5 class="mt-3">
                {{ $t('configurationGeneral.investorsArea.input.subtitle') }}
              </h5>
              <div v-if="!editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)">
                <span class="read-only card border-light">
                  {{ getInvestorsAnnouncements.subtitle }}
                </span>
              </div>
              <div v-show="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)">
                <cp-html-editor
                  :value="getInvestorsAnnouncements.subtitle"
                  @input="changeAnnouncement('subtitle', $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-light mt-4">
        <div class="card-header">
          <h4 class="mb-0">
            {{ $t('configurationGeneral.investorsArea.subTitle.about') }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
              class="col-md-4"
            >
              <h5>{{ $t('configurationGeneral.investorsArea.input.backgroundImage') }}</h5>
              <cp-file-uploader
                id="about-background-uploader"
                :default-url="aboutImage"
                @onChange="onAboutUploaded"
              />
              <table class="table table-striped table-borderless mb-0 mt-3">
                <tbody>
                  <tr>
                    <td>{{ $t('configurationGeneral.investorsArea.label.size') }}</td>
                    <td><strong>{{ $t('configurationGeneral.investorsArea.label.sizeValue.about') }}</strong></td>
                  </tr>
                  <tr>
                    <td>{{ $t('configurationGeneral.investorsArea.label.format') }}</td>
                    <td><strong>{{ $t('configurationGeneral.investorsArea.label.formatValue') }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              class="col-md-4"
            >
              <h5>{{ $t('configurationGeneral.investorsArea.input.backgroundImage') }}</h5>
              <div class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview">
                <img
                  v-b-modal.investor-about-bg
                  :class="`d-inline-block ${ getInvestorsAbout.background ? 'link': '' }`"
                  :src="aboutImage"
                  alt="About"
                >
              </div>
              <b-modal
                v-if="getInvestorsAbout.background"
                id="investor-about-bg"
                size="lg"
                class="cp-preview-modal"
                :title="$t('configurationGeneral.previewModal.title.about')"
                hide-footer
              >
                <img
                  class="d-inline-block"
                  :src="aboutImage"
                  alt="Landing Page - Background"
                >
              </b-modal>
            </div>
            <div class="col-md-4">
              <h5>{{ $t('configurationGeneral.investorsArea.input.text') }}</h5>
              <div class="card border-light mt-3">
                <div v-show="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)">
                  <cp-html-editor
                    :value="getInvestorsAbout.text"
                    @input="changeAbout('text', $event)"
                  />
                </div>
                <div
                  v-show="!editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                  class="card-body"
                >
                  {{ getInvestorsAbout.text }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h5>{{ $t('configurationGeneral.investorsArea.input.youtubeVideoUrl') }}</h5>
              <cp-editable-input
                :value="getInvestorsAbout.videoUrl"
                root-class="card border-light mt-3"
                text-class="card-body"
                :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                :text="getInvestorsAbout.videoUrl"
                @input="changeAbout('videoUrl', $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card border-light mt-4">
            <div class="card-header">
              <h4 class="mb-0">
                {{ $t('configurationGeneral.investorsArea.subTitle.wizard') }}
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                  class="col"
                >
                  <h5>{{ $t('configurationGeneral.investorsArea.input.wizardBackground') }}</h5>
                  <cp-file-uploader
                    id="wizard-background-uploader"
                    :default-url="wizardImage"
                    @onChange="onWizardUploaded"
                  />
                  <table class="table table-striped table-borderless mb-0 mt-3">
                    <tbody>
                      <tr>
                        <td>{{ $t('configurationGeneral.investorsArea.label.size') }}</td>
                        <td><strong>{{ $t('configurationGeneral.investorsArea.label.sizeValue.wizard') }}</strong></td>
                      </tr>
                      <tr>
                        <td>{{ $t('configurationGeneral.investorsArea.label.format') }}</td>
                        <td><strong>{{ $t('configurationGeneral.investorsArea.label.formatValue') }}</strong></td>
                      </tr>
                      <tr>
                        <td>{{ $t('configurationGeneral.investorsArea.label.comment') }}</td>
                        <td><strong>{{ $t('configurationGeneral.investorsArea.label.commentValue') }}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-else
                  class="col"
                >
                  <h5>{{ $t('configurationGeneral.investorsArea.input.backgroundImage') }}</h5>
                  <div class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview">
                    <img
                      v-b-modal.investor-wizard-bg
                      :class="`d-inline-block ${ getInvestorsAnnouncements.background ? 'link': '' }`"
                      :src="wizardImage"
                      alt="Wizard"
                    >
                  </div>
                  <b-modal
                    v-if="getInvestorsAnnouncements.background"
                    id="investor-wizard-bg"
                    size="lg"
                    class="cp-preview-modal"
                    :title="$t('configurationGeneral.previewModal.title.wizard')"
                    hide-footer
                  >
                    <img
                      class="d-inline-block"
                      :src="wizardImage"
                      alt="Investor Area - Wizard Background"
                    >
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-light mt-4">
            <div class="card-header">
              <h4 class="mb-0">
                {{ $t('configurationGeneral.investorsArea.subTitle.socialLinks') }}
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <table class="table table-striped table-borderless mb-0 mt-3">
                    <tbody>
                      <tr>
                        <td class="align-middle">
                          {{ $t('configurationGeneral.investorsArea.input.facebook') }}
                        </td>
                        <td>
                          <cp-editable-input
                            :value="getInvestorsSocialLinks('facebook')"
                            :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            :text="getInvestorsSocialLinks('facebook')"
                            strong
                            @input="changeSocial('facebook', $event)"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">
                          {{ $t('configurationGeneral.investorsArea.input.whatsapp') }}
                        </td>
                        <td>
                          <cp-editable-input
                            :value="getInvestorsSocialLinks('whatsapp')"
                            :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            :text="getInvestorsSocialLinks('whatsapp')"
                            strong
                            @input="changeSocial('whatsapp', $event)"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">
                          {{ $t('configurationGeneral.investorsArea.input.twitter') }}
                        </td>
                        <td>
                          <cp-editable-input
                            :value="getInvestorsSocialLinks('twitter')"
                            :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            :text="getInvestorsSocialLinks('twitter')"
                            strong
                            @input="changeSocial('twitter', $event)"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">
                          {{ $t('configurationGeneral.investorsArea.input.telegram') }}
                        </td>
                        <td>
                          <cp-editable-input
                            :value="getInvestorsSocialLinks('telegram')"
                            :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            :text="getInvestorsSocialLinks('telegram')"
                            strong
                            @input="changeSocial('telegram', $event)"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle">
                          {{ $t('configurationGeneral.investorsArea.input.medium') }}
                        </td>
                        <td>
                          <cp-editable-input
                            :value="getInvestorsSocialLinks('medium')"
                            :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            :text="getInvestorsSocialLinks('medium')"
                            strong
                            @input="changeSocial('medium', $event)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import CpEditButtons from '~/components/common/edit-buttons';
import CpEditableInput from '~/components/common/editable-input';
import CpErrorNotices from '~/components/common/error-notices';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import CpHtmlEditor from '~/components/common/standalone-components/inputs/cp-html-textarea';

import CpComponentToggleMixin from '~/mixins/component-toggle';
import staticPathGenerationMixin from '~/mixins/static-path-generation';

export default {
  name: 'CpConfigurationInvestorsArea',
  components: {
    Can,
    CpEditButtons,
    CpEditableInput,
    CpErrorNotices,
    CpFileUploader,
    CpHtmlEditor,
  },
  mixins: [
    CpComponentToggleMixin,
    staticPathGenerationMixin,
  ],
  data() {
    return {
      keep: {},
      placeholderModel: {
        videoUrl: '',
      },
      errors: [],
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('configuration', [
      'getInvestorsAnnouncements',
      'getInvestorsAbout',
      'getInvestorsSocialLinks',
      'getInvestorsWizard',
      'getAllInvestorsSocialLinks',
    ]),
    titleImage() {
      const url = this.getInvestorsAnnouncements.background || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    aboutImage() {
      const url = this.getInvestorsAbout.background || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    wizardImage() {
      const url = this.getInvestorsWizard.background || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
  },
  methods: {
    ...mapMutations('configuration', [
      'CHANGE_INVESTORS_ANNOUNCEMENTS',
      'CHANGE_INVESTORS_ABOUT',
      'CHANGE_SOCIAL_LINKS,',
    ]),
    ...mapActions('configuration', [
      'updateInvestorsConfiguration',
    ]),
    changeModeProxy() {
      if (this.editMode) {
        // on cancel clear the image IDs to prevent accidental upload
        this.cancelSelf();
        this.restoreState();
      } else {
        this.storeState();
        this.initialData(this.keep);
        this.isActivated();
      }
    },
    cancelIfActive() {
      if (this.editMode) {
        this.cancelSelf();
      }
    },
    getOwnData() {
      this.restoreState();
      return this.keep;
    },
    storeState() {
      // immutable is hard...
      this.keep.getInvestorsAnnouncements = JSON.parse(JSON.stringify(this.getInvestorsAnnouncements || {}));
      this.keep.getInvestorsAbout = JSON.parse(JSON.stringify(this.getInvestorsAbout || {}));
      this.keep.getInvestorsSocialLinks = JSON.parse(JSON.stringify(this.getAllInvestorsSocialLinks || {}));
      this.keep.getInvestorsWizard = JSON.parse(JSON.stringify(this.getInvestorsWizard || {}));
    },
    restoreState() {
      this.$store.commit('configuration/SET_INVESTORS_ANNOUNCEMENTS', this.keep.getInvestorsAnnouncements);
      this.$store.commit('configuration/SET_INVESTORS_ABOUT', this.keep.getInvestorsAbout);
      this.$store.commit('configuration/SET_WIZARD_ANNOUNCEMENTS', this.keep.getInvestorsWizard);
      this.$store.commit('configuration/SET_SOCIAL_LINKS', this.keep.getInvestorsSocialLinks);
      // reset image keys to avoid accidental upload
      this.changeAnnouncement('backgroundImageKey', undefined);
      this.changeAbout('backgroundImageKey', undefined);
      this.changeWizard('backgroundImageKey', undefined);
    },
    onAnnouncementsUploaded({ fileKey }) {
      this.changeAnnouncement('backgroundImageKey', fileKey);
    },
    onAboutUploaded({ fileKey }) {
      this.changeAbout('backgroundImageKey', fileKey);
    },
    onWizardUploaded({ fileKey }) {
      this.changeWizard('backgroundImageKey', fileKey);
    },
    changeSocial(name, value) {
      this.$store.commit('configuration/CHANGE_SOCIAL_LINKS', { name, value });
    },
    changeAnnouncement(name, value) {
      this.$store.commit('configuration/CHANGE_INVESTORS_ANNOUNCEMENTS', { name, value });
    },
    changeAbout(name, value) {
      this.$store.commit('configuration/CHANGE_INVESTORS_ABOUT', { name, value });
    },
    changeWizard(name, value) {
      this.$store.commit('configuration/CHANGE_WIZARD_ANNOUNCEMENTS', { name, value });
    },
    update() {
      const { idIssuer: issuerId } = this.$route.params;
      this.updateInvestorsConfiguration(issuerId).then(() => {
        this.errors = [];
        this.cancelSelf();
      })
        .catch((err) => {
          if (err.response.body && err.response.body.message) {
            this.errors = err.response.body.message.split(',');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .read-only {
    padding: 0 1.5rem;
  }
  .cp-preview-modal{
    text-align: center;
    img{
      max-width:90%;
    }
  }
  .cp-file-preview{
    min-height:256px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-height: 180px;
      max-width: 70%;
      margin: auto;
    }
    img.link{
      cursor: pointer;
    }
  }
  .d-inline-block{
    max-width: 100%;
    max-height: 100%;
  }
</style>
