<template>
  <div>
    <div>
      <div class="header-block mb-4">
        <h4 class="font-weight-bold mb-0">
          <i class="ion ion-ios-book" />
          {{ $t('auditLog.title') }}
        </h4>
      </div>
      <cp-audit-log-filter
        ref="auditLogFilter"
        @updateFilter="updateFilter"
      />
      <cp-audit-log-table
        :filters="activeFilters"
        @onView="openViewAuditLogModal"
      />
    </div>
    <cp-view-audit-log-modal
      ref="cpViewAuditLogModal"
      :current-item="currentItem"
    />
  </div>
</template>

<script>
import CpAuditLogTable from './components/table';
import CpViewAuditLogModal from './components/cp-view-audit-log-modal';
import CpAuditLogFilter from './components/filter';
import CpMixPanel, { TrackingTypes } from '../../mixins/mix-panel';

export default {
  name: 'AuditLog',
  components: {
    CpAuditLogTable,
    CpAuditLogFilter,
    CpViewAuditLogModal,
  },
  metaInfo: {
    title: 'Audit Log',
  },
  mixins: [CpMixPanel],
  data() {
    return {
      currentItem: {},
      activeFilters: {},
    };
  },
  mounted() {
    this.trackEvent(TrackingTypes.AUDIT_LOG_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },
  methods: {
    openViewAuditLogModal(item) {
      this.currentItem = item;
      this.$refs.cpViewAuditLogModal.$refs.viewAuditLog.$refs.confirmModal.show();
    },
    updateFilter(data) {
      this.activeFilters = data;
    },
  },
};
</script>
