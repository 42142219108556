export default {
  configurationFundraise: {
    title: 'Fundraise',
    subTitle: {
      general: 'General',
      round: 'Round',
    },
    errorsMsg: {
      depositOptions: 'Must contain at least one checkbox',
      iraDepositOptions: 'IRA cannot be selected without another deposit method',
      ihfDepositOptions: 'IHF must contain Web3 deposit option selected',
    },
    values: {
      web3: 'Web3',
      standard: 'Standard',
      'wire-transfer-crypto': 'Wire Transfer/Crypto',
      'cash-account': 'Cash account',
      ira: 'IRA',
      'reg-s': 'Reg S',
      usdc: 'USDC',
    },
    investmentRegulationTypes: {
      none: 'None',
      'reg-cf': 'Reg CF',
      'reg-d-506c': 'Reg D 506c',
      'reg-d-506b': 'Reg D 506b',
      'reg-a': 'Reg A',
      'reg-s': 'Reg S',
      'reg-d-506c-reg-s': 'Reg D 506c/Reg S',
      'reg-d-506b-reg-s': 'Reg D 506b/Reg S',
    },
    label: {
      wireTransferDetailsBank: 'Wire Transfer Details Bank',
      currencies: 'Currencies',
      ethXPub: 'ETH XPub',
      btcXPub: 'BTC XPub',
      investmentType: 'Investment type',
      depositOptions: 'Deposit options',
      cashAccountIdentifier: 'Cash account identifier',
      investmentRegulationType: 'Investment regulation',

      table: {
        name: 'Name',
        status: 'Status',
        startsAt: 'Start date',
        endsAt: 'Close date',
        issuanceDate: 'Issuance date',
        minimumCryptoInvestments: 'Min. investment ({0} for Crypto)',
        minimumFiatInvestments: 'Min. investment ({0} for FIAT)',
        investmentTermsAndConditions: 'Investment Terms and Conditions',
        investmentSubscriptionAgreement: 'Subscription Agreement',
        subscriptionAgreementDocusignId: 'Subscription agreement DocuSign ID',
      },
    },
    status: {
      active: 'active',
      planned: 'planned',
      done: 'done',
    },
    button: {
      round: {
        add: 'Add Round',
      },
    },
    loading: 'Loading fundraise, please wait...',
    // modals
    deleteModal: {
      title: 'Delete round',
      message: 'Are you sure you want to delete {0}?',
    },
    updateDoneRoundModal: {
      title: 'Edit Round',
      message: 'Reactivating a previously ‘Done’ round will cause any current ‘Active’ round to close.',
    },
    roundModal: {
      title: {
        add: 'Add Round',
        edit: 'Edit Round',
      },
      label: {
        bonusForLargeInvestments: 'Bonus for large investments',
        tokenPrice: 'Token Price',
        bonusAmount: 'Bonus amount',
        bonusPercentage: 'Bonus Percentage',
        mandatory: 'Mandatory',
        jurisdictionalExceptions: 'Jurisdictional exceptions',
      },
      message: {
        numericZeroError: 'The {0} field must be bigger then 0',
        numericRangeError: 'The {0} field should be bigger then 0 or smaller or equal 100',
        numericTypeError: '{0} must contain numeric values only',
        minAmountError: 'Min {0} must be higher than or equal to {1}',
        jurisdictionalExceptionExists: 'Jurisdictional exception exists',
        countryCodeRequired: 'Country required',
        exceptionNameRequired: 'Exception name required',
        wrongExceptionValue: 'Wrong value',
        exceptionValueRequired: 'Value required',
      },
      input: {
        roundName: 'Round Name:',
        activeStatus: 'Active:',
        applyMinInvestToTheTokenGroup: 'Minimum subsequent investments for Token Group: ',
        visibleToInvestor: 'Visible to Investor:',
        minimumFiatInvestments: 'Min. investment ({0} for FIAT):',
        minimumCryptoInvestments: 'Min. investment ({0} for Crypto):',
        minSubsequentInvestmentFiat: 'Min. subsequent investment ({0} for FIAT):',
        minSubsequentInvestmentCrypto: 'Min. subsequent investment ({0} for Crypto):',
        docuSignId: 'DocuSign ID',
        custodianDocuSignId: 'Custodian DocuSign ID',
        startDate: 'Start date:',
        closeDate: 'Close date:',
        issuanceDate: 'Issuance date:',
        tokenPrice: 'Token Price:',
        minimumInvestmentForBonus: 'Min. Investment for Bonus:',
        bonus: 'Bonus:',
        investmentTermsAndConditions: 'Investment terms and conditions:',
        subscriptionAgreement: 'Subscription Agreement:',
        json: 'JSON',
      },
      button: {
        getLastRoundAgreement: 'Same as last round',
      },
    },
  },
};
