<template>
  <div
    v-if="ready"
    class="container-fluid flex-grow-1 container-p-y"
  >
    <cp-configuration-head
      :page-name="$t('configurationGeneral.title')"
    />
    <cp-configuration-general-properties
      ref="CpConfigurationGeneralProperties"
      :opened-component="state.openedChildComponent"
      @onEditActivated="changeStateName"
    />
    <cp-configuration-landing-page
      ref="CpConfigurationLandingPage"
      :landing-info="landingInfo"
      :opened-component="state.openedChildComponent"
      @onEditActivated="changeStateName"
    />
    <cp-configuration-investors-area
      ref="CpConfigurationInvestorsArea"
      :opened-component="state.openedChildComponent"
      @onEditActivated="changeStateName"
    />
    <cp-configuration-investors-email
      ref="CpConfigurationEmail"
      :opened-component="state.openedChildComponent"
      @onEditActivated="changeStateName"
    />
    <b-modal
      ref="confirm-leave-modal"
      @ok="handleOk"
    >
      {{ $t('configurationGeneral.message.unsaved') }}
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CpConfigurationHead from '../configuration-head';
import CpConfigurationGeneralProperties from './components/general-properties';
import CpConfigurationLandingPage from './components/landing-page';
import CpConfigurationInvestorsArea from './components/investors-area';
import CpConfigurationInvestorsEmail from './components/email';
import CpComponentToggleMixin from '~/mixins/component-toggle';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'ConfigurationGeneral',

  components: {
    CpConfigurationGeneralProperties,
    CpConfigurationLandingPage,
    CpConfigurationInvestorsArea,
    CpConfigurationInvestorsEmail,
    CpConfigurationHead,
  },

  metaInfo: {
    title: 'General',
  },

  mixins: [
    CpComponentToggleMixin,
    CpMixPanel,
  ],

  data() {
    return {
      ready: false,
      state: {
        openedChildComponent: '',
        awaitingOk: '',
      },
    };
  },

  computed: mapState('configuration', ['landingInfo', 'configuration']),
  async mounted() {
    const { idIssuer } = this.$route.params;
    await this.getConfiguration(idIssuer).finally(() => {
      this.ready = true;
    });
    this.trackEvent(TrackingTypes.ISSUER_CONFIG_GENERAL_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },

  methods: {
    ...mapActions('configuration', ['getConfiguration']),
    changeStateName(stateName) {
      this.state.openedChildComponent = stateName.openedChildComponent;
      this.state.awaitingOk = stateName.awaitingOk;
    },
  },
};
</script>
