var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-general-modal',{ref:"operatorModal",attrs:{"title":_vm.title,"title-icon":_vm.titleIcon,"ok-text":_vm.title,"default-value":_vm.model},on:{"onCancel":_vm.resetForm,"onInput":_vm.handleInputs,"onOk":_vm.handleSubmit}},[_c('cp-input',{attrs:{"name":"name","label":_vm.$t('operators.operatorModal.label.name'),"validate":"required"}}),_c('cp-input',{attrs:{"name":"email","label":_vm.$t('operators.operatorModal.label.email'),"disabled":_vm.editMode,"validate":"required|email"}}),(!_vm.isBrokerDealer)?_c('cp-select',{attrs:{"name":"authorizationLevel","label":_vm.$t('operators.operatorModal.label.authorizationLevel'),"options":_vm.authorizationLevelOptions}}):_vm._e(),(_vm.shouldDisplayOrganizations)?_c('cp-select',{attrs:{"name":"organizationId","value":_vm.model.organizationId,"label":_vm.$t('operators.operatorModal.label.organization'),"options":_vm.getOrganizationOptions}}):_vm._e(),(
        _vm.isMegaAdmin &&
          _vm.model.authorizationLevel === 'brokerdealer' &&
          _vm.brokerDealerGroupsDropdown.length
      )?_c('cp-select',{attrs:{"name":"brokerDealerGroupId","label":_vm.$t('operators.operatorModal.label.brokerDealerGroup'),"options":_vm.brokerDealerGroupsDropdown,"validate":_vm.model.authorizationLevel === 'brokerdealer' ? 'required' : ''}}):_vm._e(),(
        _vm.isMegaAdmin &&
          _vm.model.authorizationLevel === 'brokerdealer' &&
          !_vm.brokerDealerGroupsDropdown.length
      )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("operators.operatorModal.warning.emptyBrokerDealerGroupList"))+" ")]):_vm._e(),_c('cp-dual-list-box',{directives:[{name:"show",rawName:"v-show",value:(
        !['megaadmin', 'brokerdealer'].includes(_vm.model.authorizationLevel)
      ),expression:"\n        !['megaadmin', 'brokerdealer'].includes(model.authorizationLevel)\n      "},{name:"validate",rawName:"v-validate.initial",value:(_vm.issuersValidation),expression:"issuersValidation",modifiers:{"initial":true}}],attrs:{"name":"issuerIds","selected-instances":_vm.selectedInstances,"instances":_vm.instances,"label":_vm.boxNames},model:{value:(_vm.model.issuerIds),callback:function ($$v) {_vm.$set(_vm.model, "issuerIds", $$v)},expression:"model.issuerIds"}}),(_vm.authorizationsList.length && !_vm.isBasic)?_c('cp-multi-select',{attrs:{"value":_vm.multiSelectDefault,"options":_vm.authorizationsList,"name":"authorizations","label":_vm.$t('operators.operatorModal.label.authorizations')}}):_vm._e(),(_vm.enableViwOnlyToggle && _vm.model.authorizationLevel !== 'megaadmin')?_c('div',{staticStyle:{"margin-top":"1.2em"}},[_c('span',{staticStyle:{"margin-right":"1em"}},[_vm._v("View only")]),_c('cp-switcher',{attrs:{"value":_vm.model.isViewOnly,"name":"isViewOnly"}})],1):_vm._e(),_c('div',{staticClass:"text-muted"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t("operators.operatorModal.label.createdBy"))+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.getCreatedBy)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }