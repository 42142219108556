<template>
  <div class="menu-wrapper">
    <div
      v-if="
        showDividerOn === menuItem.route &&
          (hasIssuerId || showDividerOn === 'securitize-id')
      "
      class="sidenav-divider mt-0"
    />
    <sidenav-router-link
      v-if="!menuItem.submenu && showMenu(menuItem)"
      :icon="menuItem.icon"
      :to="getPagePath(menuItem.route)"
      :exact="menuItem.exact"
      @onClick="scrollToTop"
    >
      {{ $t('component.sideNavMenu.link.' + menuItem.route) }}
    </sidenav-router-link>
    <sidenav-menu
      v-if="menuItem.submenu && showMenu(menuItem)"
      :open="isMatched(menuItem.route)"
      :icon="menuItem.icon"
    >
      <template slot="link-text">
        {{ $t('component.sideNavMenu.expansionItem.' + menuItem.route) }}
      </template>
      <cp-side-nav-menu
        v-for="submenu in menuItem.submenu"
        :key="submenu.route"
        :menu-item="submenu"
      />
    </sidenav-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { SidenavRouterLink, SidenavMenu } from '~/vendor/libs/sidenav';
import routeNames from '~/utilities/routes';
import { checkRouteAvailability } from '~/utilities/route-helpers';

export default {
  name: 'CpSideNavMenu',
  components: {
    SidenavRouterLink,
    SidenavMenu,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    showDividerOn: {
      type: String,
      default: 'securitize-id',
    },
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('rounds', ['rounds']),
    ...mapGetters('issuersInfo', [
      'brokerDealerGroupPermissions',
      'permissionsPerIssuer',
    ]),
    ...mapState('issuersInfo', ['currentToken']),
    hasIssuerId() {
      return Boolean(this.$route.params.idIssuer);
    },
  },
  methods: {
    isMatched(path1, path2 = false) {
      return (
        this.$route.path.includes(path1) || this.$route.path.includes(path2)
      );
    },
    getPagePath(name) {
      return routeNames[name]
        ? routeNames[name](this.$route.params.idIssuer, this.currentToken.id)
        : '';
    },
    showMenu({ route }) {
      const { authorizationLevel, authorizations, ability } = this.operatorData;
      switch (route) {
        // only non-issuer routes
        case 'createIssuer':
        case 'issuerList':
        case 'system-config':
        case 'systemAgreements':
        case 'systemQuestions':
        case 'systemCountries':
        case 'systemEmailTemplates':
        case 'systemTexts':
        case 'clientConfiguration':
          return (
            !this.hasIssuerId
            && checkRouteAvailability({
              routeName: route,
              operatorAuthorizationLevel: authorizationLevel,
              operatorAuthorizations: authorizations,
              issuerPermissions: this.brokerDealerGroupPermissions,
              permissionsPerIssuer: this.permissionsPerIssuer,
            })
          );
        // only-issuer routes
        case 'dashboard':
        case 'onboarding':
        case 'fundraise':
        case 'fundraiseMfe':
        case 'holders':
        case 'issueDetails':
        case 'outreach':
          return this.hasIssuerId
          && checkRouteAvailability({
              routeName: route,
              operatorAuthorizationLevel: authorizationLevel,
              operatorAuthorizations: authorizations,
              issuerPermissions: this.brokerDealerGroupPermissions,
              permissionsPerIssuer: this.permissionsPerIssuer,
              ability,
            });

        case 'signatures':
          return this.hasIssuerId
          && checkRouteAvailability({
            routeName: route,
            operatorAuthorizationLevel: authorizationLevel,
            operatorAuthorizations: authorizations,
            issuerPermissions: this.brokerDealerGroupPermissions,
            permissionsPerIssuer: this.permissionsPerIssuer,
            ability,
          });
        case 'distributions':
        case 'snapshots':
        case 'configuration':
        case 'issuer-configuration':
        case 'transferAgent':
        case 'advanced':
        case 'privacyControl':
        case 'auditLogIssuer':
          return (
            this.hasIssuerId
            && checkRouteAvailability({
              routeName: route,
              operatorAuthorizationLevel: authorizationLevel,
              operatorAuthorizations: authorizations,
              issuerPermissions: this.brokerDealerGroupPermissions,
              permissionsPerIssuer: this.permissionsPerIssuer,
            })
          );
        // all routes
        case 'auditLog':
        case 'securitizeId':
        case 'operators':
        default:
          return checkRouteAvailability({
            routeName: route,
            operatorAuthorizationLevel: authorizationLevel,
            operatorAuthorizations: authorizations,
            issuerPermissions: this.brokerDealerGroupPermissions,
            permissionsPerIssuer: this.permissionsPerIssuer,
          });
      }
    },
    scrollToTop(path) {
      if (path === '/operators') {
        const container = document.querySelector('#side-nav-container');
        setTimeout(() => {
          container.scrollTop = 0;
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu-wrapper {
  width: 100%;
}
</style>
