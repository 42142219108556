<template>
  <div>
    <div
      v-if="!isReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('configurationJurisdictionsPerToken.loading') }}
      </div>
    </div>
    <div v-if="isReady">
      <cp-configuration-head
        :page-head-title="$t('configurationJurisdictionsPerToken.pageTitle')"
        :page-name="$t('configurationJurisdictionsPerToken.title')"
      >
        <div class="d-flex align-items-center justify-content-end" />
      </cp-configuration-head>
      <cp-jurisdictions-per-token-table ref="countriesPerTokenTable" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

import CpConfigurationHead from '../configuration-head';
import CpJurisdictionsPerTokenTable from './components/table';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'JurisdictionsPerToken',

  metaInfo: {
    title: 'Jurisdictions',
  },

  components: {
    CpConfigurationHead,
    CpJurisdictionsPerTokenTable,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      editableCard: null,
      isReady: false,
    };
  },
  computed: {
    ...mapGetters('issuerList', ['currentIssuer']),
  },
  async mounted() {
    this.trackEvent(TrackingTypes.CONFIG_JURISDICTIONS_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  async created() {
    setTimeout(() => {
      this.isReady = true;
    }, 1000);
  },
  methods: {
    changeEditMode(blockName) {
      this.editableCard = blockName;
    },
  },
};
</script>
