<template>
  <div>
    <b-card
      header-class="font-weight-bold"
      body-class="p-0"
      :header=" displayTokenName + (displayTokenBlockchainNetwork ? (` - ${displayTokenBlockchainNetwork} `): ' ') + $t('issueDetails.label.tab.transactions')"
      class="mt-5"
    >
      <cp-token-transactions-table
        :data="tokenTransactionsData"
        :query-params="queryParams"
        :total-items="tokenTransactionsTotal"
        :table-loading="isBusy"
        @onPagination="setQueryParams"
      />
    </b-card>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
import { mapActions, mapGetters } from 'vuex';
import CpTokenTransactionsTable from './token-transactions-table';
import { BLOCKCHAIN_NETWORK_MAPPER } from '../options';
import CpMixPanel, { TrackingTypes } from '../../../../../../mixins/mix-panel';

export default {
  name: 'CpIssuanceTransactions',
  components: {
    CpTokenTransactionsTable,
  },
  mixins: [CpMixPanel],
  props: {
    tokenName: {
      type: String,
      required: true,
    },
    tokenBlockchainNetwork: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBusy: true,
      displayTokenBlockchainNetwork: '',
      displayTokenName: '',
      queryParams: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 10,
      },
    };
  },
  computed: {
    ...mapGetters('issueDetails', ['getTokenTransactionsData', 'getTokenTransactionsTotal']),
    tokenTransactionsData() {
      return this.getTokenTransactionsData();
    },
    tokenTransactionsTotal() {
      return this.getTokenTransactionsTotal;
    },
  },
  watch: {
    $route() {
      this.enableLoad();
      this.getTokenTransactions();
      this.displayTokenName = this.tokenName;
      this.displayTokenBlockchainNetwork = BLOCKCHAIN_NETWORK_MAPPER[this.tokenBlockchainNetwork];
    },
  },
  async mounted() {
    this.trackEvent(TrackingTypes.ISSUE_DETAIL_TRANSACTIONS_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  beforeMount() {
    this.displayTokenName = this.tokenName;
    this.displayTokenBlockchainNetwork = BLOCKCHAIN_NETWORK_MAPPER[this.tokenBlockchainNetwork];
    this.getTokenTransactions();
  },
  methods: {
    ...mapActions('issueDetails', ['tokenTransactions']),
    getTokenTransactions() {
      return this.tokenTransactions({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, queryParams: this.queryParams })
        .then(() => this.disableLoad());
    },
    disableLoad() {
      this.isBusy = false;
    },
    enableLoad() {
      this.isBusy = true;
    },
    setQueryParams(queryParams) {
      this.enableLoad();
      this.queryParams = queryParams;
      this.getTokenTransactions();
    },
  },
};
</script>

<style scoped>
  .font-weight-bold{
    font-size: 1rem;
  }
</style>
