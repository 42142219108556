<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('configurationFundraiseToken.general.title') }}
          </h4>
        </div>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-striped table-borderless mb-0">
        <tbody>
          <tr>
            <td>
              <h5 class="mb-0">
                {{ $t('configurationFundraiseToken.general.input.fundraise') }}
              </h5>
            </td>
            <td>
              <Can
                :i="ActionsTypes.UPDATE"
                :a="AbilitiesTypes.CONFIGURATION_TOKEN"
              >
                <label
                  class="switcher"
                  for="fundraise"
                >
                  <input
                    id="fundraise"
                    v-model="isFundraise"
                    :disabled="isLoading"
                    type="checkbox"
                    class="switcher-input"
                    @change="changeFundraise"
                  >
                  <span class="switcher-indicator">
                    <span class="switcher-yes" />
                    <span class="switcher-no" />
                  </span>
                </label>
              </Can>
            </td>
            <td>
              <span :class="`badge badge-${enableFundraise ? 'success' : 'danger'} ${locale}`">
                {{ getStatus(enableFundraise) }}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h5 class="mb-0">
                {{ $t('configurationFundraiseToken.general.input.tokenLifecycleManagement') }}
              </h5>
            </td>
            <td>
              <Can
                :i="ActionsTypes.UPDATE"
                :a="AbilitiesTypes.CONFIGURATION_TOKEN"
              >
                <label
                  class="switcher"
                  for="lifecycle"
                >
                  <input
                    id="lifecycle"
                    v-model="isLifecycle"
                    :disabled="isLoading"
                    type="checkbox"
                    class="switcher-input"
                    @change="changeLifecycle"
                  >
                  <span class="switcher-indicator">
                    <span class="switcher-yes" />
                    <span class="switcher-no" />
                  </span>
                </label>
              </Can>
            </td>
            <td>
              <span :class="`badge badge-${enableTokenLifecycleManagement ? 'success' : 'danger'} ${locale}`">
                {{ getStatus(enableTokenLifecycleManagement) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <cp-confirm-modal
      ref="cpConfirmModal"
      @onOk="save"
      @onCancel="setInitial"
    >
      {{ $t('configurationFundraiseToken.generalConfirmModal.message.confirm') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Can } from '@casl/vue';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';

export default {
  name: 'CpTokenGeneral',

  components: {
    Can,
    CpConfirmModal,
   },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    enableFundraise: {
      type: Boolean,
      default: false,
    },
    enableTokenLifecycleManagement: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFundraise: null,
      isLifecycle: null,
      fieldName: '',
      ActionsTypes,
      AbilitiesTypes,
    };
  },

  computed: {
    ...mapGetters('global', ['locale']),
  },

  mounted() {
    this.setInitial();
  },

  methods: {
    ...mapActions('configToken', [
      'enableTokenFundraise',
      'disableTokenFundraise',
      'enableTokenLifecycle',
      'disableTokenLifecycle',
    ]),
    getStatus(value) {
      return value ? this.$t('common.label.active') : this.$t('common.label.inactive');
    },
    changeFundraise(e) {
      this.isFundraise = e.target.checked;
      this.fieldName = 'fundraise';
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    changeLifecycle(e) {
      this.isLifecycle = e.target.checked;
      this.fieldName = 'lifecycle';
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    async fundraise() {
      if (this.isFundraise) {
        await this.enableTokenFundraise({
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
        });
      } else {
        await this.disableTokenFundraise({
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
        });
      }
    },
    async lifecycle() {
      if (this.isLifecycle) {
        await this.enableTokenLifecycle({
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
        });
      } else {
        await this.disableTokenLifecycle({
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
        });
      }
    },
    save() {
      this[this.fieldName]();
      this.$refs.cpConfirmModal.$refs.confirmModal.hide();
    },
    setInitial() {
      this.isFundraise = this.enableFundraise;
      this.isLifecycle = this.enableTokenLifecycleManagement;
      this.fieldName = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .badge{
    min-width:70px;
    &.ja {
      min-width:90px;
    }
  }
  .switcher-indicator,
  .switcher{
    cursor: pointer;
  }
</style>
