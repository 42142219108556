import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';
import issuerStore from '@/store/modules/issuer-list';

export default async () => {
  const {
    state: { locale, MixPanel },
  } = globalStore;

  const {
    state: {
      id,
      idUUID,
      name,
      email,
      authorizationLevel,
      externalId,
      authorizations,
      picture,
      isViewOnly,
    },
  } = currentOperatorStore;

  const params = {
    MixPanel,
  };

  return Frame(
    'fundraisecontent',
    'Fundraise Content',
    './FundraiseContentApp',
    process.env.VUE_APP_FUNDRAISE_CONTENT_MFE_ENTRY_POINT,
    () => {
      const {
        state: { current },
      } = issuerStore;

      return {
        operatorId: idUUID,
        locale,
        issuerId: current.id,
        operatorInfo: {
          operatorId: id,
          idUUID,
          name,
          email,
          authorizationLevel,
          externalId,
          authorizations,
          picture,
          isViewOnly,
        },
      };
    },
    params,
  );
};
