<template>
  <div>
    <cp-head-table
      title="System configuration"
      title-icon="ios-paper mr-2"
    />

    <cp-table
      ref="cpTable"
      get-data-action="brokerDealerSystemConfig/getSystemConfigs"
      :fields="fields"
      without-search
      without-pagination
    >
      <template
        slot="title"
        slot-scope="{ rowData: { item } }"
      >
        {{ getTitle(item.title) }}
      </template>
      <template
        slot="isEnabled"
        slot-scope="{ rowData: { item } }"
      >
        <cp-switch
          :key="item.title"
          :disabled="isLoading"
          :name="item.title"
          :value="item.isEnabled"
          @input="updateConfig(item)"
        />
      </template>
    </cp-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import { CpSwitch } from '@/components/common/standalone-components/inputs';
import fields from './fields';
import CpMixPanel, { TrackingTypes } from '../../../mixins/mix-panel';

export default {
  name: 'BrokerDealerSystemConfig',
  components: {
    CpHeadTable,
    CpTable,
    CpSwitch,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      fields,
    };
  },
  mounted() {
    this.trackEvent(TrackingTypes.MARKETS_SYSTEM_CONFIG_LAND);
  },
  methods: {
    ...mapActions('brokerDealerSystemConfig', ['updateSystemConfig']),
    async updateConfig(item) {
      this.isLoading = true;
      await this.updateSystemConfig({ ...item, isEnabled: !item.isEnabled });
      this.$refs.cpTable.updateTableData();
      this.isLoading = false;
    },
    getTitle(title) {
      switch (title) {
        case 'tradingTab':
          return 'Trading';
        case 'marketTab':
          return 'Primary Markets';
        default:
          return title;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
