import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';


class SecuritizeIdCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2' };

    that.authorizationHeader = function authorizationHeader() {
      // const _store = operatorStore;
      // that.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = { };
      // localVarHeaderParams.authorization = `Bearer ${that.configuration.apiKey}`;

      return localVarHeaderParams;
    };
    /** ***************************************************************************
     *                           INVESTORS
     ****************************************************************************** */
    this.getFilteredInvestors = function getFilteredInvestors(params) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors`;
      const localVarHeaderParams = that.authorizationHeader();
      return axios.post(localVarPath, params, {
        headers: localVarHeaderParams,
      });
    };
    this.getInvestorKycHistory = function getInvestorKycHistory(investorId) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${investorId}/kyc-history`;
      const localVarHeaderParams = that.authorizationHeader();
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getCsvInvestors = function getCsvInvestors(params) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/csv`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...{ accept: 'text/csv' } };
      return axios.post(localVarPath, params, {
        headers: localVarHeaderParams,
      });
    };
    this.getSecuritizeIdInvestorById = function getSecuritizeIdInvestorById(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getSecuritizeIdAuthorizedAccount = function getSecuritizeIdAuthorizedAccount(investorId) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-account`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getSecuritizeIdAuthorizedByAccounts = function getSecuritizeIdAuthorizedByAccounts(investorId) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-by-accounts`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.sendSecuritizeIdAuthorizedAccountRequest = function sendSecuritizeIdAuthorizedAccountRequest({ email, role, investorId }) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-account`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, { email, role }, {
        headers: localVarHeaderParams,
      });
    };
    this.changeAuthorizedAccoutnRole = function changeAuthorizedAccoutnRole(investorId, email, inviteeUserId, newRole) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-account/role`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, { email, inviteeUserId, newRole }, {
        headers: localVarHeaderParams,
      });
    };
    this.removeSecuritizeIdAuthorizedAccount = function removeSecuritizeIdAuthorizedAccount(email, investorId) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-account`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.delete(localVarPath, { data: { email } }, {
        headers: localVarHeaderParams,
      });
    };
    this.transferOwnershipOfSecuritizeIdAuthorizedAccount = function transferOwnershipOfSecuritizeIdAuthorizedAccount(newOwnerUserId, investorId) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/authorized-account/transfer-ownership`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, { newOwnerUserId }, {
        headers: localVarHeaderParams,
      });
    };
    this.createInvestor = function createInvestor(investor, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/add`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, investor, {
        headers: localVarHeaderParams,
      });
    };
    this.updateSecuritizeIdInvestorById = function updateSecuritizeIdInvestorById(investorId, investor, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.put(localVarPath, investor, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteInvestorById = function deleteInvestorById(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.removeSecuritizeIdInvestor2fa = function removeSecuritizeIdInvestor2fa(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/disable2fa`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.investorResetPassword = function investorResetPassword(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/reset-password`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    /** ***************************************************************************
     *                           VERIFICATION
     ****************************************************************************** */
    this.runInvestorVerificationCheck = function runInvestorVerificationCheck(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/run-verification`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, {}, {
        headers: localVarHeaderParams,
      });
    };
    this.getVerificationLinkData = function getVerificationLinkData(investorId, applicantId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/verification/link/${applicantId}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {}, {
        headers: localVarHeaderParams,
      });
    };
    this.postLinkVerification = function postLinkVerification(investorId, applicantId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/verification/link/${applicantId}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, {}, {
        headers: localVarHeaderParams,
      });
    };
    this.updateInvestorVerificationStatus = function updateInvestorVerificationStatus(investorId, data, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/update-verification-status`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, data, {
        headers: localVarHeaderParams,
      });
    };

    /** ***************************************************************************
     *                           LEGAL SIGNERS
     ****************************************************************************** */
    this.addLegalSigner = function addLegalSigner(investorId, legalSigner, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, legalSigner, {
        headers: localVarHeaderParams,
      });
    };

    this.getLegalSignerById = function getLegalSignerById(investorId, legalSignerId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getLegalSignersByInvestorId = function getLegalSignersByInvestorId(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.updateLegalSignerByInvestorId = function updateLegalSignerByInvestorId(investorId, legalSignerId, legalSigner, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.put(localVarPath, legalSigner, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteLegalSignerByInvestorId = function deleteLegalSignerByInvestorId(investorId, legalSignerId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    /** ***************************************************************************
     *                           LEGAL SIGNERS DOCUMENTS
     ****************************************************************************** */
    this.addLegalSignerDocument = function addLegalSignerDocument(investorId, legalSignerId, document, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, document, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteLegalSignerDocumentById = function deleteLegalSignerDocumentById(investorId, legalSignerId, documentId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}/legal-signer-documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getLegalSignerDocumentById = function getLegalSignerDocumentById(investorId, legalSignerId, documentId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}/legal-signer-documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    /** ***************************************************************************
     *                           INVESTORS DOCUMENTS
     ****************************************************************************** */
    this.getDocumentsByInvestorId = function getDocumentsByInvestorId(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getConfidentialDocumentsByInvestorId = function getConfidentialDocumentsByInvestorId(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/confidential-documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.createInvestorDocument = function createInvestorDocument(investorId, document, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, document, {
        headers: localVarHeaderParams,
      });
    };
    this.createInvestorConfidentialDocument = function createInvestorConfidentialDocument(investorId, document, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/confidential-documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, document, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteInvestorDocumentById = function deleteInvestorDocumentById(investorId, documentId, isTaxForm, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        params: { isTaxForm },
        headers: localVarHeaderParams,
      });
    };
    this.deleteInvestorConfidentialDocumentById = function deleteInvestorConfidentialDocumentById(investorId, documentId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/confidential-documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getInvestorDocumentById = function getInvestorDocumentById(investorId, documentId, isTaxForm, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        params: { isTaxForm },
        headers: localVarHeaderParams,
      });
    };
    this.getInvestorDocumentBlob = function getInvestorDocumentBlob({ investorId, key, isTaxForm }, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents/${key}/download`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        params: { isTaxForm },
        headers: localVarHeaderParams,
        responseType: 'blob',
      });
    };
    this.getInvestorConfidentialDocumentById = function getInvestorConfidentialDocumentById(investorId, documentId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/confidential-documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getWalletsByInvestorId = function getWalletsByInvestorId(investorId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/wallets`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.createInvestorWallet = function createInvestorWallet(investorId, wallet, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/wallets`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, wallet, {
        headers: localVarHeaderParams,
      });
    };
    this.removeInvestorWallet = function removeInvestorWallet(investorId, walletId, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/wallets/${encodeURIComponent(walletId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.updateSecuritizeIdInvestorDocuments = function updateSecuritizeIdInvestorDocuments(investorId, documents, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, { documents }, {
        headers: localVarHeaderParams,
      });
    };
    this.updateSecuritizeIdInvestorConfidentialDocuments = function updateSecuritizeIdInvestorConfidentialDocuments(investorId, documents, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/confidential-documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, { documents }, {
        headers: localVarHeaderParams,
      });
    };
    this.updateSecuritizeIdInvestorLegalSignerDocumentsStatus = function updateSecuritizeIdInvestorLegalSignerDocumentsStatus(investorId, legalSignerId, documents, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/investors/${encodeURIComponent(investorId)}/legal-signers/${legalSignerId}/legal-signer-documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, { documents }, {
        headers: localVarHeaderParams,
      });
    };
    /** ***************************************************************************
     *                           TRANSLATIONS
     ****************************************************************************** */
    this.getFilteredTranslations = function getFilteredInvestors(params) {
      const localVarPath = `${that.configuration.basePath}/translations`;
      const localVarHeaderParams = that.authorizationHeader();
      return axios.get(localVarPath, {
        params,
        headers: localVarHeaderParams,
      });
    };
    this.updateTranslationByKeyAndSystem = function updateTranslationByKeyAndSystem(key, system, values, headers) {
      const localVarPath = `${that.configuration.basePath}/${system}/translations/${key}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.put(localVarPath, { values }, {
        headers: localVarHeaderParams,
      });
    };
    this.importTranslationsCsv = function importTranslationsCsv(data, headers) {
      const localVarPath = `${that.configuration.basePath}/securitize-id/translations/import`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, data, {
        headers: localVarHeaderParams,
      });
    };
    /** ***************************************************************************
     *                           LABELS
     ****************************************************************************** */
    this.getSecuritizeIdLabels = (params) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/labels`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.get(localVarPath, {
        params,
        headers: localVarHeaderParams,
      });
    };
    this.deleteSecuritizeIdLabel = (labelId) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/labels/${labelId}`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.createSecuritizeIdLabel = (body) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/labels`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.post(localVarPath, body, {
        headers: localVarHeaderParams,
      });
    };
    this.updateSecuritizeIdInvestorsLabels = (body) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/investors/labels`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.patch(localVarPath, body, {
        headers: localVarHeaderParams,
      });
    };
    this.getSecuritizeIdBlacklistedWallets = ({ 'order-direction': orderDirection, ...params }) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/blacklist`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.get(localVarPath, {
        params,
        headers: localVarHeaderParams,
      });
    };
    this.getSecuritizeIdWhitelistedWallets = ({ 'order-direction': orderDirection, ...params }) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/whitelist`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.get(localVarPath, {
        params,
        headers: localVarHeaderParams,
      });
    };
    this.removeWalletFromBlackList = (walletId) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/blacklist/${walletId}`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.removeWalletFromWhitelist = (walletId) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/whitelist/${walletId}`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.addWalletToBlackList = (wallet) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/blacklist`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.post(localVarPath, wallet, {
        headers: localVarHeaderParams,
      });
    };
    this.addWalletToWhitelist = (wallet) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/wallets/whitelist`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.post(localVarPath, wallet, {
        headers: localVarHeaderParams,
      });
    };
    this.getDocumentOptions = ({ investorType, investorFacing }) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/documents`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.get(localVarPath, {
        params: {
          investorType,
          investorFacing,
        },
        headers: localVarHeaderParams,
      });
    };
    this.getKeyPartyDocumentOptions = ({ keyPartyType, investorFacing }) => {
      const localVarPath = `${this.configuration.basePath}/securitize-id/key-party-documents`;
      const localVarHeaderParams = this.authorizationHeader();
      return axios.get(localVarPath, {
        params: {
          keyPartyType,
          investorFacing,
        },
        headers: localVarHeaderParams,
      });
    };
  }
}

export const {
  getDocumentsByInvestorId,
  getConfidentialDocumentsByInvestorId,
  getInvestorDocumentById,
  getInvestorDocumentBlob,
  getInvestorConfidentialDocumentById,
  createInvestorDocument,
  deleteInvestorDocumentById,
  deleteInvestorConfidentialDocumentById,
  addLegalSigner,
  getLegalSignersByInvestorId,
  getLegalSignerById,
  updateLegalSignerByInvestorId,
  deleteLegalSignerByInvestorId,
  addLegalSignerDocument,
  deleteLegalSignerDocumentById,
  getLegalSignerDocumentById,
  getFilteredInvestors,
  getCsvInvestors,
  createInvestor,
  updateSecuritizeIdInvestorById,
  deleteInvestorById,
  removeSecuritizeIdInvestor2fa,
  runInvestorVerificationCheck,
  getVerificationLinkData,
  postLinkVerification,
  getSecuritizeIdInvestorById,
  investorResetPassword,
  updateInvestorVerificationStatus,
  createInvestorConfidentialDocument,
  getWalletsByInvestorId,
  createInvestorWallet,
  removeInvestorWallet,
  getInvestorKycHistory,
  updateSecuritizeIdInvestorDocuments,
  updateSecuritizeIdInvestorConfidentialDocuments,
  updateSecuritizeIdInvestorLegalSignerDocumentsStatus,
  getFilteredTranslations,
  updateTranslationByKeyAndSystem,
  importTranslationsCsv,
  getSecuritizeIdLabels,
  deleteSecuritizeIdLabel,
  createSecuritizeIdLabel,
  updateSecuritizeIdInvestorsLabels,
  getSecuritizeIdBlacklistedWallets,
  getSecuritizeIdWhitelistedWallets,
  removeWalletFromBlackList,
  removeWalletFromWhitelist,
  addWalletToBlackList,
  addWalletToWhitelist,
  getSecuritizeIdAuthorizedAccount,
  getSecuritizeIdAuthorizedByAccounts,
  sendSecuritizeIdAuthorizedAccountRequest,
  removeSecuritizeIdAuthorizedAccount,
  transferOwnershipOfSecuritizeIdAuthorizedAccount,
  changeAuthorizedAccoutnRole,
  getDocumentOptions,
  getKeyPartyDocumentOptions,
} = new SecuritizeIdCpApi();
