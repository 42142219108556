<template>
  <div>
    <div>
      <div class="row align-items-center ml-0 mr-0">
        <div>
          <cp-select
            v-model="languageSelected"
            :label="$t('opportunities.editOpportunity.content.language.label')"
            name="languageContents"
            :options="formattedLanguages"
            :disabled="loading"
            @input="setLanguage($event)"
          />
        </div>
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.CONFIGURATION_ASSET_CONTENT"
        >
          <div
            class="card-body mt-2"
          >
            <label
              class="switcher"
              for="contentsPublished"
            >
              <span class="ml-3">{{
                $t('opportunities.editOpportunity.content.language.publishText') }}</span>
              <input
                id="contentsPublished"
                v-model="contentsPublished"
                type="checkbox"
                class="switcher-input"
                :disabled="publishDisabled"
                @input="setPublish($event)"
              >
              <span class="switcher-indicator">
                <span class="switcher-yes" />
                <span class="switcher-no" />
              </span>
            </label>
          </div>
        </Can>
      </div>
    </div>
    <div class="col-md-12 text-center">
      <b-spinner
        v-if="loading"
        class="align-middle"
      />
    </div>
    <div
      v-if="!loading"
      class="card mb-5"
    >
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4
              class="mb-0 text-primary"
            >
              {{ $t('opportunities.editOpportunity.content.options.headerText') }}
            </h4>
          </div>
          <Can
            :i="ActionsTypes.UPDATE"
            :a="AbilitiesTypes.CONFIGURATION_ASSET_CONTENT"
          >
            <cp-edit-buttons
              :edit-mode="editMode"
              @changeMode="changeModeProxy"
              @update="saveOpportunity"
            />
          </Can>
        </div>
      </div>
      <div
        v-if="editMode"
        class="card-body"
      >
        <label
          class="switcher"
          for="contentsRequireKyc"
        >
          <span class="ml-3">{{ (model.contentsRequireKyc) ?
            $t('opportunities.editOpportunity.content.options.kycRequiredText'):
            $t('opportunities.editOpportunity.content.options.noKycRequiredText') }}</span>
          <input
            id="contentsRequireKyc"
            v-model="model.contentsRequireKyc"
            type="checkbox"
            class="switcher-input"
          >
          <span class="switcher-indicator">
            <span class="switcher-yes" />
            <span class="switcher-no" />
          </span>
        </label>
      </div>
      <div
        v-else
        class="card-body"
      >
        <span :class="`badge m-2 h4  badge-${currentOpportunity.contentsRequireKyc ? 'success' : 'dark'}`">
          {{ (currentOpportunity.contentsRequireKyc) ? $t('opportunities.editOpportunity.content.options.kycRequiredText') : $t('opportunities.editOpportunity.content.options.noKycRequiredText') }}
        </span>
      </div>
    </div>
    <div v-if="!loading">
      <div
        v-for="(content) in sortedContents"
        :key="content.id"
        class="row"
      >
        <div class="col">
          <content-text-box
            v-if="content.attributeType==='text'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
          <content-video-box
            v-if="content.attributeType==='video'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
          <content-image-box
            v-if="content.attributeType === 'img_with_description'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
          <content-telegram-box
            v-if="content.attributeType === 'telegram'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
          <content-documents-box
            v-if="content.attributeType === 'documents'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
          <content-progress-box
            v-if="content.attributeType === 'progress'"
            :edit-content-mode="!content.id"
            :content="content"
            :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
            @onSave="save"
            @onDeleteAction="deleteAction"
          />
        </div>
      </div>
      <div
        v-for="(content) in contents"
        :key="content.tempId"
        class="row un-saved "
      >
        <content-video-box
          v-if="content.attributeType === 'video'"
          :edit-content-mode="!content.id"
          :content="content"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
        <content-text-box
          v-if="content.attributeType === 'text'"
          :edit-content-mode="!content.id"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :content="content"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
        <content-image-box
          v-if="content.attributeType === 'img_with_description'"
          :edit-content-mode="!content.id"
          :content="content"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
        <content-telegram-box
          v-if="content.attributeType === 'telegram'"
          :edit-content-mode="!content.id"
          :content="content"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
        <content-documents-box
          v-if="content.attributeType === 'documents'"
          :edit-content-mode="!content.id"
          :content="content"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
        <content-progress-box
          v-if="content.attributeType === 'progress'"
          :edit-content-mode="!content.id"
          :content="content"
          :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          :can-delete="$can(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT)"
          @onSave="save"
          @onDeleteAction="deleteAction"
        />
      </div>
      <div class="sctoll-to-here" />
      <b-modal
        v-model="addModalOpen"
        no-fade
        centered
        hide-footer
      >
        <div>
          <h4 class="modal-title text-center ">
            {{ $t('opportunities.editOpportunity.content.add.modal.title.label') }}
          </h4>
        </div>
        <div class="side row d-flex align-items-center justify-content-center">
          <span class="col-4 p-0">
            <b-button
              block
              variant="outline-dark"
              class="leftColumn mt-3"
              :class="{'active': side === 'left'}"
              @click="sideSelect('left')"
            >
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.side.options.left') }}</span>
            </b-button>
          </span>
          <span class="col-4 p-0">
            <b-button
              block
              variant="outline-dark"
              class="rightColumn mt-3"
              :class="{'active': side === 'right'}"
              @click="sideSelect('right')"
            >
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.side.options.right') }}</span>
            </b-button>
          </span>
        </div>
        <b-button
          v-if="side !== 'right'"
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('video')"
          @click="addContent('video')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-ios-videocam"
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.video.title') }}</span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.video.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <b-button
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('text')"
          @click="addContent('text')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-ios-paper"
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.text.title') }}</span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.text.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <b-button
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('img_with_description')"
          @click="addContent('img_with_description')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-ios-image  "
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.imgWithDesc.title') }}
              </span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.imgWithDesc.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <b-button
          v-if="side !== 'right'"
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('telegram')"
          @click="addContent('telegram')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-ios-chatboxes"
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.telegram.title') }}
              </span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.telegram.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <b-button
          v-if="side !== 'right'"
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('documents')"
          @click="addContent('documents')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-ios-paper"
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.documents.title') }}</span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.documents.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <b-button
          v-if="side !== 'right'"
          block
          variant="outline-dark"
          class="mt-3"
          :disabled="analyzeContent('progress')"
          @click="addContent('progress')"
        >
          <span class="row">
            <span class="col-1 d-flex align-items-center">
              <i
                class="ion ion-md-analytics"
              />
            </span>
            <span class="col-11 text-left">
              <span class="h5 mb-1 mt-2 d-block">  {{ $t('opportunities.editOpportunity.content.add.modal.buttons.progress.title') }}</span>
              <span class="d-block  font-weight-light">
                {{ $t('opportunities.editOpportunity.content.add.modal.buttons.progress.description') }}
              </span>
            </span>
          </span>
        </b-button>
        <span
          v-if="side === 'right'"
          class="d-flex align-items-center mt-4"
        >
          <b-img
            width="30"
            :src="infoIcon"
            class="mr-3"
          />
          {{ $t('opportunities.editOpportunity.content.add.modal.buttons.side.infoRightMsg') }}
        </span>
      </b-modal>
      <cp-confirm-modal
        ref="cpConfirmDeleteModal"
        :ok-text="$t('opportunities.editOpportunity.content.deleteConfirmation.deleteButton')"
        @onOk="runDeleteORequest"
      >
        <h3>
          {{ $t('opportunities.editOpportunity.content.deleteConfirmation.title') }}
          <span class="font-weight-light"> '{{ opportunityContentSelected.title }}' </span>
        </h3>
        <p>
          {{ $t('opportunities.editOpportunity.content.deleteConfirmation.content') }}
        </p>
      </cp-confirm-modal>
      <div class="text-center">
        <Can
          :i="ActionsTypes.CREATE"
          :a="AbilitiesTypes.CONFIGURATION_ASSET_CONTENT"
        >
          <b-button
            variant="primary"
            size="lg"
            class="mb-5 pl-5 pr-5 d-inline-block"
            @click="addContentAction"
          >
            <i
              class="ion ion-ios-add-circle"
            />    {{ $t('opportunities.editOpportunity.content.add.button.text') }}
          </b-button>
        </Can>
      </div>
    </div>
    <hr v-if="!loading">
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { sanitize } from 'dompurify';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../../utilities/ability/abilities';
import CpEditButtons from '~/components/common/edit-buttons';
import ContentVideoBox from '../../components/content-video-box';
import ContentTextBox from '../../components/content-text-box';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import ContentImageBox from '@/pages/_idIssuer/configuration/opportunities/components/content-image-box';
import ContentTelegramBox from '@/pages/_idIssuer/configuration/opportunities/components/content-telegram-box';
import ContentDocumentsBox from '@/pages/_idIssuer/configuration/opportunities/components/content-documents-box';
import ContentProgressBox from '@/pages/_idIssuer/configuration/opportunities/components/content-progress-box';
import CpEditableMixin from '~/mixins/editable-component';
import infoIcon from '~/assets/img/infoIcon.svg';

export default {
  name: 'EditOpportunitySummary',
  components: {
    Can,
    CpEditButtons,
    ContentImageBox,
    ContentVideoBox,
    ContentTextBox,
    ContentTelegramBox,
    CpConfirmModal,
    CpSelect,
    ContentDocumentsBox,
    ContentProgressBox,
  },
  mixins: [CpEditableMixin],
  data() {
    return {
      isReady: false,
      contents: [],
      addModalOpen: false,
      opportunityContentSelected: {},
      side: 'left',
      infoIcon,
      model: {},
      languageSelected: 'EN',
      formattedLanguages: [],
      contentsPublished: true,
      loading: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('opportunities', ['currentOpportunity', 'languages']),
    sortedContents() {
      const sanitizeContent = (content) => {
        content.content = sanitize(content.content);
        return content;
      };
      const { contents } = this.currentOpportunity.languageDetails[this.languageSelected];
      const toSort = contents ? contents.items : [];
      return toSort.map(a => sanitizeContent(a)).sort((a, b) => a.order - b.order);
    },
    publishDisabled() {
      const { contents } = this.currentOpportunity.languageDetails[this.languageSelected];
      return this.languageSelected === 'EN'
        || this.loading
        || (contents && contents.items.length === 0 && !this.contentsPublished);
    },
  },
  created() {
    this.formatLanguages();
    if (this.$route.query.edit) {
      this.setConfigState();
      this.editMode = true;
    }
  },
  methods: {
    ...mapActions('opportunities', ['getOpportunityById', 'updateOpportunity', 'updateOpportunityContent', 'deleteOpportunityContent', 'addOpportunityContent', 'getLanguages', 'updatePublishContent', 'createLanguageContent']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    setConfigState() {
      this.model = { contentsRequireKyc: this.currentOpportunity.contentsRequireKyc };
    },
    addContentAction() {
      this.addModalOpen = true;
    },
    addContent(type) {
      const tempId = Math.random() * 10000;
      this.contents.push({ opportunityId: this.currentOpportunity.id, attributeType: type, order: 0, tempId, alignment: 'none', side: this.side });
      this.addModalOpen = false;
      this.side = 'left';
      setTimeout(() => { this.scrollToBottom(); }, 100);
    },
    sideSelect(sideSelected) {
      this.side = sideSelected;
    },
    analyzeContent(type) {
      if (this.side === 'left') return false;
      const contents = this.currentOpportunity.languageDetails[this.languageSelected].contents.items.concat(this.contents).filter(content => content.side === 'right');
      if (contents.length >= 2 || contents.find(content => content.attributeType === type)) return true;
    },
    scrollToBottom() {
      const el = this.$el.getElementsByClassName('sctoll-to-here')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    changeModeProxy() {
      if (this.editMode) {
        this.$router.push({ query: { edit: undefined } });
        this.customErrors = '';
      } else {
        this.$router.push({ query: { edit: 1 } });
        this.setConfigState();
      }
      this.changeMode();
    },
    async saveOpportunity() {
      await this.updateOpportunity({
        issuerId: this.currentOpportunity.issuerId,
        opportunityId: this.currentOpportunity.id,
        opportunity: {
          ...this.model,
          tokenId: this.currentOpportunity.tokenId,
          title: this.currentOpportunity.title,
          content: this.currentOpportunity.content,
        },
      });
      await this.getOpportunityById({ issuerId: this.currentOpportunity.issuerId, opportunityId: this.currentOpportunity.id, additionalFields: 'attributes,documents,contents,languages', language: this.languageSelected });
      this.changeModeProxy();
    },
    async save(opportunityContent) {
      try {
        const { idIssuer: issuerId } = this.$route.params;
        opportunityContent.saving = true;
        opportunityContent.language = this.languageSelected;
        // To add backward compatibility with old contents without this attribute
        if (!opportunityContent.alignment) opportunityContent.alignment = 'none';
        const opportunityId = this.currentOpportunity.id;
        this.loading = true;
        if (opportunityContent.id === undefined) {
          await this.addOpportunityContent({ issuerId, opportunityId, opportunityContent });
        } else {
          await this.updateOpportunityContent({ issuerId, opportunityId, opportunityContentId: opportunityContent.id, opportunityContent });
        }
        await this.getOpportunityById({ issuerId, opportunityId, additionalFields: 'attributes,documents,contents,languages', language: this.languageSelected });
        if (opportunityContent.tempId) {
          this.contents = this.contents.filter(obj => obj.tempId !== opportunityContent.tempId);
        }
      } catch (error) {
        this.CALL_ERROR_TOASTER('Error trying to update content:', error);
      } finally {
        opportunityContent.saving = false;
        this.loading = false;
      }
    },
    deleteAction(opportunityContent) {
      if (opportunityContent.tempId) {
        this.contents = this.contents.filter(obj => obj.tempId !== opportunityContent.tempId);
      } else {
        this.opportunityContentSelected = { ...opportunityContent };
        this.$refs.cpConfirmDeleteModal.show();
      }
    },
    async runDeleteORequest() {
      try {
        const { idIssuer: issuerId } = this.$route.params;
        const opportunityId = this.currentOpportunity.id;
        this.loading = true;
        await this.deleteOpportunityContent({ issuerId, opportunityId, opportunityContentId: this.opportunityContentSelected.id });
        await this.getOpportunityById({ issuerId, opportunityId, additionalFields: 'attributes,documents,contents,languages', language: this.languageSelected });
      } catch (error) {
        this.CALL_ERROR_TOASTER('Error trying to delete content:', error);
      } finally {
        this.loading = false;
      }
    },
    formatLanguages() {
      this.formattedLanguages = this.languages.map(language => ({
           value: language.key,
           text: language.description,
        }));
    },
    async setLanguage(language) {
      this.languageSelected = language;
      if (!this.currentOpportunity.languageDetails[language].contents) {
        this.loading = true;
        const { idIssuer: issuerId } = this.$route.params;
        const opportunityId = this.currentOpportunity.id;
        try {
          await this.createLanguageContent({ issuerId, opportunityId, language: this.languageSelected });
          await this.getOpportunityById({ issuerId, opportunityId, additionalFields: 'attributes,documents,contents,languages', language: this.languageSelected });
        } catch (error) {
          this.CALL_ERROR_TOASTER('Set language error:', error);
        } finally {
          this.loading = false;
        }
      }
      this.contentsPublished = this.currentOpportunity.languageDetails[language].contents.published;
    },
    async setPublish() {
      const { idIssuer: issuerId } = this.$route.params;
      const opportunityId = this.currentOpportunity.id;
      try {
        this.loading = true;
        await this.updatePublishContent({ issuerId, opportunityId, published: !this.contentsPublished, language: this.languageSelected });
        await this.getOpportunityById({ issuerId, opportunityId, additionalFields: 'attributes,documents,contents,languages', language: this.languageSelected });
      } catch (error) {
        this.CALL_ERROR_TOASTER('Publish error:', error);
      } finally {
        this.loading = false;
      }
      this.contentsPublished = this.currentOpportunity.languageDetails[this.languageSelected].contents.published;
    },
  },
};
</script>
<style lang="scss" scope>
.un-saved{
  .edit-mode-cancel-button{
    display:none;
  }
}
.saving{
  border:solid 2px red;
}
.side {
  :focus{
    border-color: transparent;
    background: rgba(10, 23, 39, 0.9);
    color: #fff;
  }
  .leftColumn {
    border-radius: 0.25rem 0rem 0rem 0.25rem;
  }
  .rightColumn {
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }
}
</style>
