import { MixPanel } from '../../../utilities/mixPanel';

export default {
  countries: [],
  currencies: [],
  issuers: [],
  issuersNavList: [],
  user: {},
  currenciesIsReady: false,
  currenciesErrors: [],
  countriesIsReady: false,
  countriesErrors: [],
  issuerMainCurrencyIdentifier: '',
  statesAndRegions: [],
  issuerLabels: [],
  filterState: {
    investorsOnboarding: {},
    investorsFundraise: {},
    investorsHolders: {},
    investorsOutreach: {},
    tokenTransactions: {},
    securitizeIdInvestors: {},
    brokerDealerInvestors: {},
    auditLog: {},
    auditLogIssuer: {},
    clientConfiguration: {},
    snapshot: {},
  },
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  MixPanel,
};
