import { buildAbilityFor } from '~/utilities/ability/abilities';

export default {
  id: '',
  idUUID: '',
  email: '',
  name: '',
  authorizationLevel: '',
  authorizations: [],
  externalId: '',
  organizationId: '',
  isAuthenticated: false,
  isViewOnly: false,
  viewData: {},
  notificationsCount: null,
  notifications: [],
  securitizeIdNotificationsCount: null,
  securitizeIdNotifications: [],
  registerToken: '',
  twoFaUri: '',
  ability: buildAbilityFor(),
};
