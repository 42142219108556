import { AbilityBuilder, Ability } from '@casl/ability';

export const ActionsTypes = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};
export const AbilitiesTypes = {
  HOLDERS_INVESTOR: 'holders-investor',
  HOLDERS_IMPORT_ISSUANCE: 'holders-import-issuance',
  HOLDERS_EXPORT_LIST: 'holders-export-list',
  HOLDERS_EDIT_LABELS: 'holders-edit-labels',
  HOLDERS_SHOW_LABELS: 'holders-show-labels',
  HOLDERS_DELETE_INVESTOR: 'holders-delete-investor',
  FUNDRAISE: 'fundraise',
  SIGNATURES: 'signatures',
  INVESTOR_ENGAGEMENT: 'investor-engagement',
  CONFIGURATION_FUNDARAISE: 'configuration-fundraise',
  CONFIGURATION_FUNDARAISE_ROUND: 'configuration-fundraise-round',
  CONFIGURATION_TOKEN: 'configuration-token',
  CONFIGURATION_TOKEN_DOCUMENTS: 'configuration-token-documents',
  CONFIGURATION_TOKEN_HISTORICAL_VALUES: 'configuration-token-historical-values',
  CONFIGURATION_ASSET_CONTENT: 'configuration-asset-content',
  CONFIGURATION_JURISDICTIONS: 'configuration-jurisdictions',
  ONBOARDING: 'onboarding',
  ISSUER_CONFIGURATION_GENERAL: 'issuer-configuration-general',
  ISSUER_JURISDICTIONS: 'issuer-jurisdictions',
  ISSUER_OPPORTUNITIES: 'issuer-opportunities',
  ISSUER_AFFILIATES: 'issuer-affiliates',
  ISSUER_AGREEMENTS: 'issuer-agreements',
  ISSUER_BANK_ACCOUNTS: 'issuer-bank-accounts',
  ISSUER_CONFIGURATION_VARIABLES: 'issuer-configuration-variables',
  ISSUER_CURRENCIES: 'issuer-currencies',
  ISSUER_EMAIL_TEMPLATES: 'issuer-email-templates',
  ISSUER_PLATFORM_DOCUMENTS: 'issuer-platform-documents',
  ISSUER_QUESTIONS: 'issuer-questions',
  ISSUER_TEXTS: 'issuer-texts',
  ISSUER_PRERENDERED_ADDRESSES: 'issuer-prerendered-addresses',
  PRIVACY_CONTROL: 'privacy-control',
  PANEL_ADMINISTRATION: 'panel-administration',
  SYSTEM_CONFIG_AGREEMENTS: 'system-config-agreements',
  SYSTEM_CONFIG_QUESTIONS: 'system-config-questions',
  SYSTEM_CONFIG_COUNTRIES: 'system-config-countries',
  SYSTEM_CONFIG_EMAIL_TEMPLATES: 'system-config-email-templates',
  SYSTEM_CONFIG_SYSTEM_TEXTS: 'system-config-system-texts',
  SYSTEM_CONFIG_CLIENT_CONFIGURATION: 'system-config-client-configuration',
  ALL: 'all',
};

export function defineRulesFor(operator) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  can(ActionsTypes.MANAGE, AbilitiesTypes.ALL);

  if (operator?.isViewOnly) {
    // -- Holders --//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_INVESTOR);
    cannot(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_IMPORT_ISSUANCE);
    cannot(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_EXPORT_LIST);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.HOLDERS_INVESTOR);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.HOLDERS_EDIT_LABELS);
    cannot(ActionsTypes.READ, AbilitiesTypes.HOLDERS_SHOW_LABELS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.HOLDERS_DELETE_INVESTOR);

    // ---- Fundraise ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.FUNDRAISE);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.FUNDRAISE);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.FUNDRAISE);

    // -- Signatures --//
    cannot(ActionsTypes.READ, AbilitiesTypes.SIGNATURES);

    // -- Investor Engagement --//
    cannot(ActionsTypes.READ, AbilitiesTypes.INVESTOR_ENGAGEMENT);

    // -- Configuration --//
    // ---- Fundraise ----//
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_FUNDARAISE);
    cannot(ActionsTypes.CREATE, AbilitiesTypes.CONFIGURATION_FUNDARAISE_ROUND);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_FUNDARAISE_ROUND);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_FUNDARAISE_ROUND);
    // ---- Token ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.CONFIGURATION_TOKEN);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_TOKEN);
    cannot(ActionsTypes.CREATE, AbilitiesTypes.CONFIGURATION_TOKEN_DOCUMENTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_TOKEN_DOCUMENTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_TOKEN_DOCUMENTS);
    cannot(ActionsTypes.CREATE, AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES);
    // ---- Asset Content ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.CONFIGURATION_ASSET_CONTENT);
    // ---- Jurisdiction ----//
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_JURISDICTIONS);
    // -- Onboarding --//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ONBOARDING);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ONBOARDING);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ONBOARDING);
    // -- Issuer Configuration --//
    // ---- General ----//
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL);
    // ---- Jurisdictions ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_JURISDICTIONS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_JURISDICTIONS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_JURISDICTIONS);
    // ---- Opportunities ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_OPPORTUNITIES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_OPPORTUNITIES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_OPPORTUNITIES);
    // -- Advanced --//
    // ---- Issuer Affiliate ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_AFFILIATES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_AFFILIATES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_AFFILIATES);
    // ---- Issuer Agreements ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_AGREEMENTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_AGREEMENTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_AGREEMENTS);
    // ---- Issuer Bank Accounts ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_BANK_ACCOUNTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_BANK_ACCOUNTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_BANK_ACCOUNTS);
    // ---- Issuer Configuration Variables ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_CONFIGURATION_VARIABLES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_VARIABLES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_CONFIGURATION_VARIABLES);
    // ---- Issuer Currencies ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_CURRENCIES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CURRENCIES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_CURRENCIES);
    // ---- Issuer Email Templates ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_EMAIL_TEMPLATES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_EMAIL_TEMPLATES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_EMAIL_TEMPLATES);
    // ---- Issuer Platform Documents ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_PLATFORM_DOCUMENTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_PLATFORM_DOCUMENTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_PLATFORM_DOCUMENTS);
    // ---- Issuer Questions ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_QUESTIONS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_QUESTIONS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_QUESTIONS);
    // ---- Issuer Texts ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_TEXTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_TEXTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_TEXTS);
    // ---- Issuer Prerendered Addresses ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_PRERENDERED_ADDRESSES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_PRERENDERED_ADDRESSES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.ISSUER_PRERENDERED_ADDRESSES);
    // -- Privacy Control --//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.PRIVACY_CONTROL);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.PRIVACY_CONTROL);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.PRIVACY_CONTROL);
    // -- Panel Administration --//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.PANEL_ADMINISTRATION);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.PANEL_ADMINISTRATION);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.PANEL_ADMINISTRATION);
    // -- System Config --//
    // ---- Agreements ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_AGREEMENTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_AGREEMENTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_AGREEMENTS);
    // ---- Questions ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_QUESTIONS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_QUESTIONS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_QUESTIONS);
    // ---- Countries ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_COUNTRIES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_COUNTRIES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_COUNTRIES);
    // ---- Email Templates ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_EMAIL_TEMPLATES);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_EMAIL_TEMPLATES);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_EMAIL_TEMPLATES);
    // ---- System Texts ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_SYSTEM_TEXTS);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_SYSTEM_TEXTS);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_SYSTEM_TEXTS);
    // ---- Client Configuration ----//
    cannot(ActionsTypes.CREATE, AbilitiesTypes.SYSTEM_CONFIG_CLIENT_CONFIGURATION);
    cannot(ActionsTypes.UPDATE, AbilitiesTypes.SYSTEM_CONFIG_CLIENT_CONFIGURATION);
    cannot(ActionsTypes.DELETE, AbilitiesTypes.SYSTEM_CONFIG_CLIENT_CONFIGURATION);
  }

  return rules;
}

// Function to build the ability based on the role
export function buildAbilityFor(operator) {
  return new Ability(defineRulesFor(operator));
}
