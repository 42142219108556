<template>
  <div>
    <div class="d-flex justify-content-end p-4">
      <b-tooltip
        v-if="disableExport"
        target="export-investors-list-tool-tip"
        triggers="hover"
      >
        {{ $t('investorsCommon.button.table.exportToolTip') }}
      </b-tooltip>
      <div id="export-investors-list-tool-tip">
        <b-button
          v-if="canExportList"
          :disabled="disabled || disableExport"
          size="sm"
          variant="outline-primary ml-3 ion ion-ios-download"
          @click="$emit('onExportList')"
        >
          {{ $t('investorsCommon.button.table.export') }}
        </b-button>
      </div>
      <b-button
        v-if="canEditLabels"
        size="sm"
        variant="outline-primary ml-3 ion ion-ios-pricetag"
        @click="$emit('onEditLabels')"
      >
        {{ $t('investorsCommon.button.table.edit') }}
      </b-button>
      <b-button
        v-if="canShowLabels"
        size="sm"
        variant="outline-primary ml-3 ion ion-ios-pricetag"
        @click="$emit('onShowLabels')"
      >
        {{ $t('investorsCommon.button.table.show') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InvestorsBatchActions',
  props: {
    disabled: {
      type: Boolean,
      disabled: false,
    },
    canExportList: {
      type: Boolean,
      default: true,
    },
    canEditLabels: {
      type: Boolean,
      default: true,
    },
    canShowLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('currentOperator', ['authorizations']),
    disableExport() {
      return !this.authorizations.includes('allow-export');
    },
  },
};
</script>
