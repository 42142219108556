<template>
  <div>
    <cp-head-table
      :title="$t('issuerQuestions.title')"
      title-icon="ios-build"
      :button-title="$t('issuerQuestions.button.question.add')"
      :show-button="$can(ActionsTypes.CREATE, AbilitiesTypes.ISSUER_QUESTIONS)"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerQuestion/getQuestions"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="section"
        slot-scope="{ rowData }"
      >
        {{ getSection(rowData.item.section) }}
      </template>
      <template
        slot="position"
        slot-scope="{ rowData }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.ISSUER_QUESTIONS"
        >
          <div class="d-flex">
            <b-button
              variant="light"
              @click="directItem(rowData.item, true)"
            >
              <i class="ion ion-md-arrow-up" />
            </b-button>
            <b-button
              variant="light"
              class="ml-1"
              @click="directItem(rowData.item)"
            >
              <i class="ion ion-md-arrow-down" />
            </b-button>
          </div>
        </Can>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.ISSUER_QUESTIONS"
        >
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.edit')"
            @click="toEditModal(rowData.item)"
          >
            <i
              class="ion ion-md-create"
              :style="{ color: '#04bec4' }"
            />
            {{ $t('common.button.edit') }}
          </b-btn>
        </Can>
        <Can
          :i="ActionsTypes.DELETE"
          :a="AbilitiesTypes.ISSUER_QUESTIONS"
        >
          <b-btn
            v-b-tooltip.hover="true"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.delete')"
            @click="openDeleteModal(rowData.item)"
          >
            <i
              class="ion ion-ios-trash"
              :style="{ color: '#04bec4' }"
            />
            {{ $t('common.button.delete') }}
          </b-btn>
        </Can>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-questions-modal
      ref="CpIssuerQuestions"
      :title-data="titleData"
      :question-data="questionData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';
import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpQuestionsModal from './issuer-question-modal.vue';
import CpDeleteModal from '~/components/common/table/deleteModal';
import { sectionLabels } from './options';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'IssuerQuestions',

  metaInfo: {
    title: 'IssuerQuestions',
  },

  components: {
    Can,
    CpTable,
    CpHeadTable,
    CpDeleteModal,
    CpQuestionsModal,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      questionData: {},
      titleData: {},
      deleteItem: null,
      fields: [
        { key: 'section', i18nKey: 'issuerQuestions.label.table.section', tdClass: 'align-middle' },
        { key: 'question', i18nKey: 'issuerQuestions.label.table.question', tdClass: 'align-middle' },
        { key: 'position', i18nKey: 'issuerQuestions.label.table.position', tdClass: 'align-middle' },
        { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
      ],
      urlParams: { issuerId: this.$route.params.idIssuer },
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  mounted() {
    this.trackEvent(TrackingTypes.ADVANCED_ISSUER_QUESTIONS_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },
  methods: {
    ...mapActions('issuerQuestion', [
      'create',
      'update',
      'delete',
    ]),

    getSection(section) {
      return this.$t(sectionLabels[section]);
    },

    showModal() {
      this.$refs.CpIssuerQuestions.$refs.issuerQuestion.show();
    },

    hideModal() {
      this.$refs.CpIssuerQuestions.$refs.issuerQuestion.hide();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    directItem(item, top) {
      const { position, id } = item;
      let updatePosition = position + 1;
      if (top) updatePosition = !position ? position : position - 1;

      this.update({
        id,
        issuerId: this.$route.params.idIssuer,
        data: {
          ...item,
          position: updatePosition,
        },
      }).then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        label: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.questionData = { section: 'GENERAL QUESTIONS' };
      this.showModal();
    },

    toEditModal(data) {
      this.titleData = {
        type: 'Edit',
        label: this.$t('common.button.edit'),
        icon: 'md-create',
      };

      this.questionData = Object.assign({}, data);
      this.showModal();
    },

    removeItem() {
      const { id } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges({ action, data }) {
      this[action]({
        issuerId: this.$route.params.idIssuer,
        data,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>
