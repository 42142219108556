<template>
  <div class="d-flex flex-column w-100 vh-100">
    <div class="d-flex header-block mb-4 justify-content-between flex-column">
      <div class="d-flex w-100 align-items-center justify-content-between mb-3">
        <div class="d-flex">
          <h4 class="font-weight-bold mb-0">
            {{ $t('brokerDealer.opportunitiesPage.title') }}
          </h4>
          <div class="d-lg-block text-big font-weight-light mx-1">
            |
          </div>
          <h4 class="font-weight-light">
            {{ $t('brokerDealer.opportunitiesPage.subtitle') }}
          </h4>
        </div>
        <b-button
          variant="light"
          class="btn btn-outline-primary btn-new-opportunity"
          @click="showCreateOpportunityModal"
        >
          <i class="ion ion-ios-add-circle-outline mr-2" />
          {{ $t('brokerDealer.opportunitiesPage.buttons.createNewOpportunity') }}
        </b-button>
      </div>
      <filters @search="onFilterChange" />
    </div>
    <b-spinner
      v-if="isLoading"
      class="loading"
      big
    />
    <draggable
      v-if="!isLoading"
      :list="opportunities"
      class="w-100"
      ghost-class="ghost"
      :move="checkSortable"
      @start="dragging = true"
      @end="dragging = false"
      @change="onOrderChange"
    >
      <transition-group
        class="opportunities w-100"
        type="transition"
        name="flip-list"
      >
        <opportunity-card
          v-for="opportunity in opportunities"
          :key="opportunity.id"
          :opportunity="opportunity"
          @opportunityDeleted="refreshList"
        />
      </transition-group>
    </draggable>
    <no-results
      v-if="!isLoading && !opportunities.length"
      :create-opportunity="showCreateOpportunityModal"
    />
    <modal-create-opportunity
      ref="createOpportunityModal"
      @opportunityCreated="refreshList"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('brokerDealer.opportunitiesPage.sortWarningModal.title')"
      ok-only
      @onOk="$refs.cpConfirmModal.hide()"
    >
      {{ $t('brokerDealer.opportunitiesPage.sortWarningModal.description') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import NoResults from '@/pages/broker-dealer/opportunities/components/no-results';
import OpportunityCard from '@/pages/broker-dealer/opportunities/components/opportunity-card';
import ModalCreateOpportunity from '@/pages/broker-dealer/opportunities/components/modal-create-opportunity';
import Filters from '@/pages/broker-dealer/opportunities/components/filters';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import CpMixPanel, { TrackingTypes } from '../../../mixins/mix-panel';

export default {
  name: 'BrokerDealerOpportunities',
  components: {
    ModalCreateOpportunity,
    OpportunityCard,
    NoResults,
    draggable,
    Filters,
    CpConfirmModal,
  },
  ...mapState('brokerDealerOpportunities', ['brokerDealerOpportunities']),
  mixins: [CpMixPanel],
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      opportunities: [],
      sortable: true,
      showSortDisabledWarningModal: false,
      activeFilters: {},
    };
  },
  async created() {
    const [opportunities] = await Promise.all([this.getAllBrokerDealerOpportunities(), this.getAllSystemIssuers()]);
    this.opportunities = [...opportunities];
    this.isLoading = false;
  },
  mounted() {
    this.trackEvent(TrackingTypes.MARKETS_OPPORTUNITIES_LAND);
  },
  methods: {
    ...mapActions('brokerDealerOpportunities', ['getAllBrokerDealerOpportunities', 'updateBrokerDealerOpportunitiesOrder']),
    ...mapActions({
      getAllSystemIssuers: 'issuerList/getAllSystemIssuers',
    }),
    showCreateOpportunityModal() {
      this.$refs.createOpportunityModal.show();
    },
    onFilterChange(filters) {
      this.activeFilters = filters;
      this.sortable = !this.hasFilters(filters);
      this.refreshList();
    },
    onOrderChange() {
      const opportunities = this.opportunities.map(({ id }, index) => ({ id, order: index }));
      this.updateBrokerDealerOpportunitiesOrder(opportunities);
    },
    hasFilters({ search, issuerIds, type }) {
      return !!search || !!(issuerIds && issuerIds.length) || !!(type && type.length);
    },
    checkSortable() {
      if (!this.sortable) this.$refs.cpConfirmModal.show();
      return this.sortable;
    },
    refreshList() {
      this.isLoading = true;
      this.getAllBrokerDealerOpportunities(this.activeFilters)
        .then((data) => {
          this.opportunities = [...data];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .opportunities, .featured-opportunities {
    display: flex;
    flex-wrap: wrap;
    margin: 2em -1em;
  }

  .ghost {
    opacity: 0.7;
    background: #c8ebfb;
  }

  .flip-list-move {
    transition: transform 0.75s;
  }
  .no-move {
    transition: transform 0s;
  }

  .loading {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% + 80px);
  }
</style>
