<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div class="card mb-5">
      <cp-card-head
        :card-name="$t('configurationFundraise.subTitle.general')"
        :edit-mode="editMode"
        :can-edit="$can(ActionsTypes.UPDATE, AbilitiesTypes.CONFIGURATION_FUNDARAISE)"
        @onSaveChanges="saveChanges"
        @onChangeModeProxy="onEditCancel"
      />
      <div class="card-body">
        <cp-input-container
          ref="cpInputContainer"
          v-model="formGroup"
        >
          <div class="row">
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.wireTransferDetailsBank') }}</h5>
              <div class="card border-light mt-3">
                <div
                  v-if="!editMode"
                  class="card-body"
                  v-html="formGroup.wireTransferInstructions"
                />
                <div v-show="editMode">
                  <cp-html-text-area
                    v-model="formGroup.wireTransferInstructions"
                    name="wireTransferInstructions"
                    validate="required"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.currencies') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div class="card-body curr-enum">
                  <span
                    v-for="currency in activeCurrenciesIdentity"
                    :key="currency"
                  >
                    {{ currency }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-show="editMode"
              class="col-md-12"
            >
              <cp-checkbox-group
                v-model="formGroup.supportedCurrencies"
                :options="getCurrenciesIdentity"
                validate="required"
                name="supportedCurrencies"
              />
            </div>
          </div>
          <div
            v-if="activeCurrenciesIdentity.includes('ETH')"
            class="row mt-4"
          >
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.ethXPub') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div
                  class="card-body"
                >
                  {{ formGroup.xpubs.ethXpub }}
                </div>
              </div>
              <cp-input
                v-if="editMode"
                v-model="formGroup.xpubs.ethXpub"
                validate="required"
                name="eth xpubs"
              />
            </div>
          </div>
          <div
            v-if="activeCurrenciesIdentity.indexOf('BTC')>=0"
            class="row mt-4"
          >
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.btcXPub') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div
                  class="card-body"
                >
                  {{ formGroup.xpubs.btcXpub }}
                </div>
              </div>
              <cp-input
                v-if="editMode"
                v-model="formGroup.xpubs.btcXpub"
                validate="required"
                name="btc xpubs"
              />
            </div>
          </div>

          <div

            class="row mt-4"
          >
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.depositOptions') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div class="card-body curr-enum">
                  <span
                    v-for="depositOption in investmentOptionsForDisplay"
                    :key="depositOption"
                  >
                    {{ depositOption }}
                  </span>
                </div>
              </div>
              <div v-show="editMode">
                <cp-checkbox-group
                  v-model="formGroup.depositOptions"
                  :options="formGroup.depositOptionsEnums"
                  name="depositOptions"
                  :onchange="validateDepositOption"
                  :validate="validateDepositOption"
                />
                <span
                  v-if="isDepositOptionsValid"
                  class="invalid-feedback d-block"
                >{{ $t('configurationFundraise.errorsMsg.depositOptions') }}
                </span>
                <span
                  v-if="isIHFDepositOptionsValid"
                  class="invalid-feedback d-block"
                >{{ $t('configurationFundraise.errorsMsg.ihfDepositOptions') }}
                </span>
                <span
                  v-if="isIRADepositOptionsValid"
                  class="invalid-feedback d-block"
                >{{ $t('configurationFundraise.errorsMsg.iraDepositOptions') }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="isValidCashAccountIdentifier"
            class="row mt-4"
          >
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.cashAccountIdentifier') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div class="card-body curr-enum">
                  {{ formGroup.cashAccountIdentifier }}
                </div>
              </div>
              <div v-show="editMode">
                <cp-input
                  v-if="editMode"
                  v-model="formGroup.cashAccountIdentifier"
                  :validate="isValidCashAccountIdentifier? 'required':null"
                  name="cashAccountIdentifier"
                />
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <h5>{{ $t('configurationFundraise.label.investmentRegulationType') }}</h5>
              <div
                v-if="!editMode"
                class="card border-light mt-3"
              >
                <div
                  class="card-body"
                  v-html="investmentRegulationsForDisplay"
                />
              </div>
              <div v-show="editMode">
                <cp-select
                  v-model="formGroup.investmentRegulationType"
                  name="investmentRegulationType"
                  :options="formGroup.investmentRegulations"
                />
              </div>
            </div>
          </div>
        </cp-input-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpInputContainer from '~/components/common/cpInputContainer';
import {
  CpInput,
  CpHtmlTextArea,
  CpCheckboxGroup, CpSelect,
} from '~/components/common/standalone-components/inputs';
import { i18n } from '@/i18n';
import CpCardHead from './card-head';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';


export default {
  name: 'FundraiseComponent',
  components: {
    CpCheckboxGroup,
    CpCardHead,
    CpInputContainer,
    CpInput,
    CpHtmlTextArea,
    CpSelect,
  },
  props: {
    general: {
      type: Object,
      default: (rawProps) => {
        if (rawProps && rawProps.investmentType) {
          delete rawProps.investmentType;
        }
        return rawProps || null;
      },
    },
    depositTypeErrorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formGroup: {
        depositOptions: [],
        depositOptionsEnums: [],
        supportedCurrencies: [],
        wireTransferInstructions: '',
        cashAccountIdentifier: '',
        xpubs: {
          btcXpub: '',
          ethXpub: '',
        },
      },
      investmentRegulationType: 'none',
      investmentRegulations: [],
      currenciesWithXpub: ['btc', 'eth'],
      editMode: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerCurrencies']),
    activeCurrenciesIdentity() {
      return this.getCurrenciesIdentity.filter(currency => this.isCurrencySelected(currency));
    },
    isValidCashAccountIdentifier() {
      return this.formGroup.depositOptions.includes('cash-account');
    },
    getCurrenciesIdentity() {
      return this.issuerCurrencies.map(currency => currency.identifier);
    },
    isDepositOptionsValid() {
      return (this.formGroup.depositOptions.length === 0);
    },
    isIRADepositOptionsValid() {
      return (this.formGroup.depositOptions.length === 1 && this.formGroup.depositOptions.includes('ira'));
    },
    isIHFDepositOptionsValid() {
      return (this.formGroup.supportedCurrencies.includes('IHF') && !this.formGroup.depositOptions.includes('web3'));
    },
    investmentOptionsForDisplay() {
      return this.formGroup.depositOptions.map(value => i18n.t(`configurationFundraise.values.${value}`));
    },
    investmentRegulationsForDisplay() {
      return i18n.t(`configurationFundraise.investmentRegulationTypes.${this.formGroup.investmentRegulationType}`);
    },
    validateDepositOption() {
      if (this.formGroup.depositOptions.length === 1 && this.formGroup.depositOptions.includes('usdc')) {
        return 'cannot_be_selected_without_another_deposit_method';
      }

      if (this.formGroup.supportedCurrencies.includes('IHF') && !this.formGroup.depositOptions.includes('web3')) {
        return 'web3_deposit_option_is_required';
      }
      return '';
    },
  },
  watch: {
    general: {
      immediate: true,
      handler(data) {
        this.formGroup = JSON.parse(JSON.stringify(data));
        this.formGroup.supportedCurrencies = this.formGroup.supportedCurrencies.filter(currency => this.getCurrenciesIdentity.includes(currency));
        this.formGroup.depositOptionsEnums = [
          { value: 'wire-transfer-crypto', text: i18n.t('configurationFundraise.values.wire-transfer-crypto') },
          { value: 'cash-account', text: i18n.t('configurationFundraise.values.cash-account') },
          { value: 'ira', text: i18n.t('configurationFundraise.values.ira') },
          { value: 'web3', text: i18n.t('configurationFundraise.values.web3') },
          { value: 'usdc', text: i18n.t('configurationFundraise.values.usdc') },
        ];
        this.formGroup.investmentRegulations = [
          { value: 'none', text: i18n.t('configurationFundraise.investmentRegulationTypes.none') },
          { value: 'reg-cf', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-cf') },
          { value: 'reg-d-506c', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-d-506c') },
          { value: 'reg-d-506b', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-d-506b') },
          { value: 'reg-a', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-a') },
          { value: 'reg-s', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-s') },
          { value: 'reg-d-506c-reg-s', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-d-506c-reg-s') },
          { value: 'reg-d-506b-reg-s', text: i18n.t('configurationFundraise.investmentRegulationTypes.reg-d-506b-reg-s') },
        ];
      },
    },
  },
  methods: {
    ...mapActions({
      updateFundraiseTokenConfiguration: 'fundraise/updateFundraiseTokenConfiguration',
    }),
    isCurrencySelected(currency) {
      return this.formGroup.supportedCurrencies.includes(currency);
    },
    resetInactiveXpub(currency) {
      if (!this.formGroup.supportedCurrencies.includes(currency.toUpperCase()) && this.formGroup.xpubs[`${currency.toLowerCase()}Xpub`]) {
        delete this.formGroup.xpubs[`${currency.toLowerCase()}Xpub`];
      }
    },
    saveChanges() {
      this.currenciesWithXpub.forEach(currency => this.resetInactiveXpub(currency));
      if ((this.formGroup.depositOptions.length === 0)
          || (this.formGroup.depositOptions.length === 1 && this.formGroup.depositOptions.includes('ira'))
          || (this.formGroup.supportedCurrencies.includes('IHF') && !this.formGroup.depositOptions.includes('web3'))
        ) {
          return;
        }
      this.$refs.cpInputContainer.validateForm().then(() => {
        if (this.$refs.cpInputContainer.isValid()) {
          const { idIssuer: issuerId, tokenId } = this.$route.params;
          this.updateFundraiseTokenConfiguration({
            issuerId,
            tokenId,
            body: this.formGroup,
          }).then(() => {
            this.editMode = false;
          });
        }
      });
    },
    onEditCancel() {
      if (this.$isLimitedAccess()) {
        return this.$showLimitAccessError();
      }
      this.editMode = !this.editMode;
      this.$emit('onCancel');
    },
  },
};
</script>
<style lang="scss" scoped>
.curr-enum {
  span {
    &:after {
      content: ',';
      position: relative;
      left: -3px;
    }

    &:last-child {
      &:after {
        content: ''
      }
    }
  }
}
</style>
