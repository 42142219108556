<template>
  <cp-table
    ref="cpTable"
    default-sort-by="totalTokens"
    default-sort-direction="desc"
    :checkbox="$can(ActionsTypes.UPDATE, AbilitiesTypes.HOLDERS_INVESTOR)"
    :mass-selectable="$can(ActionsTypes.UPDATE, AbilitiesTypes.HOLDERS_INVESTOR)"
    :filters="params"
    :get-data-action="getUrl"
    :url-params="urlParams"
    :fields="calculatedTableFields"
    :data-parser="listCorrection"
    :table-title="$t('holders.title')"
    :i18n-values="[mainCurrency]"
    @tableDataUpdated="onTableDataUpdated"
    @onSort="$emit(onSort, $event)"
    @onSelectAll="onSelectAll($event)"
    @onSelect="$emit('onSelectInvestor', $event)"
    @onMassSelect="onMassSelect"
  >
    <template
      v-if="showTableHeaderActions"
      slot="tableHeader"
    >
      <cp-batch-actions
        :disabled="isEmptyExportList"
        :can-export-list="$can(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_EXPORT_LIST)"
        :can-edit-labels="$can(ActionsTypes.UPDATE, AbilitiesTypes.HOLDERS_EDIT_LABELS)"
        :can-show-labels="$can(ActionsTypes.READ, AbilitiesTypes.HOLDERS_SHOW_LABELS)"
        @onExportList="$emit('onExportList')"
        @onEditLabels="$emit('onEditLabels')"
        @onShowLabels="$emit('onShowLabels')"
      />
    </template>
    <template
      slot="name"
      slot-scope="{ rowData }"
    >
      <a
        v-if="!isCustodianHolders(rowData.item)"
        href="javascript:void(0)"
        @click.prevent="$emit('onViewElem', rowData.item, $event)"
      >
        {{ getFullName(rowData.item) }}
      </a>
      <span v-else>
        {{ getFullName(rowData.item) }}
      </span>
    </template>

    <template
      slot="countryCode"
      slot-scope="{ rowData }"
    >
      <cp-country-name
        :country-code="rowData.item.countryCode"
        :locale="locale"
      />
    </template>

    <template
      slot="investorType"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.investorTypeValue }}
    </template>

    <template
      slot="totalTokens"
      slot-scope="{ rowData }"
    >
      <cp-ellipsis
        :data-value="formatToken(rowData.item.totalTokensToDisplay)"
        :tooltip-value="formatToken(rowData.item.totalTokensToDisplay)"
      />
    </template>

    <template
      slot="walletAddress"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.walletAddress }}
      <b-badge
        v-if="isWalletsCountShowing(rowData.item.walletsCount)"
        class="align-text-bottom ml-1"
        variant="outline-secondary"
      >
        {{ `+${rowData.item.walletsCount - 1}` }}
      </b-badge>
    </template>

    <template
      slot="walletRegistrationStatus"
      slot-scope="{ rowData }"
    >
      <b-badge
        v-if="rowData.item.walletRegistrationStatusValue"
        class="align-text-bottom ml-1"
        :variant="rowData.item.walletRegistrationStatusStyle"
      >
        {{ rowData.item.walletRegistrationStatusValue }}
      </b-badge>
    </template>

    <template
      slot="percentage"
      slot-scope="{ rowData }"
    >
      <div class="no-break">
        <cp-ellipsis
          class="no-break"
          :data-value="rowData.item.percentage"
        >
          %
        </cp-ellipsis>
      </div>
    </template>

    <template
      slot="labels"
      slot-scope="{ rowData }"
    >
      <div v-if="isLabels(rowData.item)">
        <span
          v-for="label in getLabelsList(rowData.item)"
          :key="label"
        >
          <b-badge
            v-if="label"
            class="align-text-bottom ml-1"
            variant="outline-secondary"
          >
            {{ label }}
          </b-badge>
        </span>
        <span
          v-if="rowData.item.labels.length > 3"
          v-b-tooltip.hover="true"
          :title="getLabelsTitle(rowData.item)"
          style="cursor: pointer"
        >
          + {{ rowData.item.labels.length - 3 }}
        </span>
      </div>
    </template>
    <template
      slot="custodianManagementActions"
      slot-scope="{ rowData }"
    >
      <div
        class="d-flex"
        :style="{ width: '56px' }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.HOLDERS_INVESTOR"
        >
          <cp-button
            v-if="!rowData.item.isAggregatedBrokerDealerGroup"
            variant="default btn-xs icon-btn md-btn-flat"
            @click="handleUpdateCustodian(rowData.item, $event)"
          >
            <i class="ion ion-md-create" />
          </cp-button>
        </Can>
        <Can
          :i="ActionsTypes.DELETE"
          :a="AbilitiesTypes.HOLDERS_DELETE_INVESTOR"
        >
          <cp-button
            disabled
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="$emit('onRemoveElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </Can>
      </div>
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <div
        class="d-flex"
        :style="{ width: '56px' }"
      >
        <cp-button
          v-if="
            !isCustodianHolders(rowData.item) &&
              !rowData.item.isAggregatedBrokerDealerGroup
          "
          variant="default btn-xs icon-btn md-btn-flat"
          @click="handleActionClick(rowData.item, $event)"
        >
          <i class="ion ion-ios-eye" />
        </cp-button>
        <Can
          :i="ActionsTypes.DELETE"
          :a="AbilitiesTypes.HOLDERS_DELETE_INVESTOR"
        >
          <cp-button
            v-if="
              !isCustodianHolders(rowData.item) &&
                !rowData.item.isAggregatedBrokerDealerGroup
            "
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="$emit('onRemoveElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </Can>
      </div>
    </template>
  </cp-table>
</template>

<script>
import { mapState } from 'vuex';
import { Can } from '@casl/vue';
import CpBatchActions from '~/pages/_idIssuer/investors/batch-actions';
import CpCountryName from '~/components/common/country-name';
import CpEllipsis from '~/components/common/ellipse';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import GeneralActionsOfInvestorTables from '~/mixins/general-actions-of-investor-tables';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import {
  registrationStatus,
  getTableColumns,
  investorTypeList,
} from './options';
import CpCurrencyFormatterLatest from '../../../../../mixins/currency-formatter-latest';

export default {
  name: 'CpHoldersTable',
  components: {
    Can,
    CpBatchActions,
    CpCountryName,
    CpEllipsis,
    CpTable,
    CpButton,
  },
  mixins: [GeneralActionsOfInvestorTables, CpCurrencyFormatterLatest],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    selectedTab: {
      type: String,
      default: '',
    },
    showTableHeaderActions: {
      type: Boolean,
      default: true,
    },
    tableGetUrl: {
      type: String,
      default: '',
      validator: value => ['custodians/getCustodians', 'investors/getInvestorsList'].indexOf(
          value,
        ) !== -1,
    },
  },
  data() {
    return {
      registrationStatus,
      locale: null,
      getUrl: this.tableGetUrl,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapState('issuersInfo', ['issuerInfo', 'currentToken']),
    isTokenRepresentsDebtValue() {
      return this.currentToken && this.currentToken.representDebt;
    },
    mainCurrency() {
      return this.issuerInfo && this.issuerInfo.mainCurrency;
    },
    calculatedTableFields() {
      const tableFields = getTableColumns(this.selectedTab);

      if (!this.isTokenRepresentsDebtValue) return tableFields;

      return tableFields.map((tableField) => {
        if (tableField.key === 'totalTokens') tableField.i18nKey = 'holders.label.table.principalAmount';
        return tableField;
      });
    },
    params() {
      return {
        view: 'holders',
        ...this.filters,
        tokenId: this.$route.params.tokenId,
        'holder-type-view': this.selectedTab || undefined,
      };
    },
    showInCustodianManagement() {
      return this.selectedTab === 'custodian-management';
    },
  },
  watch: {
    tableGetUrl(newTableGetUrl) {
      this.getUrl = newTableGetUrl;
    },
    selectedTab() {
      this.clearTableSelection();
      this.onMassSelect({ massSelect: false });
    },
  },
  created() {
    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });
  },
  methods: {
    isWalletsCountShowing(walletsCount) {
      return walletsCount > 1;
    },
    isCustodianHolders(item) {
      return item.investorType === 'custodian-wallet-manager';
    },
    onSelectAll(event) {
      this.$emit('onSelectAll', event);
    },
    onTableDataUpdated({ totalItems }) {
      this.checkEmptyTable(totalItems);
    },
    onMassSelect({ massSelect }) {
      this.$emit('onMassSelect', massSelect);
    },
    handleActionClick(item, event) {
      this.$emit('onViewElem', item, event);
    },
    handleUpdateCustodian(item, event) {
      this.$emit('onUpdateCustodian', item, event);
    },
    updatedTable() {
      this.$refs.cpTable.updateTableData();
    },
    listCorrection(data) {
      return data.map((item) => {
        let correctedItem = {
          ...item,
          investorTypeValue: this.getInvestorTypeToDisplay(item),
          walletRegistrationStatusStyle:
            this.getWalletRegistrationStatusStyle(item),
          walletRegistrationStatusValue:
            this.getWalletRegistrationStatusToDisplay(item),
        };

        if (item.brokerDealerGroupName) {
          correctedItem = {
            ...correctedItem,
            name: item.brokerDealerGroupName,
            investorTypeValue: 'N/A',
            walletAddress: 'N/A',
            walletRegistrationStatusValue: 'N/A',
            isAggregatedBrokerDealerGroup: true,
          };
        }

        return correctedItem;
      });
    },
    getInvestorTypeToDisplay(item) {
      return this.$t(investorTypeList[item.investorType]);
    },
    getWalletRegistrationStatusStyle(item) {
      return registrationStatus.styles[item.walletRegistrationStatus];
    },
    getWalletRegistrationStatusToDisplay(item) {
      return this.$t(registrationStatus.labels[item.walletRegistrationStatus]);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-break {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
