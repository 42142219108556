<template>
  <div>
    <div class="header-block mb-4">
      <h4 class="font-weight-bold mb-0">
        <i class="ion ion-ios-people" />
        {{ $t('investorsCommon.title') }}
        <span class="text-muted font-weight-normal"> | {{ title }} </span>
      </h4>
      <div>
        <div
          v-if="actionsType == 'custodian' && canCreateInvestor"
          class="d-flex"
        >
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('onAddCustodian')"
          >
            <i class="ion ion-ios-person-add" />
            {{ $t('investorsCommon.button.investor.addCustodian') }}
          </button>
        </div>
        <div
          v-if="actionsType === 'holders'"
          class="d-flex"
        >
          <cp-import-investors
            v-if="showImportInvestors"
            @handleImportInvestors="$emit('handleImportInvestors')"
          />
          <cp-import-issuance v-if="showImportIssuance" />
          <div v-if="canCreateInvestor">
            <b-dropdown
              v-if="isOmnibusAddable"
              variant="primary"
            >
              <template v-slot:button-content>
                <i class="ion ion-ios-person-add" />
                {{ $t('investorsCommon.button.investor.dropdown') }}
              </template>
              <b-dropdown-item @click="showNewInvestorModal">
                {{ $t('investorsCommon.button.investor.add') }}
              </b-dropdown-item>
              <b-dropdown-item @click="showNewOmnibusWalletOwnerModal">
                {{ $t('investorsCommon.button.investor.omnibus') }}
              </b-dropdown-item>
            </b-dropdown>
            <cp-button
              v-else
              size
              @click="showNewInvestorModal"
            >
              <i class="ion ion-ios-person-add" />
              {{ $t('investorsCommon.button.investor.add') }}
            </cp-button>
          </div>
        </div>
      </div>
    </div>
    <cp-create-investor-modal
      ref="cpAddInvestorModal"
      :options="countryCodes"
    />
    <cp-create-omnibus-wallet-owner
      ref="cpAddOmnibusWalletOwnerModal"
      :options="countryCodes"
    />
  </div>
</template>

<script>
import CpCreateInvestorModal from '~/pages/_idIssuer/investors/modals/create-investor-modal';
import CpCreateOmnibusWalletOwner from '~/pages/_idIssuer/investors/omnibus/modals/create-omnibus-wallet-owner-modal';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../utilities/ability/abilities';
import CpImportIssuance from './import-issuance';
import CpImportInvestors from './import-investors';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpTableHead',
  components: {
    CpCreateInvestorModal,
    CpCreateOmnibusWalletOwner,
    CpImportInvestors,
    CpImportIssuance,
    CpButton,
  },
  props: {
    countryCodes: {
      type: Array,
      default: () => [],
    },
    actionsType: {
      type: String,
      default: 'holders',
      validator: value => ['holders', 'custodian'].indexOf(value) !== -1,
    },
    title: {
      type: String,
      required: true,
    },
    isOmnibusAddable: {
      type: Boolean,
      default: false,
    },
    showImportInvestors: {
      type: Boolean,
      default: true,
    },
    showImportIssuance: {
      type: Boolean,
      default: false,
    },
    canCreateInvestor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tokenId: this.$route.params.tokenId || '',
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  methods: {
    showNewInvestorModal() {
      this.$refs.cpAddInvestorModal.show();
    },
    showNewOmnibusWalletOwnerModal() {
      this.$refs.cpAddOmnibusWalletOwnerModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
.header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }
}
</style>
