<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToList"
        >
          <i class="ion ion-ios-arrow-back" />
          Back to List
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted" />
      </div>
    </div>
    <div class="row connected-profiles">
      <div class="col-md-12 d-flex align-items-center">
        Connected Profiles:
        <div
          class="ml-3"
          style="max-width: 150px"
        >
          <cp-image
            class="securitize-markets-logo"
            :src="getImagePath('sec-markets-logo.svg', 'logo')"
            :default-src="getImagePath('logo.png', 'logo')"
            @click="goToSecuritizeIdMarkets"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-end mb-2 row-overwrite justify-content-between">
      <div class="py-3">
        <h4 class="font-weight-bold">
          {{ securitizeIdInvestor.fullName }}
        </h4>
        <div class="d-flex align-items-center">
          <span class="text-muted">
            <a :href="`mailto:${securitizeIdInvestor.email}`">
              {{ securitizeIdInvestor.email }}
            </a> | External ID: {{ securitizeIdInvestor.investorId || '-' }}
          </span>
        </div>
      </div>
      <div class="align-self-center">
        <b-dropdown
          right
          no-caret
          variant="outline-primary"
        >
          <template v-slot:button-content>
            <i class="ion ion-ios-more mr-2" />
            More options
          </template>
          <b-dropdown-item
            v-if="!isEntityInvestor"
            :disabled="!canResetPassword"
            @click="doResetPassword"
          >
            <i class="ion ion-ios-refresh mr-2" />
            Reset Password
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isEntityInvestor"
            :disabled="!is2FaEnabled"
            @click="openDisable2FaModal"
          >
            <i class="ion ion-ios-remove-circle mr-2" />
            Disable 2FA
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!!securitizeIdInvestor.mixPanelId"
            :href="`${mixpanelProfileUrl}#distinct_id=${securitizeIdInvestor.mixPanelId}`"
            target="_blank"
          >
            <i class="ion ion-ios-stats mr-2" />
            Analytics
          </b-dropdown-item>
          <b-dropdown-item
            :href="`/api/v2/audit-trails/document/?investorId=${securitizeIdInvestor.investorId}`"
            target="_blank"
          >
            <i class="ion ion-ios-download mr-2" />
            Download Audit Trail
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            @click="verifyDeleteInvestor"
          >
            <i class="ion ion-ios-trash mr-2 text-danger" />
            <span class="text-danger">
              {{ $t('investorDetails.button.delete') }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <cp-disable2fa-modal
      ref="CpDisable2fa"
      @confirm="handleDisable2FA"
    />
    <securitize-id-general-information />
    <securitize-id-legal-signers-table
      v-if="isEntityInvestor"
      :is-update-table="updateTable"
      @addLegalSignerModel="addLegalSignerModal"
      @onViewElem="navigateToEditLegalSigner"
    />
    <legal-signer-model
      ref="LegalSignerModal"
      @updateTable="doUpdateTable"
    />
    <securitize-id-authorized-accounts-table
      v-if="securitizeIdInvestor.investorType === 'entity'"
      :is-update-table="updateAuthorizedAccountTable"
      @addAuthorizedAccountModel="addAuthorizedAccountModel"
      @onRemoveAuthorizedAccount="verifyDeleteAuthorizedAccount"
      @onTransferAccountOwnership="confirmTransferAccountOwnership"
      @onResendAuthorizedAccountInvite="resendAuthorizedAccountInvite"
      @onChangeAuthorizedAccoutnRole="onChangeAuthorizedAccoutnRole"
    />
    <authorized-account-model
      ref="AuthorizedAccountModel"
      :authorized-investors="authorizedInvestors"
      :investor-email="securitizeIdInvestor.email"
      @updateAuthorizedAccountTable="doUpdateAuthorizedAccountTable"
    />
    <cp-securitize-id-investor-details-kyc @documentsUpdated="onDocumentsUpdated" />
    <securitize-id-investor-documents
      :categories="documentCategories"
      :refresh-doc-table="refreshDocTable"
    />
    <securitize-id-investor-documents
      :categories="documentCategories"
      :is-confidential="true"
    />
    <securitize-id-wallets-table />
    <cp-confirm-modal
      ref="cpConfirmDeleteModal"
      :title="$t('investorDetails.removeModal.title', [securitizeIdInvestor.fullName])"
      ok-text="Delete"
      @onOk="runDeleteInvestorRequest"
    >
      {{ $t('investorDetails.removeModal.message', [$t('investor')]) }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="cpCantDeleteModal"
      :title="`Delete ${securitizeIdInvestor.fullName || securitizeIdInvestor.name}`"
      :ok-only="true"
    >
      {{
        `This investor is associated with ${securitizeIdInvestor.issuersData.map(issuer => issuer.issuerName).join(', ')}.
        If you wish to delete investors you must first unlink them from issuers.`
      }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="authorizeAccountConfirmDeleteModal"
      title="Remove account"
      ok-text="Remove"
      @onOk="removeAuthorizedAccount"
    >
      Are you sure you want to remove {{ authorizedAccountToRemove }} from being able to perform actions on this account?
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="transferAccountOwnershipConfirmModal"
      title="Transfer ownership"
      ok-text="Transfer Ownership"
      cancel-text="Cancel"
      @onOk="transferAccountOwnership"
    >
      {{ `Are you sure you want to transfer ownership of this account to ${authorizedAccountNewOwnerInfo.newOwnerName}?` }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpDisable2faModal from '~/pages/_idIssuer/investors/details/components/disable-2fa-modal';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import routeNames from '~/utilities/routes';
import SecuritizeIdWalletsTable from '@/pages/securitize-id/components/details/investors/wallets-table';
import CpSecuritizeIdInvestorDetailsKyc from './components/sec-id-kyc';
import SecuritizeIdLegalSignersTable from './components/details/legal-signer/legal-signers-table';
import SecuritizeIdAuthorizedAccountsTable from './components/details/authorized-accounts/authorized-accounts-table';
import SecuritizeIdGeneralInformation from './components/details/general';
import SecuritizeIdInvestorDocuments from './components/details/documents';
import LegalSignerModel from './components/details/legal-signer/modal-create-legal-signer';
import AuthorizedAccountModel from './components/details/authorized-accounts/modal-add-authorized-account';
import CpImage from '../../components/common/standalone-components/cp-image.vue';
import CpStaticPathGenerationMixin from '../../mixins/static-path-generation';

export default {
  name: 'SecuritizeIdInvestor',
  metaInfo: {
    title: 'Investor Details',
  },
  components: {
    SecuritizeIdWalletsTable,
    CpDisable2faModal,
    SecuritizeIdLegalSignersTable,
    SecuritizeIdAuthorizedAccountsTable,
    CpSecuritizeIdInvestorDetailsKyc,
    CpConfirmModal,
    SecuritizeIdGeneralInformation,
    LegalSignerModel,
    AuthorizedAccountModel,
    SecuritizeIdInvestorDocuments,
    CpImage,
  },
  mixins: [
  CpStaticPathGenerationMixin,
  ],
  props: {
    linkToList: {
      type: String,
      default: '/securitize-id', // replace with "required: true" when list-page is ready
    },
  },
  data() {
    return {
      updateTable: false,
      updateAuthorizedAccountTable: false,
      investorId: this.$route.params.investorId,
      isReady: false,
      investorsListQueryParams: {},
      mixpanelProfileUrl: process.env.VUE_APP_MIXPANEL_PROFILE_PATH,
      authorizedInvestors: [],
      authorizedAccountToRemove: {},
      authorizedAccountNewOwnerInfo: {},
      refreshDocTable: false,
      authorizedByAccounts: [],
      documentCategories: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === routeNames.securitizeId()) {
        vm.investorsListQueryParams = from.query;
      }
    });
  },
  computed: {
    ...mapGetters('global', ['currencies']),
    ...mapState('securitizeId', ['securitizeIdInvestor']),
    is2FaEnabled() {
      return this.securitizeIdInvestor.tfaEnabled;
    },
    getInvestorTitle() {
      return this.securitizeIdInvestor.fullName;
    },
    isEntityInvestor() {
      return this.securitizeIdInvestor.investorType === 'entity';
    },
    canResetPassword() {
      if (this.isEntityInvestor) return false;
      if (!this.securitizeIdInvestor.isHidden) return true;

      return !!this.authorizedByAccounts?.length;
    },
  },
  created() {
    Promise.all([
      this.getSecuritizeIdInvestorByInvestorId({ investorId: this.investorId }),
      this.getDocumentCategories(),
    ])
    .then(([, documentCategories]) => {
        this.documentCategories = documentCategories;
        this.isReady = true;
    });

    this.getSecuritizeIdAuthorizedByAccounts({ investorId: this.investorId })
      .then(({ data }) => {
        this.authorizedByAccounts = data.filter(authAcc => authAcc.status === 'approved');
      });
  },
  methods: {
    ...mapActions('securitizeId', [
      'removeSecuritizeIdInvestor2fa', 'getSecuritizeIdInvestorByInvestorId', 'securitizeIdInvestorResetPassword',
      'deleteSecuritizeIdInvestor', 'resendSecuritizeIdAuthorizedAccountInvite', 'removeSecuritizeIdAuthorizedAccount',
      'sendSecuritizeIdAuthorizedAccountRequest', 'changeAuthorizedAccoutnRole', 'transferOwnershipOfSecuritizeIdAuthorizedAccount',
      'getSecuritizeIdAuthorizedByAccounts', 'getDocumentCategories',
    ]),
    onDocumentsUpdated() {
      this.refreshDocTable = !this.refreshDocTable;
    },
    addLegalSignerModal() {
      this.updateTable = false;
      this.$refs.LegalSignerModal.show();
    },
    addAuthorizedAccountModel(authorizedInvestors) {
      this.authorizedInvestors = authorizedInvestors;
      this.updateAuthorizedAccountTable = false;
      this.$refs.AuthorizedAccountModel.show();
    },
    doUpdateTable() {
      this.updateTable = true;
    },
    doUpdateAuthorizedAccountTable() {
      this.updateAuthorizedAccountTable = true;
    },
    doResetPassword() {
      this.securitizeIdInvestorResetPassword({ investorId: this.investorId });
    },
    sendInviteModal() {
      this.$refs.CpSendInviteEmail.show();
    },
    goToList() {
      this.$router.push({
        path: routeNames.securitizeId(),
        query: { ...this.investorsListQueryParams },
      });
    },
    openDisable2FaModal() {
      this.$refs.CpDisable2fa.show();
    },
    goToSecuritizeIdMarkets() {
      window.open(routeNames.securitizeIdMarketsInvestor(this.investorId), '_blank');
    },
    handleDisable2FA() {
      return this.removeSecuritizeIdInvestor2fa({ investorId: this.investorId });
    },
    verifyDeleteInvestor() {
      const isInvestorAssociatedToIssuers = !!this.securitizeIdInvestor.issuersData.length;
      if (isInvestorAssociatedToIssuers) {
        this.$refs.cpCantDeleteModal.show();
      } else {
        this.$refs.cpConfirmDeleteModal.show();
      }
    },
    runDeleteInvestorRequest() {
      this.deleteSecuritizeIdInvestor({ investorId: this.investorId });
      this.goToList();
    },
    navigateToEditLegalSigner({ signerId: legalSignerId }) {
      if (legalSignerId && this.investorId) {
        this.$router.push(routeNames.securitizeIdLegalSigner({ legalSignerId, investorId: this.investorId }));
      }
    },
    resendAuthorizedAccountInvite(email, role) {
      this.sendSecuritizeIdAuthorizedAccountRequest({
        email,
        role,
        investorId: this.$route.params.investorId,
      })
      .then(() => {
        this.$toast.open({
          message: 'Email sent',
          duration: 5000,
        });
      });
    },
    onChangeAuthorizedAccoutnRole({ investorId, email, inviteeUserId, newRole }) {
      this.updateAuthorizedAccountTable = false;
      this.changeAuthorizedAccoutnRole({ investorId, email, inviteeUserId, newRole })
        .then(() => {
          this.updateAuthorizedAccountTable = true;
          this.$toast.open({
            message: `Role was changed to ${newRole}`,
            duration: 5000,
          });
        })
        .finally(() => { this.updateAuthorizedAccountTable = false; });
    },
    verifyDeleteAuthorizedAccount(email) {
      this.authorizedAccountToRemove = email;
      this.$refs.authorizeAccountConfirmDeleteModal.show();
    },
    confirmTransferAccountOwnership(newOwnerInfo) {
      this.authorizedAccountNewOwnerInfo = { ...newOwnerInfo, investorId: this.$route.params.investorId };
      this.$refs.transferAccountOwnershipConfirmModal.show();
    },
    removeAuthorizedAccount() {
      this.updateAuthorizedAccountTable = false;
      this.removeSecuritizeIdAuthorizedAccount({
        email: this.authorizedAccountToRemove,
        investorId: this.$route.params.investorId,
      })
      .then(() => {
        this.updateAuthorizedAccountTable = true;
        this.$toast.open({
          message: `${this.authorizedAccountToRemove} was removed successfully`,
          duration: 5000,
        });
      })
      .finally(() => {
        this.updateAuthorizedAccountTable = false;
      });
    },
    transferAccountOwnership() {
      this.updateAuthorizedAccountTable = false;
      this.transferOwnershipOfSecuritizeIdAuthorizedAccount(this.authorizedAccountNewOwnerInfo)
        .then((res) => {
          const isSuccessful = res?.status === 201;
          if (isSuccessful) {
            this.updateAuthorizedAccountTable = true;
            this.$toast.open({
              message: `Ownership successfully transferred. ${this.authorizedAccountNewOwnerInfo.newOwnerName} is now the owner of this account.`,
              duration: 5000,
            });
          }
        })
        .finally(() => {
          this.updateAuthorizedAccountTable = false;
        });
    },
  },

};
</script>

<style src="~/vendor/styles/pages/users.scss" lang="scss"></style>
<style scoped lang="scss">
  .ion-ios-arrow-back {
    padding-right: 5px;
  }

  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0;

    h4 {
      margin: 0;
    }
  }

  .row.row-overwrite {
    margin-right: 0;
    margin-left: 0;
  }

  .primary {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  }

  .connected-profiles{
    .securitize-markets-logo{
      cursor: pointer;
    }
  }
</style>
