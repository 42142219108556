<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h4 class="mb-0 text-primary">
          {{ cardName }}
        </h4>
      </div>
      <cp-edit-buttons
        v-if="!withoutEdit && canEdit"
        :edit-mode="editMode"
        :disable-edit="disableEdit"
        @changeMode="$emit('onChangeModeProxy')"
        @update="$emit('onSaveChanges')"
      />
      <div
        v-if="createElem && canCreate"
        class="col-md-6 d-flex justify-content-end"
      >
        <b-button
          variant="info"
          class="btn btn-outline-primary btn-sm"
          @click="$emit('onCreateElem')"
        >
          <span
            v-if="buttonIcon"
            :class="['ion', `ion-${buttonIcon}`]"
          />
          <span class="d-none d-sm-inline-block">{{ buttonTitle }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CpEditButtons from '~/components/common/edit-buttons';

export default {
  name: 'CardHead',
  components: {
    CpEditButtons,
  },
  props: {
    cardName: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    withoutEdit: {
      type: Boolean,
      default: false,
    },
    createElem: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: '',
    },
  },
};
</script>
