<template>
  <div>
    <div class="card mb-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('configurationGeneral.landingPage.title') }}
            </h4>
          </div>
          <Can
            :i="ActionsTypes.UPDATE"
            :a="AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL"
          >
            <cp-edit-buttons
              :edit-mode="editMode"
              @changeMode="changeModeProxy"
              @update="save"
            />
          </Can>
        </div>
      </div>
      <hr class="my-0 mx-0">
      <div class="card-body">
        <cp-error-notices
          :errors="errors"
        />
        <div class="row">
          <div class="col-6">
            <cp-switcher-button
              :edit-mode="$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
              :title="$t('configurationGeneral.landingPage.button.basicLandingPage')"
              :open-status="!isLandingBasicType"
              @click="changeLandingType('basic')"
            />
          </div>
          <div class="col-6">
            <cp-switcher-button
              :edit-mode="$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
              :title="$t('configurationGeneral.landingPage.button.externalLandingPage')"
              :open-status="isLandingBasicType"
              @click="changeLandingType('external')"
            />
          </div>
        </div>
        <div
          v-if="!isLandingBasicType"
          class="card border-light mt-3"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <i18n
                  path="configurationGeneral.landingPage.message.externalLandingPageSection"
                  tag="p"
                >
                  <a
                    href="https://developers.securitize.io"
                    target="_blank"
                  >
                    developers.securitize.io
                  </a>
                <!-- </p> -->
                </i18n>
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">
                      {{ $t('configurationGeneral.landingPage.input.platformUrl.text') }}
                    </label>
                    <div>
                      <cp-editable-input
                        is-url
                        :value="landingPageConfig.external.platformURL"
                        :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                        :text="landingPageConfig.external.platformURL"
                        :placeholder="$t('configurationGeneral.landingPage.input.platformUrl.placeholder')"
                        @input="changeModelByType('external', 'platformURL', $event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="form-label">
                  {{ $t('configurationGeneral.landingPage.input.externalLandingUrl.text') }}
                </label>
                <div>
                  <cp-editable-input
                    is-url
                    :value="landingPageConfig.external.externalURL"
                    :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                    :text="landingPageConfig.external.externalURL"
                    :placeholder="$t('configurationGeneral.landingPage.input.externalLandingUrl.placeholder')"
                    @input="changeModelByType('external', 'externalURL', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="isLandingBasicType"
          class="card border-light mt-3"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <i18n
                  path="configurationGeneral.landingPage.message.basicLandingPageSection"
                  tag="div"
                >
                  <a
                    :href="landingPageConfig.url"
                    target="_blank"
                  >
                    <strong>{{ landingPageConfig.url }}</strong>
                  </a>.
                </i18n>
              </div>
            </div>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-striped table-borderless mb-0 mt-3">
                  <tbody>
                    <tr>
                      <td>{{ $t('configurationGeneral.landingPage.input.enablePage') }}</td>
                      <td>
                        <label
                          v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          class="switcher"
                        >
                          <input
                            :checked="landingPageConfig.basic.page"
                            type="checkbox"
                            class="switcher-input"
                            @change="changeModelByType('basic', 'page', !landingPageConfig.basic.page)"
                          >
                          <span class="switcher-indicator">
                            <span class="switcher-yes" />
                            <span class="switcher-no" />
                          </span>
                        </label>
                        <span
                          v-else
                          :class="getStatusIcon(landingPageConfig.basic.page)"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('configurationGeneral.landingPage.input.mode') }}</td>
                      <td>
                        <cp-btn-group
                          :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          :group="types"
                          :active-btn="landingPageConfig.basic.pageMode"
                          @click="changeModelByType('basic', 'pageMode', $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-striped table-borderless mb-0 mt-3 landing-settings-table">
                  <tbody>
                    <tr>
                      <td>{{ $t('configurationGeneral.landingPage.input.enableLimitedAccess') }}</td>
                      <td>
                        <label
                          v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          class="switcher"
                        >
                          <input
                            :checked="landingPageConfig.basic.limitedAccess.isLimitedAccess"
                            type="checkbox"
                            class="switcher-input"
                            @click.stop="($event)=>openLimitModal($event,'isLimitedAccess')"
                            @change="changeLimitedAccess(
                              'isLimitedAccess',
                              !landingPageConfig.basic.limitedAccess.isLimitedAccess
                            )"
                          >
                          <span class="switcher-indicator">
                            <span class="switcher-yes" />
                            <span class="switcher-no" />
                          </span>
                        </label>
                        <span
                          v-else
                          :class="getStatusIcon(landingPageConfig.basic.limitedAccess.isLimitedAccess)"
                        />
                      </td>
                    </tr>
                    <tr v-if="landingPageConfig.basic.limitedAccess.isLimitedAccess">
                      <td class="align-middle">
                        {{ $t('configurationGeneral.landingPage.input.landingPageUser') }}
                      </td>
                      <td>
                        {{ landingPageConfig.basic.limitedAccess.username }}
                      </td>
                    </tr>
                    <tr v-if="landingPageConfig.basic.limitedAccess.isLimitedAccess">
                      <td class="align-middle">
                        {{ $t('configurationGeneral.landingPage.input.landingPagePassword') }}
                      </td>
                      <td>
                        <cp-editable-input
                          :value="landingPageConfig.basic.limitedAccess.password"
                          :edit-mode="editMode"
                          :text="landingPageConfig.basic.limitedAccess.password"
                          strong
                          @input="changeLimitedAccess('password', $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 m-0">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div class="restrict-login-header">
                          <h5 class="mb-0 mr-3">
                            {{ $t('configurationGeneral.landingPage.input.restrictLogin') }}
                          </h5>
                          <label
                            v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                            class="switcher"
                          >
                            <input
                              :checked="restrictLoginEnabled"
                              type="checkbox"
                              class="switcher-input"
                              name="restrictLogin"
                              @change="enableRestrictLogin"
                            >
                            <span class="switcher-indicator">
                              <span class="switcher-yes" />
                              <span class="switcher-no" />
                            </span>
                          </label>
                          <span
                            v-else
                            :class="getStatusIcon(restrictLoginEnabled)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-striped table-borderless mb-0 mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <b-form-radio
                          v-model="restrictLogin"
                          :disabled="!editMode || !restrictLoginEnabled || !$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          plain
                          :checked="isSelectedRestrictLogin"
                          :value="'allow-holders'"
                        >
                          <span class="radio-label">{{ $t('configurationGeneral.landingPage.input.radio.allowHolders') }}</span>
                        </b-form-radio>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-striped table-borderless mb-0 mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <b-form-radio
                          v-model="restrictLogin"
                          :disabled="!editMode || !restrictLoginEnabled || !$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          plain
                          :checked="isSelectedRestrictLogin"
                          :value="'require-label'"
                        >
                          <span class="radio-label">{{ $t('configurationGeneral.landingPage.input.radio.requireLabel') }}</span>
                        </b-form-radio>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body">
            <div class="row">
              <div
                v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                class="col-md-6"
              >
                <h5>{{ $t('configurationGeneral.landingPage.input.backgroundImage') }}</h5>
                <cp-file-uploader
                  id="background-uploader"
                  @onChange="onBackgroundUploaded"
                />
                <table class="table table-striped table-borderless mb-0 mt-3">
                  <tbody>
                    <tr>
                      <td>{{ $t('configurationGeneral.landingPage.label.size') }}</td>
                      <td><strong>{{ $t('configurationGeneral.landingPage.label.sizeValue') }}</strong></td>
                    </tr>
                    <tr>
                      <td>{{ $t('configurationGeneral.landingPage.label.format') }}</td>
                      <td><strong>{{ $t('configurationGeneral.landingPage.label.formatValue') }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-else
                class="col-md-6"
              >
                <h5>{{ $t('configurationGeneral.landingPage.input.backgroundImage') }}</h5>
                <div
                  class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview"
                >
                  <img
                    v-b-modal.lp-bg
                    :class="`d-inline-block ${ landingPageConfig.basic.backgroundImage ? 'link': '' }`"
                    :src="backgroundImage"
                    alt="Background image"
                  >
                </div>
                <b-modal
                  v-if="landingPageConfig.basic.backgroundImage"
                  id="lp-bg"
                  size="lg"
                  modal-class="cp-preview-modal"
                  title="Landing Page - Background"
                  hide-footer
                >
                  <img
                    class="d-inline-block"
                    :src="backgroundImage"
                    alt="Landing Page - Background"
                  >
                </b-modal>
              </div>
              <div class="col-md-6">
                <h5>{{ $t('configurationGeneral.landingPage.input.headline') }}</h5>
                <div class="card border-light mt-3">
                  <div v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)">
                    <b-input
                      v-if="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                      :value="landingPageConfig.basic.headline"
                      @input="changeModelByType('basic', 'headline', $event)"
                    />
                  </div>
                  <div
                    v-else
                    class="card-body"
                  >
                    {{ landingPageConfig.basic.headline }}
                  </div>
                </div>
                <h5 class="mt-3">
                  {{ $t('configurationGeneral.landingPage.input.text') }}
                </h5>
                <div class="card border-light mt-3">
                  <div v-show="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)">
                    <cp-html-text-area
                      v-model="landingPageConfig.basic.text"
                      name="text"
                      @input="changeModelByType('basic', 'text', $event)"
                    />
                  </div>
                  <div
                    v-if="!editMode"
                    class="card-body"
                  >
                    {{ landingPageConfig.basic.text }}
                  </div>
                </div>
                <table class="table table-striped table-borderless mb-0 mt-3">
                  <tbody>
                    <tr v-if="['login-only','login-n-registration',].includes(landingPageConfig.basic.pageMode)">
                      <td>{{ $t('configurationGeneral.landingPage.input.loginButtonText') }}</td>
                      <td>
                        <cp-editable-input
                          :value="landingPageConfig.basic.LoginButtonText"
                          :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          :text="landingPageConfig.basic.LoginButtonText"
                          strong
                          @input="changeModelByType('basic', 'loginButtonText', $event)"
                        />
                      </td>
                    </tr>
                    <tr v-if="landingPageConfig.basic.pageMode === 'login-n-registration'">
                      <td>{{ $t('configurationGeneral.landingPage.input.registrationButtonText') }}</td>
                      <td>
                        <cp-editable-input
                          :value="landingPageConfig.basic.registrationButtonText"
                          :edit-mode="editMode && $can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_CONFIGURATION_GENERAL)"
                          :text="landingPageConfig.basic.registrationButtonText"
                          strong
                          @input="changeModelByType('basic', 'registrationButtonText', $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import CpEditButtons from '~/components/common/edit-buttons';
import CpEditableInput from '~/components/common/editable-input';
import CpBtnGroup from '~/components/common/btn-group';
import CpErrorNotices from '~/components/common/error-notices';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpSwitcherButton from '~/components/common/switcher-button';
import CpComponentToggleMixin from '~/mixins/component-toggle';
import { CpHtmlTextArea } from '~/components/common/standalone-components/inputs';

export default {
  name: 'CpConfigurationLandingPage',
  components: {
    Can,
    CpEditButtons,
    CpSwitcherButton,
    CpEditableInput,
    CpBtnGroup,
    CpErrorNotices,
    CpFileUploader,
    CpHtmlTextArea,
  },
  mixins: [
    staticPathGenerationMixin,
    CpComponentToggleMixin,
  ],
  props: {
    landingInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      landingInfoModelKeep: {},
      landingInfoModel: {
        basicMode: true,
        page: false,
        mode: '',
      },
      errors: [],
      restrictLogin: 'disabled',
      restrictLoginEnabled: false,
      isSelectedRestrictLogin: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('configuration', ['getLandingPage', 'getGeneral']),
    types() {
      return {
        'coming-soon': this.$t('configurationGeneral.landingPage.option.mode.comingSoon'),
        'login-only': this.$t('configurationGeneral.landingPage.option.mode.loginOnly'),
        'login-n-registration': this.$t('configurationGeneral.landingPage.option.mode.loginRegistration'),
      };
    },
    isLandingBasicType() {
      return this.landingPageConfig.type === 'basic';
    },
    landingPageConfig() {
      const lpConfig = JSON.parse(JSON.stringify(this.getLandingPage));
      if (lpConfig.basic.limitedAccess.password === null) {
        const randomPass = (
          1000000000000 + Math.floor(Math.random() * Math.floor(9000000000000))
        ).toString(16).toUpperCase();
        lpConfig.basic.limitedAccess.password = randomPass;
        this.changeLimitedAccess('password', randomPass);
      }
      this.updateRestrictLogin(lpConfig.basic.restrictLogin);
      return lpConfig; // immutability is hard
    },
    backgroundImage() {
      const url = this.landingPageConfig.basic.backgroundImage || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
  },
  watch: {
    restrictLogin: {
      handler(value) {
        this.changeRestrictLogin(value);
      },
    },
  },
  mounted() {
    this.landingInfoModel = { ...this.landingInfo };
    this.initialData(this.getLandingPage);
  },
  methods: {
    ...mapActions('configuration', ['updateLandingPageConfiguration']),
    onBackgroundUploaded({ fileKey }) {
      this.$store.commit('configuration/SET_LANDING_PAGE_BG_KEY', fileKey);
    },
    changeModeProxy() {
      if (this.editMode) {
        // on cancel clear the image IDs to prevent accidental upload
        this.clearTouched();
        this.cancelSelf();
      } else {
        this.storeLpState();
        this.isActivated();
      }
    },
    cancelIfActive() {
      if (this.editMode) {
        this.clearTouched();
        this.cancelSelf();
      }
    },
    getOwnData() {
      return this.landingPageConfig;
    },
    clearTouched() {
      this.restoreLpState();
    },
    storeLpState() {
      this.landingInfoModelKeep = this.landingPageConfig;
    },
    restoreLpState() {
      this.$store.commit('configuration/SET_LANDING_PAGE', this.landingInfoModelKeep);
    },
    changeLandingType(type) {
      if (this.$isLimitedAccess() && type === 'external') {
        return this.$showLimitAccessError();
      }
      if (!this.editMode) {
        this.storeLpState();
        this.isActivated();
      }
      this.$store.commit('configuration/CHANGE_LANDING_TYPE', type);
    },
    getStatusIcon(status) {
      return status
        ? 'ion ion-md-checkmark text-success'
        : 'ion ion-md-close text-danger';
    },
    changeModelByType(type, field, value) {
      this.$store.commit('configuration/CHANGE_LANDING_TYPES_CONFIG', { type, field, value });
    },
    openLimitModal(e, field) {
      if (this.$isLimitedAccess() && field === 'isLimitedAccess') {
        e.preventDefault();
        return this.$showLimitAccessError();
      }
    },
    changeLimitedAccess(field, value) {
      this.$store.commit('configuration/CHANGE_LANDING_LIMITED_ACCESS', ({ field, value }));
    },
    changeRestrictLogin(value) {
      this.$store.commit('configuration/CHANGE_LANDING_RESTRICT_LOGIN', (value));
    },
    updateRestrictLogin(restrictLogin) {
      this.restrictLogin = restrictLogin;
      this.restrictLoginEnabled = restrictLogin !== 'disabled';
    },
    enableRestrictLogin() {
      this.restrictLoginEnabled = !this.restrictLoginEnabled;
      if (this.restrictLoginEnabled) {
        this.restrictLogin = 'allow-holders';
      } else {
        this.restrictLogin = 'disabled';
      }
    },
    save() {
      const { idIssuer } = this.$route.params;
      // backgroundImageKey
      this.updateLandingPageConfiguration(idIssuer)
        .then(() => {
          this.errors = [];
          this.cancelSelf();
        })
        .catch((err) => {
          if (err.response.body && err.response.body.message) {
            this.errors = err.response.body.message.split(',');
          }
        });
    },
  },
};
</script>

<style src="~/vendor/libs/bootstrap-markdown/bootstrap-markdown.css" />
<style lang="scss" scoped>
  .cp-preview-modal{
    text-align: center;
    img{
      max-width:100%;
    }
  }
  .cp-file-preview{
    min-height:256px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-height: 180px;
      max-width: 70%;
      margin: auto;
    }
    img.link{
      cursor: pointer;
    }
  }
  .landing-settings-table {
    tr {
      td {
        width: 50%;
      }
    }
  }
  .restrict-login-header {
    display: flex;
  }
  .radio-label {
    line-height: 1.4rem;
  }
</style>
