import { render, staticRenderFns } from "./documents.vue?vue&type=template&id=05363d80&scoped=true&"
import script from "./documents.vue?vue&type=script&lang=js&"
export * from "./documents.vue?vue&type=script&lang=js&"
import style0 from "./documents.vue?vue&type=style&index=0&id=05363d80&prod&lang=scss&"
import style1 from "./documents.vue?vue&type=style&index=1&id=05363d80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05363d80",
  null
  
)

export default component.exports