<template>
  <div>
    <cp-head-table
      title="System Countries"
      title-icon="ios-build"
    />

    <cp-table
      ref="cpTable"
      get-data-action="systemCountries/getDefaultCountrySettingsList"
      :fields="fields"
      :data-parser="parseData"
      default-sort-direction="asc"
    >
      <template
        slot="type"
        slot-scope="{ rowData }"
      >
        <b-badge variant="outline-info">
          {{ rowData.item.type }}
        </b-badge>
      </template>

      <template
        slot="agreement1"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.agreement1 && rowData.item.agreement1.name }}
      </template>

      <template
        slot="agreement2"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.agreement2 && rowData.item.agreement2.name }}
      </template>

      <template
        slot="agreementOnEmail"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{
            'ion-md-checkmark text-primary': rowData.item.agreementOnEmail,
            'ion-md-close text-light': !rowData.item.agreementOnEmail
          }"
        />
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.SYSTEM_CONFIG_COUNTRIES"
        >
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            title="Edit"
            @click="editElement(rowData.item)"
          >
            <i
              class="ion ion-md-create"
              :style="{ color: '#04bec4' }"
            />
            Edit
          </b-btn>
        </Can>
      </template>
    </cp-table>

    <cp-system-countries-modal
      ref="CpSystemCountriesModal"
      :country-data="countryData"
      :show-agreements-list="showAgreementsList"
      @onUpdateCountry="updateCountry"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import countries from 'i18n-iso-countries';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../utilities/ability/abilities';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import CpSystemCountriesModal from './system-country-modal';
import CpMixPanel, { TrackingTypes } from '../../../mixins/mix-panel';
import fields from './fields';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'SystemCountries',
  metaInfo: {
    title: 'System Countries',
  },
  components: {
    Can,
    CpHeadTable,
    CpTable,
    CpSystemCountriesModal,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      fields,
      countryData: {},
      showAgreementsList: [],
      ActionsTypes,
      AbilitiesTypes,
    };
  },

  created() {
    this.searchAgreements();
  },
  mounted() {
    this.trackEvent(TrackingTypes.SYSTEM_CONFIG_COUNTRIES_LAND);
  },
  methods: {
    ...mapActions('systemCountries',
      [
        'getDefaultCountrySettingsById',
        'updateDefaultCountrySettings',
      ]),
    ...mapActions('systemAgreements', ['getDefaultAgreements']),
    ...mapMutations('systemCountries', ['SET_EDITABLE_COUNTRY']),

    parseData(data) {
      return data.map((item) => {
        const countryName = countries.getName(item.countryCode, 'en');
        return { ...item, countryName };
      });
    },

    searchAgreements() {
      this.getDefaultAgreements()
        .then(({ data }) => {
          this.showAgreementsList = (data || []).map(({ name }) => name);
        });
    },

    updateCountry({ id, ...data }) {
      this.updateDefaultCountrySettings({
        id,
        data,
      }).then(() => {
        this.$refs.CpSystemCountriesModal.$refs.systemCountries.hide();
        this.$refs.cpTable.updateTableData();
      });
    },

    editElement({ id }) {
      this.getDefaultCountrySettingsById(id).then(({ data }) => {
        this.countryData = data;
        this.SET_EDITABLE_COUNTRY(data);
        this.$refs.CpSystemCountriesModal.$refs.systemCountries.show();
        return data;
      }).then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },
  },
};
</script>
