<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div>
      <div class="row align-items-center ml-0 mr-0">
        <div>
          <cp-select
            v-model="languageSelected"
            :label="$t('opportunities.editOpportunity.content.language.label')"
            name="languageId"
            :options="formattedLanguages"
            :disabled="loading"
            @input="setLanguage($event)"
          />
        </div>
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.CONFIGURATION_ASSET_CONTENT"
        >
          <div class="card-body mt-2">
            <label
              class="switcher"
              for="summaryPublished"
            >
              <span class="ml-3">{{
                $t('opportunities.editOpportunity.content.language.publishText')
              }}</span>
              <input
                id="summaryPublished"
                v-model="summaryPublished"
                type="checkbox"
                class="switcher-input"
                :disabled="defaultLanguageActive | loading"
                @input="setPublish($event)"
              >
              <span class="switcher-indicator">
                <span class="switcher-yes" />
                <span class="switcher-no" />
              </span>
            </label>
          </div>
        </Can>
      </div>
    </div>
    <div class="col-md-12 text-center">
      <b-spinner
        v-if="loading"
        class="align-middle"
      />
    </div>
    <div
      v-if="!loading"
      class="card mb-5"
    >
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('opportunities.editOpportunity.summary.header.text') }}
            </h4>
          </div>
          <Can
            :i="ActionsTypes.UPDATE"
            :a="AbilitiesTypes.CONFIGURATION_ASSET_CONTENT"
          >
            <cp-edit-buttons
              :edit-mode="editMode"
              @changeMode="changeModeProxy"
              @update="save"
            />
          </Can>
        </div>
      </div>
      <div
        v-if="errors.length"
        class="card-body"
      >
        <cp-error-notices :errors="errors" />
      </div>
      <div
        v-if="editMode"
        class="card-body"
      >
        <cp-input-container
          ref="cpInputContainer"
          v-model="model"
        >
          <div class="row">
            <div class="col-md-4">
              <cp-select
                v-model="model.tokenId"
                name="tokenId"
                :label="$t('opportunities.editOpportunity.summary.token.label')"
                :disabled="true"
                :required="true"
                :options="issuerTokensOptions"
                validate="required"
              />
            </div>
            <div class="col-md-4">
              <h6 class="mb-2">
                {{ $t('opportunities.editOpportunity.summary.round.label') }}
              </h6>
              <div class="h5 font-weight-light mb-4">
                {{ currentRound.text | nullFilter }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <h6>
                {{ $t('opportunities.editOpportunity.summary.image.label') }}
              </h6>
              <cp-file-uploader
                id="image-uploader"
                :default-url="imageUrl"
                extension=".jpeg,.jpg,.png,.gif"
                style="margin-bottom: 20px"
                @onChange="onLogoUploaded"
              />
              <h6>
                {{
                  $t('opportunities.editOpportunity.summary.smallImage.label')
                }}
              </h6>
              <cp-file-uploader
                id="small-image-uploader"
                :default-url="smallImageUrl"
                extension=".jpeg,.jpg,.png,.gif"
                @onChange="onSmallImageUploaded"
              />
              <label
                class="switcher w-100 mt-5"
                for="enabled"
              >
                <span class="ml-3">{{
                  model.enabled
                    ? $t('opportunities.editOpportunity.summary.publishedText')
                    : $t(
                      'opportunities.editOpportunity.summary.unpublishedText'
                    )
                }}</span>
                <input
                  id="enabled"
                  v-model="model.enabled"
                  type="checkbox"
                  class="switcher-input"
                >
                <span class="switcher-indicator">
                  <span class="switcher-yes" />
                  <span class="switcher-no" />
                </span>
              </label>
              <label
                class="switcher w-100 mt-5"
                for="shadow"
              >
                <span class="ml-3">{{
                  model.shadow
                    ? $t('opportunities.editOpportunity.summary.shadowOn')
                    : $t('opportunities.editOpportunity.summary.shadowOff')
                }}</span>
                <input
                  id="shadow"
                  v-model="model.shadow"
                  type="checkbox"
                  class="switcher-input"
                >
                <span class="switcher-indicator">
                  <span class="switcher-yes" />
                  <span class="switcher-no" />
                </span>
              </label>
              <label
                class="switcher w-100 mt-5"
                for="qualifiedOnly"
              >
                <span class="ml-3">{{
                  model.qualifiedOnly
                    ? $t(
                      'opportunities.editOpportunity.summary.qualifiedOnlyOn'
                    )
                    : $t(
                      'opportunities.editOpportunity.summary.qualifiedOnlyOff'
                    )
                }}</span>
                <input
                  id="qualifiedOnly"
                  v-model="model.qualifiedOnly"
                  type="checkbox"
                  class="switcher-input"
                >
                <span class="switcher-indicator">
                  <span class="switcher-yes" />
                  <span class="switcher-no" />
                </span>
              </label>
              <label
                class="switcher w-100 mt-5"
                for="accreditedOnly"
              >
                <span class="ml-3">{{
                  model.accreditedOnly
                    ? $t(
                      'opportunities.editOpportunity.summary.accreditedOnlyOn'
                    )
                    : $t(
                      'opportunities.editOpportunity.summary.accreditedOnlyOff'
                    )
                }}</span>
                <input
                  id="accreditedOnly"
                  v-model="model.accreditedOnly"
                  type="checkbox"
                  class="switcher-input"
                >
                <span class="switcher-indicator">
                  <span class="switcher-yes" />
                  <span class="switcher-no" />
                </span>
              </label>
              <div class="mt-3">
                <cp-date-picker
                  v-model="model.signedDate"
                  :label="
                    $t('opportunities.editOpportunity.summary.signedDate')
                  "
                  name="signedDate"
                  validate="required"
                  use-utc
                  preview-date-hide
                />
              </div>
              <div
                v-for="field in translatedSummary.fields"
                :key="field.key"
                class="mt-3"
              >
                <cp-input
                  v-model="field.value"
                  :label="
                    $t(
                      `opportunities.editOpportunity.summary.fields.${field.key}.label`
                    ) || field.key
                  "
                  :validate="field.mandatory ? 'required' : undefined"
                />
              </div>
            </div>
            <div class="col-md-8">
              <cp-input
                key="title"
                v-model="translatedSummary.title"
                :label="$t('opportunities.editOpportunity.summary.title.label')"
                name="title"
                validate="required"
                style="margin-bottom: 20px"
              />
              <cp-html-editor
                v-model="translatedSummary.content"
                name="content"
                :label="
                  $t('opportunities.editOpportunity.summary.description.label')
                "
                validate="required"
                style="margin-bottom: 20px"
              />
              <cp-input
                v-model="translatedSummary.shortDescription"
                name="shortDescription"
                :label="
                  $t(
                    'opportunities.editOpportunity.summary.shortDescription.label'
                  )
                "
                validate="required"
                style="margin-bottom: 20px"
              />
              <cp-input
                v-model="model.scheduleCallLink"
                name="scheduleCallLink"
                :label="
                  $t(
                    'opportunities.editOpportunity.summary.scheduleCallLink.label'
                  )
                "
                style="margin-bottom: 20px"
              />
              <div class="row">
                <div
                  v-for="attr in model.attributes"
                  :key="attr.id"
                  class="col-md-6 mb-4"
                >
                  <h5>
                    {{
                      $t(
                        'opportunities.editOpportunity.summary.attributes.attribute1.label'
                      )
                    }}
                  </h5>
                  <cp-input
                    v-model="attr.content"
                    :html-props="{ maxlength: 20 }"
                  />
                </div>
              </div>
              <cp-input
                key="order"
                v-model="model.order"
                input-type="number"
                :label="$t('opportunities.editOpportunity.summary.order.label')"
                name="order"
                validate="required"
              />
            </div>
          </div>
        </cp-input-container>
      </div>
      <div
        v-else
        class="card-body"
      >
        <div class="row">
          <div class="col-md-4">
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.token.label') }}
            </h6>
            <div class="h5 font-weight-light mb-4">
              {{ currentToken.name }}
            </div>
          </div>
          <div class="col-md-4">
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.round.label') }}
            </h6>
            <div class="h5 font-weight-light mb-4">
              {{ currentRound.text | nullFilter }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.image.label') }}
            </h6>
            <div
              class="card border-light d-flex justify-content-center align-items-center w-100"
              style="margin-bottom: 20px"
            >
              <img
                v-if="currentOpportunity.image"
                v-b-modal.opportunity-image
                class="d-block w-100"
                :src="previewImageUrl"
                :alt="currentOpportunity.title"
              >
              <b-modal
                id="opportunity-image"
                size="lg"
                class="cp-preview-modal"
                title="Opportunity Image"
                hide-footer
              >
                <span class="modal-logo-wrapper">
                  <img
                    class="d-inline-block w-100"
                    :src="currentOpportunity.image"
                    :alt="currentOpportunity.title"
                  >
                </span>
              </b-modal>
            </div>
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.smallImage.label') }}
            </h6>
            <div
              class="card border-light d-flex justify-content-center align-items-center w-100"
            >
              <img
                v-if="currentOpportunity.smallImage"
                v-b-modal.opportunity-small-image
                class="d-block w-100"
                :src="previewSmallImageUrl"
                :alt="currentOpportunity.title"
              >
              <b-modal
                id="opportunity-small-image"
                size="lg"
                class="cp-preview-modal"
                title="Opportunity Small Image"
                hide-footer
              >
                <span class="modal-logo-wrapper">
                  <img
                    class="d-inline-block w-100"
                    :src="currentOpportunity.smallImage"
                    :alt="currentOpportunity.title"
                  >
                </span>
              </b-modal>
            </div>
            <span
              :class="`badge m-2  mb-4 h4  badge-${
                currentOpportunity.enabled ? 'success' : 'dark'
              }`"
            >
              {{
                currentOpportunity.enabled
                  ? $t('opportunities.editOpportunity.summary.publishedText')
                  : $t('opportunities.editOpportunity.summary.unpublishedText')
              }}
            </span>
            <span
              :class="`badge m-2  mb-4 h4  badge-${
                currentOpportunity.shadow ? 'success' : 'dark'
              }`"
            >
              {{
                currentOpportunity.shadow
                  ? $t('opportunities.editOpportunity.summary.shadowOn')
                  : $t('opportunities.editOpportunity.summary.shadowOff')
              }}
            </span>
            <span
              :class="`badge m-2  mb-4 h4  badge-${
                currentOpportunity.qualifiedOnly ? 'success' : 'dark'
              }`"
            >
              {{
                currentOpportunity.qualifiedOnly
                  ? $t('opportunities.editOpportunity.summary.qualifiedOnlyOn')
                  : $t('opportunities.editOpportunity.summary.qualifiedOnlyOff')
              }}
            </span>
            <span
              :class="`badge m-2  mb-4 h4  badge-${
                currentOpportunity.accreditedOnly ? 'success' : 'dark'
              }`"
            >
              {{
                currentOpportunity.accreditedOnly
                  ? $t('opportunities.editOpportunity.summary.accreditedOnlyOn')
                  : $t(
                    'opportunities.editOpportunity.summary.accreditedOnlyOff'
                  )
              }}
            </span>
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.order.label') }}
            </h6>
            <div class="h5 font-weight-light mb-4">
              {{ currentOpportunity.order }}
            </div>
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.signedDate') }}
            </h6>
            <div class="h5 font-weight-light mb-4">
              {{ signedDate }}
            </div>

            <template
              v-for="field in translatedSummary.fields"
              class="mt-3"
            >
              <template v-if="field.value">
                <h6
                  :key="field.key + 'h'"
                  class="mb-2"
                >
                  {{
                    $t(
                      `opportunities.editOpportunity.summary.fields.${field.key}.label`
                    ) || field.key
                  }}
                </h6>
                <div
                  :key="field.key + 'v'"
                  class="h5 font-weight-light mb-4"
                >
                  {{ field.value || '-' }}
                </div>
              </template>
            </template>
          </div>
          <div class="col-md-8">
            <h6 class="mb-2">
              {{ $t('opportunities.editOpportunity.summary.title.label') }}
            </h6>
            <div class="font-weight-light mb-4">
              {{ translatedSummary.title }}
            </div>
            <h6 class="mb-1">
              {{
                $t('opportunities.editOpportunity.summary.description.label')
              }}
            </h6>
            <div
              class="font-weight-light mb-4"
              v-html="sanitizedOpportunityContent"
            />
            <h6 class="mb-1">
              {{
                $t(
                  'opportunities.editOpportunity.summary.shortDescription.label'
                )
              }}
            </h6>
            <div
              class="font-weight-light mb-4"
              v-html="sanitizedShortDescription"
            />
            <h6 class="mb-1">
              {{
                $t(
                  'opportunities.editOpportunity.summary.scheduleCallLink.label'
                )
              }}
            </h6>
            <div
              class="font-weight-light mb-4"
              v-html="sanitizedScheduleCall"
            />
            <div class="d-flex">
              <div
                v-for="attr in translatedSummary.attributes"
                :key="attr.id"
              >
                <div
                  v-if="attr.content"
                  class="attribute-container"
                >
                  <p class="font-weight-light mb-0">
                    {{ attr.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-0 mx-0">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { sanitize } from 'dompurify';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../../utilities/ability/abilities';
import CpEditButtons from '~/components/common/edit-buttons';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpEditableMixin from '~/mixins/editable-component';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpHtmlEditor from '@/components/common/standalone-components/inputs/cp-html-textarea';
import {
  CpInput,
  CpSelect,
  CpDatePicker,
} from '~/components/common/standalone-components/inputs';
import CpErrorNotices from '~/components/common/error-notices';
import { formatDateUTC } from '~/utilities/date-utils';

export default {
  name: 'EditOpportunitySummary',
  components: {
    Can,
    CpFileUploader,
    CpEditButtons,
    CpInput,
    CpInputContainer,
    CpSelect,
    CpDatePicker,
    CpErrorNotices,
    CpHtmlEditor,
  },

  mixins: [CpEditableMixin, staticPathGenerationMixin],
  data() {
    return {
      currentRound: {},
      currentToken: {},
      errors: [],
      customErrors: '',
      model: {},
      currRounds: [],
      languageSelected: 'EN',
      loading: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },

  computed: {
    ...mapGetters('opportunities', ['currentOpportunity', 'languages']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    ...mapGetters('rounds', ['roundsOption']),
    issuerTokensOptions() {
      return (this.issuerInfo.tokens || []).map(item => ({
        text: item.name,
        value: item.id,
      }));
    },
    imageUrl() {
      return this.currentOpportunity.image
        ? this.currentOpportunity.image
        : null;
    },
    smallImageUrl() {
      return this.currentOpportunity.smallImage
        ? this.currentOpportunity.smallImage
        : null;
    },
    previewImageUrl() {
      return this.currentOpportunity.image
        ? this.currentOpportunity.image
        : this.getImagePath('fallback-image.jpg');
    },
    previewSmallImageUrl() {
      return this.currentOpportunity.smallImage
        ? this.currentOpportunity.smallImage
        : this.getImagePath('fallback-image.jpg');
    },
    sanitizedOpportunityContent() {
      return sanitize(this.translatedSummary.content);
    },
    sanitizedShortDescription() {
      return sanitize(this.translatedSummary.shortDescription);
    },
    sanitizedScheduleCall() {
      return sanitize(this.currentOpportunity.scheduleCallLink);
    },
    signedDate() {
      return formatDateUTC(this.currentOpportunity.signedDate, 'll');
    },
    defaultLanguageActive() {
      return this.languageSelected === 'EN';
    },
    formattedLanguages() {
      return this.languages.map(language => ({
        value: language.key,
        text: language.description,
      }));
    },
    summaryPublished() {
      const { summary } = this.currentOpportunity.languageDetails[this.languageSelected];
      return summary ? summary.published : false;
    },
    translatedSummary() {
      const { summary } = this.currentOpportunity.languageDetails[this.languageSelected];
      return {
        title: summary.title,
        content: summary.description,
        shortDescription: summary.shortDescription,
        attributes: summary.attributes,
        fields: summary.fields,
      };
    },
  },
  created() {
    if (this.$route.query.edit) {
      this.setConfigState();
      this.editMode = true;
    }
    this.loadData();
  },
  methods: {
    ...mapActions('opportunities', [
      'updateOpportunity',
      'getOpportunityById',
      'createLanguageSummary',
      'updateLanguageSummary',
    ]),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    setConfigState() {
      const { summary } = this.currentOpportunity.languageDetails[this.languageSelected];
      this.model = JSON.parse(JSON.stringify(this.currentOpportunity || {}));
      this.model.attributes = summary.attributes;
      this.model.title = summary.title;
      this.model.content = summary.description;
      this.model.shortDescription = summary.shortDescription;
      if (!this.model.attributes) this.model.attributes = [];
      let diff = 4 - this.model.attributes.length;
      while (diff > 0) {
        this.model.attributes.push({
          opportunityId: this.currentOpportunity.id,
          order: 4 - (diff - 1),
          title: '',
          content: '',
        });
        diff -= 1;
      }
      this.model.attributes.sort((first, second) => first.order - second.order);
    },
    onLogoUploaded({ fileKey }) {
      if (fileKey) this.model.tmpImageFileKey = fileKey;
      else this.model.tmpImageFileKey = '';
    },
    onSmallImageUploaded({ fileKey }) {
      if (fileKey) this.model.tmpSmallImageFileKey = fileKey;
      else this.model.tmpSmallImageFileKey = '';
    },
    changeModeProxy() {
      if (this.editMode) {
        this.$router.push({ query: { edit: undefined } });

        // on cancel clear the image IDs to prevent accidental upload
        // this.clearTouched();
        this.customErrors = '';
      } else {
        this.$router.push({ query: { edit: 1 } });
        this.setConfigState();
      }
      this.changeMode();
    },
    loadData() {
      this.currentRound = (this.roundsOption || []).find(
        ({ value }) => value === this.currentOpportunity.roundId,
      ) || { text: null };
      this.currentToken = (this.issuerInfo.tokens || []).find(
          ({ id }) => id === this.currentOpportunity.tokenId,
        ) || {};
    },
    save() {
      this.$refs.cpInputContainer.validateForm().then(async () => {
        if (this.$refs.cpInputContainer.isValid()) {
          try {
            const { idIssuer: issuerId } = this.$route.params;
            const opportunityId = this.currentOpportunity.id;
            const filteredFields = this.getFilteredFields(
              this.translatedSummary.fields,
            );
            const attributes = this.model.attributes.filter(
              attr => attr.content,
            );
            this.loading = true;
            const { fields, ...restModel } = this.model;
            const summaryPayload = {
              title: this.model.title,
              description: this.model.content,
              shortDescription: this.model.shortDescription,
              attributes,
              fields: filteredFields,
              opportunity: { ...restModel, attributes },
            };
            await this.updateLanguageSummary({
              issuerId,
              opportunityId,
              summaryPayload,
              language: this.languageSelected,
            });
            await this.getOpportunityById({
              issuerId,
              opportunityId,
              includeEmptyFields: true,
              additionalFields: 'attributes,documents,contents,languages',
            });
            this.changeModeProxy();
            this.loadData();
          } catch (x) {
            this.CALL_ERROR_TOASTER('Save summary error:', x);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async setLanguage(language) {
      this.languageSelected = language;
      this.editMode = false;
      if (!this.currentOpportunity.languageDetails[language].summary) {
        const { idIssuer: issuerId } = this.$route.params;
        const opportunityId = this.currentOpportunity.id;
        const fields = this.getFilteredFields(this.currentOpportunity.fields);
        const summaryPayload = {
          languageKey: this.languageSelected,
          title: this.currentOpportunity.title,
          description: this.currentOpportunity.content,
          shortDescription:
            this.currentOpportunity.languageDetails.EN.summary.shortDescription,
          attributes: this.currentOpportunity.attributes,
          fields,
        };
        try {
          this.loading = true;
          await this.createLanguageSummary({
            issuerId,
            opportunityId,
            summaryPayload,
          });
          await this.getOpportunityById({
            issuerId,
            opportunityId,
            includeEmptyFields: true,
            additionalFields: 'attributes,documents,contents,languages',
          });
        } catch (x) {
          this.CALL_ERROR_TOASTER('Set language error:', x);
        } finally {
          this.loading = false;
        }
      }
    },
    async setPublish() {
      const { idIssuer: issuerId } = this.$route.params;
      const opportunityId = this.currentOpportunity.id;
      const { summary } = this.currentOpportunity.languageDetails[this.languageSelected];
      const fields = this.getFilteredFields(summary.fields);
      const summaryPayload = {
        title: summary.title,
        description: summary.description,
        published: !this.summaryPublished,
        fields,
      };
      await this.updateLanguageSummary({
        issuerId,
        opportunityId,
        summaryPayload,
        language: this.languageSelected,
      });
      await this.getOpportunityById({
        issuerId,
        opportunityId,
        includeEmptyFields: true,
        additionalFields: 'attributes,documents,contents,languages',
      });
    },
    getFilteredFields(fields) {
      return fields.filter(field => !!field.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.attribute-container {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  border-right: 1px solid #d9d8d8;
  padding-right: 1em;
}
</style>
