<template>
  <cp-table
    ref="cpTable"
    default-sort-by="createdAt"
    default-sort-direction="desc"
    :checkbox="$can(ActionsTypes.UPDATE, AbilitiesTypes.ONBOARDING)"
    :mass-selectable="$can(ActionsTypes.UPDATE, AbilitiesTypes.ONBOARDING)"
    :filters="params"
    get-data-action="investors/getInvestorsList"
    :url-params="urlParams"
    :fields="tableFields"
    :data-parser="listCorrection"
    :search-value="searchQueryParam"
    :table-title="$t('investorsOnboarding.title')"
    @onSelect="onSelect($event)"
    @onSelectAll="onSelectAll($event)"
    @onSort="$emit('onSort', $event)"
    @tableDataUpdated="onTableDataUpdated"
    @onMassSelect="onMassSelect"
  >
    <template slot="tableHeader">
      <span class="headers-wrapper">
        <div class="batch-actions">
          <cp-batch-actions
            :disabled="isEmptyExportList"
            :can-export-list="$can(ActionsTypes.CREATE, AbilitiesTypes.ONBOARDING)"
            :can-edit-labels="$can(ActionsTypes.UPDATE, AbilitiesTypes.ONBOARDING)"
            :can-show-labels="$can(ActionsTypes.UPDATE, AbilitiesTypes.ONBOARDING)"
            @onExportList="$emit('onExportList')"
            @onEditLabels="$emit('onEditLabels')"
            @onShowLabels="$emit('onShowLabels')"
          />
        </div>
        <Can
          :i="ActionsTypes.CREATE"
          :a="AbilitiesTypes.ONBOARDING"
        >
          <div class="input-group">
            <cp-select
              v-model="selectedOperation"
              input-class=""
              :options="operations"
            />
            <span class="input-group-append">
              <cp-button
                :disabled="selectedInvestorsCount === 0"
                size="ms"
                variant="primary"
                :is-loading="isBulkLoading"
                @click="handleBulkOperationClick"
              >
                {{ $t('investorsOnboarding.label.run', [selectedInvestorsCount]) }}
              </cp-button>
            </span>
          </div>
        </Can>
      </span>
      <cp-send-invite-email-modal
        ref="invite-modal"
        is-bulk
        :user-ids="userIds"
        :excluded-user-ids="excludedUserIds"
        :filters="bulkActionFilters"
        @onInviteSended="showBulkSummaryModal"
      />
      <cp-bulk-summary-modal
        ref="bulk-summary"
        v-bind="bulkSummary"
      />
    </template>
    <template
      slot="name"
      slot-scope="{ rowData }"
    >
      <a
        v-if="!rowData.item.isAggregatedBrokerDealerGroup"
        href="javascript:void(0)"
        @click.prevent="$emit('onViewElem', rowData.item, $event)"
      >
        {{ getFullName(rowData.item) }}
      </a>
      <div v-else>
        {{ getFullName(rowData.item) }}
      </div>
    </template>

    <template
      slot="countryCode"
      slot-scope="{ rowData }"
    >
      <cp-country-name
        :country-code="rowData.item.countryCode"
        :locale="locale"
      />
    </template>

    <template
      slot="investorType"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.investorTypeValue }}
    </template>

    <template
      slot="registrationSource"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.registrationSourceValue }}
    </template>

    <template
      v-if="showHasSecuritizeId"
      slot="hasSecuritizeId"
      slot-scope="{ rowData }"
    >
      <b-badge
        class="align-text-bottom ml-1"
        :variant="rowData.item.hasSecuritizeIdStyle"
      >
        {{ rowData.item.hasSecuritizeIdValue }}
      </b-badge>
    </template>

    <template
      slot="kycStatus"
      slot-scope="{ rowData }"
    >
      <b-badge
        class="align-text-bottom ml-1"
        :variant="rowData.item.kycStatusStyle"
      >
        {{ rowData.item.kycStatusValue }}
      </b-badge>
    </template>

    <template
      slot="accreditedStatus"
      slot-scope="{ rowData }"
    >
      <b-badge
        class="align-text-bottom ml-1"
        :variant="rowData.item.accreditedStatusStyle"
      >
        {{ rowData.item.accreditedStatusValue }}
      </b-badge>
    </template>

    <template
      slot="labels"
      slot-scope="{ rowData }"
    >
      <div v-if="isLabels(rowData.item)">
        <span
          v-for="label in getLabelsList(rowData.item)"
          :key="label"
        >
          <b-badge
            v-if="label"
            class="align-text-bottom ml-1"
            variant="outline-secondary"
          >
            {{ label }}
          </b-badge>
        </span>
        <span
          v-if="rowData.item.labels.length > 3"
          v-b-tooltip.hover="true"
          :title="getLabelsTitle(rowData.item)"
          style="cursor: pointer"
        >
          + {{ rowData.item.labels.length - 3 }}
        </span>
      </div>
    </template>

    <template
      slot="createdAt"
      slot-scope="{ rowData }"
    >
      <span>
        {{ rowData.item.createdAt | dateFilter }}
      </span>
    </template>

    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <div class="d-flex">
        <cp-button
          v-if="!rowData.item.isAggregatedBrokerDealerGroup"
          variant="default btn-xs icon-btn md-btn-flat"
          @click="$emit('onViewElem', rowData.item, $event)"
        >
          <i class="ion ion-ios-eye" />
        </cp-button>
        <Can
          :i="ActionsTypes.DELETE"
          :a="AbilitiesTypes.ONBOARDING"
        >
          <cp-button
            v-if="!rowData.item.isAggregatedBrokerDealerGroup"
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="$emit('onRemoveElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </Can>
      </div>
    </template>
  </cp-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import CpBatchActions from '~/pages/_idIssuer/investors/batch-actions';
import CpCountryName from '~/components/common/country-name';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

import CpTimeConverter from '~/mixins/time-converter';
import GeneralActionsOfInvestorTables from '~/mixins/general-actions-of-investor-tables';

import { accreditedStatus, kycStatus, hasSecuritizeIdStatus, tableFields, investorTypeList } from './options';
import CpSendInviteEmailModal from '../../details/components/send-invite-modal.vue';
import CpBulkSummaryModal from '../../modals/bulk-summary-modal';

export default {
  name: 'CpOnboardingTable',
  components: {
    Can,
    CpSelect,
    CpBatchActions,
    CpSendInviteEmailModal,
    CpCountryName,
    CpTable,
    CpButton,
    CpBulkSummaryModal,
  },
  mixins: [
    CpTimeConverter,
    GeneralActionsOfInvestorTables,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    showOrigin: {
      type: Boolean,
      default: false,
    },
    showHasSecuritizeId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accreditedStatus,
      kycStatus,
      hasSecuritizeIdStatus,
      selectedEntityState: {},
      isBulkLoading: false,
      bulkActionFilters: {},
      userIds: [],
      excludedUserIds: [],
      totalTableData: [],
      selectedInvestorsCount: 0,
      selectedOperation: 'invite_email',
      searchQueryParam: this.$route.query.search || '',
      bulkSummary: {},
      locale: null,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['authorizationLevel', 'authorizations']),
    ...mapGetters('issuersInfo', ['issuerInfo', 'permissionsPerIssuer']),
    tableFields() {
      const unauthorizedFields = [];
      if (!this.showOrigin) unauthorizedFields.push('registrationSource');
      if (!this.showHasSecuritizeId) unauthorizedFields.push('hasSecuritizeId');
      return tableFields.filter(tableField => !unauthorizedFields.includes(tableField.key));
    },
    params() {
      return {
        view: 'onboarding',
        ...this.filters,
      };
    },
    operations() {
      const operationsArray = [
        {
          i18nKey: 'investorsOnboarding.option.operations.inviteEmail',
          value: 'invite_email',
        },
        {
          i18nKey: 'investorsOnboarding.option.operations.passwordReset',
          value: 'password_reset',
        },
        {
          i18nKey: 'investorsOnboarding.option.operations.accreditationEmail',
          value: 'accreditation_email',
        },
      ];
      if (this.issuerInfo.permissionServiceSecret) {
        operationsArray.push({
          text: this.$t('investorsOnboarding.option.operations.inviteSecidEmail'),
          value: 'invite_secid_email',
        });
      }
      if (this.authorizationLevel === 'megaadmin' && this.authorizations.includes('securitize-id') && Boolean(this.permissionsPerIssuer.isTrustedIssuer)) {
        operationsArray.push({
          text: this.$t('investorsOnboarding.option.operations.secIDReverseSync'),
          value: 'sec_id_reverse_sync',
        });
      }
      return operationsArray;
    },
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  created() {
    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });
  },
  methods: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapActions('investors', ['bulkResetPassword', 'bulkAccreditationEmail', 'bulkInviteSecIdEmail', 'bulkSecIDReverseSync']),
    handleBulkOperationClick() {
      this.isBulkLoading = true;
      this.prepareBulkOperationData();
      const body = {
        userIds: this.userIds.length ? this.userIds : null,
        exceptInvestorsIds: this.excludedUserIds.length ? this.excludedUserIds : null,
        ...this.bulkActionFilters,
      };
      this.performBulkOperation(this.$route.params.idIssuer, body)
        .then((res) => {
          if (this.selectedOperation !== 'invite_email' && this.selectedOperation !== 'sec_id_reverse_sync') this.showBulkSummaryModal(res);
        })
        .finally(() => {
          this.isBulkLoading = false;
        });
    },
    prepareBulkOperationData() {
      this.bulkActionFilters = this.getTableFilters();
      const { selectedItems, excludedItems } = this.getSelectedItems();
      this.userIds = selectedItems.map(({ id }) => id);
      this.excludedUserIds = excludedItems.map(({ id }) => id);
    },
    performBulkOperation(issuerId, body) {
      switch (this.selectedOperation) {
        case 'invite_email':
          return new Promise(resolve => resolve(this.$refs['invite-modal'].show()));
        case 'password_reset':
          return this.bulkResetPassword({ issuerId, body });
        case 'accreditation_email':
          return this.bulkAccreditationEmail({ issuerId, body });
        case 'invite_secid_email':
          return this.bulkInviteSecIdEmail({ issuerId, body });
        case 'sec_id_reverse_sync':
          return this.bulkSecIDReverseSync({ issuerId, body });
        default:
          return new Promise(reject => reject('not implemented'));
      }
    },
    onSelect({ value, item, selectedItemsCount }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onSelectInvestor', { value, item });
    },
    onTableDataUpdated({ items, totalItems, selectedItemsCount }) {
      this.totalTableData = items;
      this.selectedInvestorsCount = selectedItemsCount;
      this.checkEmptyTable(totalItems);
    },
    getAccreditedStatusToDisplay(item) {
      return item.accreditedStatus && (this.$t(this.accreditedStatus.labels[item.accreditedStatus]) || '');
    },
    getAccreditedStatusStyle(item) {
      return this.accreditedStatus.styles[item.accreditedStatus] || 'default';
    },
    getKycStatusToDisplay(item) {
      return item.kycStatus && (this.$t(this.kycStatus.labels[item.kycStatus]) || '');
    },
    getKycStatusStyle(item) {
      return kycStatus.styles[item.kycStatus] || 'default';
    },
    getHasSecuritizeIdToDisplay(item) {
      const hasSecuritizeId = item.hasSecuritizeId && item.hasSecuritizeId.toString();
      return hasSecuritizeId && (this.$t(this.hasSecuritizeIdStatus.labels[hasSecuritizeId]) || this.$t('investorsOnboarding.status.hasSecuritizeId.none'));
    },
    getHasSecuritizeIdStyle(item) {
      return hasSecuritizeIdStatus.styles[item.hasSecuritizeId] || 'default';
    },
    getInvestorTypeToDisplay(item) {
      return this.$t(investorTypeList[item.investorType]) || 'N/A';
    },
    getRegistrationSourceToDisplay(item) {
      return item.registrationSource || this.$t('investorsOnboarding.option.registrationSource.internal');
    },
    listCorrection(data) {
      return data.map((item) => {
        if (!item.brokerDealerGroupName) {
          return {
          ...item,
          investorTypeValue: this.getInvestorTypeToDisplay(item),
          accreditedStatusValue: this.getAccreditedStatusToDisplay(item),
          accreditedStatusStyle: this.getAccreditedStatusStyle(item),
          kycStatusValue: this.getKycStatusToDisplay(item),
          kycStatusStyle: this.getKycStatusStyle(item),
          hasSecuritizeIdValue: this.getHasSecuritizeIdToDisplay(item),
          hasSecuritizeIdStyle: this.getHasSecuritizeIdStyle(item),
          registrationSourceValue: this.getRegistrationSourceToDisplay(item),
          };
        }

        return {
          ...item,
          name: item.brokerDealerGroupName,
          registrationSourceValue: 'N/A',
          accreditedStatusValue: 'N/A',
          kycStatusValue: 'N/A',
          hasSecuritizeIdValue: 'N/A',
          isAggregatedBrokerDealerGroup: true,

          investorTypeValue: 'N/A',
          accreditedStatusStyle: this.getAccreditedStatusStyle(item),
          kycStatusStyle: this.getKycStatusStyle(item),
          hasSecuritizeIdStyle: this.getHasSecuritizeIdStyle(item),
        };
      });
    },
    selectedEntity(entityState) {
      this.selectedEntityState = entityState;
    },
    onSelectAll({ allSelect, selectedItemsCount, selectedItems }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onSelectAll', { allSelect, selectedItems });
    },
    onMassSelect({ massSelect, selectedItemsCount }) {
      this.selectedInvestorsCount = selectedItemsCount;
      this.$emit('onMassSelect', massSelect);
    },
    showBulkSummaryModal(data) {
      this.bulkSummary = data;
      this.$refs['bulk-summary'].show();
    },
  },
};
</script>

<style scoped lang="scss">
  .headers-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .input-group {
    width: auto;
  }

</style>
