<template>
  <div>
    <div
      v-if="!isReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('configurationJurisdictions.loading') }}
      </div>
    </div>
    <div v-if="isReady">
      <cp-configuration-head :page-name="$t('configurationJurisdictions.title')">
        <div class="d-flex align-items-center justify-content-end">
          <Can
            :i="ActionsTypes.CREATE"
            :a="AbilitiesTypes.ISSUER_JURISDICTIONS"
          >
            <cp-import-jurisdictions @onJurisdictionsImported="handleJurisdictionsImported" />
          </Can>
          <Can
            :i="ActionsTypes.CREATE"
            :a="AbilitiesTypes.ISSUER_JURISDICTIONS"
          >
            <cp-button
              size="md"
              variant="outline-primary"
              class="btn btn-primary ml-1"
              @click="exportJurisdictionsSettings"
            >
              <i class="ion ion-md-arrow-down" />
              {{ $t('configurationJurisdictions.button.exportJurisdictionsSettings') }}
            </cp-button>
          </Can>
        </div>
      </cp-configuration-head>
      <cp-restricted-jurisdictions
        :editable-card="editableCard"
        @onUpdate="update"
        @onChangeEditMode="changeEditMode"
      />
      <cp-disclaimers
        :editable-card="editableCard"
        @onUpdate="update"
        @onChangeEditMode="changeEditMode"
      />
      <cp-jurisdictions-table ref="countriesTable" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';
import CpRestrictedJurisdictions from './components/restricted-jurisdictions';
import CpConfigurationHead from '../configuration-head';
import CpJurisdictionsTable from './components/table';
import CpDisclaimers from './components/disclaimers-and-definitions';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpImportJurisdictions from './components/import-jurisdictions';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'Jurisdictions',

  metaInfo: {
    title: 'Jurisdictions',
  },

  components: {
    Can,
    CpConfigurationHead,
    CpRestrictedJurisdictions,
    CpDisclaimers,
    CpJurisdictionsTable,
    CpButton,
    CpImportJurisdictions,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      editableCard: null,
      isReady: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('issuerList', ['currentIssuer']),
  },
  mounted() {
    this.trackEvent(TrackingTypes.ISSUER_CONFIG_JURISDICTIONS_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },
  async created() {
    const issuerId = this.$route.params.idIssuer;
    await this.getJurisdictionsConfigurations(issuerId);
    setTimeout(() => {
      this.isReady = true;
    }, 1000);
  },
  methods: {
    ...mapActions('jurisdictions', [
      'getJurisdictionsConfigurations',
      'updateJurisdictions',
      'getJurisdictionsSettingsCsv',
    ]),
    update(data) {
      this.updateJurisdictions({
        data,
        issuerId: this.$route.params.idIssuer,
      }).then(() => {
        this.editableCard = null;
        this.$refs.countriesTable.$refs.cpTable.updateTableData();
      });
    },
    changeEditMode(blockName) {
      this.editableCard = blockName;
    },
    exportJurisdictionsSettings() {
      const params = {
        issuerId: this.$route.params.idIssuer,
      };
      this.getJurisdictionsSettingsCsv({ params, issuerName: this.currentIssuer.name }).then();
    },
    async handleJurisdictionsImported() {
      this.$refs.countriesTable.$refs.cpTable.updateTableData();
      await this.getJurisdictionsConfigurations(this.$route.params.idIssuer);
    },
  },
};
</script>
