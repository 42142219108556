<template>
  <div class="card mb-5 w-100">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4
            class="mb-0 text-primary"
          >
            <i
              class="ion ion-ios-image"
            /> {{ $t('opportunities.editOpportunity.content.imageBox.title') }}
            <b-badge v-if="content.tempId">
              {{ $t('opportunities.editOpportunity.content.newText') }}
            </b-badge>
          </h4>
        </div>
        <div class="d-flex p-2 w-50 flex-row justify-content-end">
          <opp-edit-buttons
            v-if="canEdit"
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="save"
          />
          <cp-button
            v-if="canDelete"
            class=""
            variant="primary"
            icon="ion ion-ios-trash"
            @click="deleteAction"
          >
            {{ $t('opportunities.editOpportunity.content.deleteConfirmation.deleteButton') }}
          </cp-button>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
      >
        <div class="row">
          <div class="col-md-4">
            <label
              class="form-label"
              style="margin-top: 20px"
            >
              {{ $t('opportunities.editOpportunity.content.imageBox.inputs.image.label') }}
            </label>
            <cp-file-uploader
              id="image-uploader"
              :default-url="model.resource"
              extension=".jpeg,.jpg,.png,.gif"
              validate="required"
              :max-file-size="5"
              @onChange="onImageUploaded"
            />
            <span
              v-if="imgError"
              class="invalid-feedback d-block"
            > Image field is required </span>
            <div class="d-flex flex-column align-items-center mt-3">
              <label
                class="form-label"
              >
                {{ $t('opportunities.editOpportunity.content.imageBox.inputs.alignment') }}
              </label>
              <div class="d-flex align-items-center justify-content-center mt-2 mb-5">
                <div class="alignment-container d-flex align-items-center justify-content-between">
                  <div
                    class="p-2 border-radius-left"
                  >
                    <b-img
                      width="30"
                      :src="model.alignment === 'left' ? leftSelectedIcon : leftIcon"
                      @click="setAlignment('left')"
                    />
                  </div>
                  <div
                    class="p-2 border-radius-right"
                  >
                    <b-img
                      width="30"
                      :src="model.alignment === 'right' ? rightSelectedIcon : rightIcon"
                      @click="setAlignment('right')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <content-visibility
              :is-edit="editMode"
              :show-to-verified-investors-only="model.showToVerifiedInvestorsOnly"
              :show-in-opportunity="model.showInOpportunity"
              :show-in-token-details="model.showInTokenDetails"
              @valueChanged="onContentVisibilityChanged"
            />
          </div>
          <div class="col-md-8">
            <cp-input
              key="title"
              v-model="model.title"
              class="mt-3"
              :label="$t('opportunities.editOpportunity.content.telegramBox.inputs.title.label')"
              :placeholder="$t('opportunities.editOpportunity.content.telegramBox.inputs.title.placeholder')"
              name="title"
              validate="required"
            />
            <minimal-html-textarea
              v-if="content.side === 'left'"
              v-model="model.content"
              name="content"
              :label="$t('opportunities.editOpportunity.content.imageBox.inputs.description.label')"
              validate="required"
            />
            <cp-textarea
              v-if="content.side === 'right'"
              v-model="model.content"
              :label="$t('opportunities.editOpportunity.content.videoBox.inputs.description.label')"
              :placeholder="$t('opportunities.editOpportunity.content.textBox.inputs.description.label')"
              name="content"
              :max-length="175"
              :rows="5"
            />
            <cp-input
              key="order"
              v-model="model.order"
              input-type="number"
              :label="$t('opportunities.editOpportunity.content.imageBox.inputs.order.label')"
              :placeholder="$t('opportunities.editOpportunity.content.imageBox.inputs.order.placeholder')"
              name="order"
              validate="required"
            />
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-else
      class="card-body"
    >
      <div class="d-flex justify-content-between">
        <img
          class="mr-3 w-50"
          :class="{
            'high-order' : model.alignment === 'right',
            'mr-3': model.alignment === 'left',
            'ml-3': model.alignment === 'right',
          }"
          :src="content.resource"
        >
        <div class="d-flex flex-column w-50">
          <h5>{{ content.title }}</h5>
          <p
            v-html="content.content"
          />
          <content-visibility
            :is-edit="editMode"
            :show-to-verified-investors-only="content.showToVerifiedInvestorsOnly"
            :show-in-opportunity="content.showInOpportunity"
            :show-in-token-details="content.showInTokenDetails"
          />
        </div>
      </div>
      <p class="mt-5">
        Order: {{ content.order }}
      </p>
      <ContentSide
        :side="content.side"
      />
    </div>
  </div>
</template>
<script>
import CpEditableMixin from '~/mixins/editable-component';
import OppEditButtons from './edit-buttons';
import CpInputContainer from '~/components/common/cpInputContainer';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpFileUploader from '@/components/common/standalone-components/cp-file-uploader';
import MinimalHtmlTextarea from './minimal-html-textarea';
import leftIcon from '~/assets/img/left.svg';
import rightIcon from '~/assets/img/right.svg';
import leftSelectedIcon from '~/assets/img/left-selected.svg';
import rightSelectedIcon from '~/assets/img/right-selected.svg';
import ContentVisibility from '@/pages/_idIssuer/configuration/opportunities/components/content-visibility';
import ContentSide from '@/pages/_idIssuer/configuration/opportunities/components/content-side';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'ContentImageBox',
  components: {
    ContentVisibility,
    CpFileUploader,
    OppEditButtons,
    CpInputContainer,
    CpInput,
    CpButton,
    MinimalHtmlTextarea,
    ContentSide,
    CpTextarea,
  },
  mixins: [CpEditableMixin],
  props: {
    content: {
      type: undefined,
      required: true,
    },
    editContentMode: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {},
      imgError: false,
      rightIcon,
      leftIcon,
      leftSelectedIcon,
      rightSelectedIcon,
    };
  },
  created() {
    this.editMode = this.editContentMode;
    this.setConfigState();
  },
  methods: {
    changeModeProxy() {
      if (!this.editMode) {
        this.setConfigState();
      }
      this.changeMode();
    },
    setConfigState() {
      this.model = { ...this.content };
      if (this.model.alignment === 'none') this.model.alignment = 'left';
    },
    save() {
      this.$refs.cpInputContainer.validateForm()
        .then(async () => {
          if (!this.model.resource) {
            this.imgError = true;
            return;
          }
          if (this.$refs.cpInputContainer.isValid()) {
            this.$emit('onSave', this.model);
            this.changeMode();
          }
        });
    },
    deleteAction() {
      this.$emit('onDeleteAction', this.model);
    },
    onImageUploaded({ fileKey }) {
      this.model.resource = fileKey || '';
      this.imgError = false;
    },
    setAlignment(alignment) {
      this.model = Object.assign({}, this.model, { alignment });
    },
    onContentVisibilityChanged({ visibilityType, value }) {
      this.model[visibilityType] = value;
      this.content[visibilityType] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .alignment-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    .border-radius-left {
      border-radius: 10px 0 0 10px;
    }
    .border-radius-right {
     border-radius: 0 10px 10px 0;
    }
  }
  .high-order {
    order: 1;
  }
</style>
