import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';

export default async () => {
  const {
    state: { locale, MixPanel },
  } = globalStore;

  const {
    state: {
      id,
      idUUID,
      name,
      email,
      authorizationLevel,
      externalId,
      authorizations,
      picture,
      isViewOnly,
    },
  } = currentOperatorStore;

  const params = {
    MixPanel,
  };

  return Frame(
    'investor_details',
    'Investor Details',
    './InvestorDetailsApp',
    process.env.VUE_APP_INVESTOR_DETAILS_MFE_ENTRY_POINT,
    () => ({
        locale,
        operatorInfo: {
          operatorId: id,
          idUUID,
          name,
          email,
          authorizationLevel,
          externalId,
          authorizations,
          picture,
          isViewOnly,
        },
      }),
    params,
  );
};
