
import Vue from 'vue'; // eslint-disable-line
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import exponentialToDecimal from '../mixins/numberBig';
import { i18n } from '../i18n';

Vue.filter('unixDateFilter', (date) => {
  const result = moment.unix(date).format('ll');
  return result && result !== 'Invalid date' ? result : '-';
});

Vue.filter('dateFilterWOTz', (date) => {
  const result = moment.utc(date).format('ll');
  return result && result !== 'Invalid date' ? result : '-';
});

Vue.filter('dateFilter', (date, privacyControlOn = false) => {
  if (privacyControlOn) return date;
  const result = moment(date).format('ll');
  return result && result !== 'Invalid date' ? result : '-';
});

Vue.filter('standardDateFilter', (date) => {
  const result = moment(date).format('YYYY-MM-DD, h:mm:ss a');
  return result && result !== 'Invalid date' ? result : '-';
});
Vue.filter('transactionTimeFilter', (date) => {
  const result = moment(date).format('YYYY-MM-DD, HH:mm');
  return result && result !== 'Invalid date' ? result : '-';
});
Vue.filter('blockchainSign', (blockchainType) => {
  const blockchainMap = {
    tezos: 'TZ',
    ethereum: 'ETH',
    algorand: 'ALGO',
  };
  return blockchainMap[blockchainType] || 'ETH';
});

Vue.filter('currencyIdentifierToSymbol', (identifier) => {
  const currencySymbolMap = {
    USD: '$',
    CAD: '$',
    JPY: '¥',
    EUR: '€',
    GBP: '£',
    THB: '฿',
    AUD: '$',
  };
  return currencySymbolMap[identifier] || '';
});

Vue.filter('separatedDateFilter', (date) => {
  const result = moment(date).format('DD/MM/YYYY');
  return result && result !== 'Invalid date' ? result : '-';
});

Vue.filter('dateAndTimeFilter', (date) => {
  const result = moment(date).format('LLL');
  return result && result !== 'Invalid date' ? result : '-';
});
Vue.filter('nullFilter', (val) => {
  if (!val) return i18n.t('common.label.none');
  return val;
});
Vue.filter('notAvailable', (val) => {
  if (!val) return i18n.t('common.label.na');
  return val;
});
Vue.filter('noneToFalse', val => val.toLowerCase() !== 'none');
Vue.filter('booleanToYesNo', val => (val ? i18n.t('common.label.yes') : i18n.t('common.label.no')));
Vue.filter('activeInactive', val => (val ? i18n.t('common.label.active') : i18n.t('common.label.inactive')));
Vue.filter('nullToMinus', val => (val || '-'));
Vue.filter('emptyToNA', val => (val === '' ? 'N/A' : val));
Vue.filter('exponentialToDecimal', (...val) => exponentialToDecimal(val));
Vue.filter('currencySymbol', currencyCode => getSymbolFromCurrency(currencyCode));
Vue.filter('phoneIsVerified', val => (val ? i18n.t('common.phone.isVerified') : i18n.t('common.phone.isNotVerified')));
