import Vue from 'vue';
import '~/utilities/filters';
import '~/utilities/vee-validate';
import BootstrapVue from 'bootstrap-vue';
import vue2Dropzone from 'vue2-dropzone';
import VueLogger from 'vuejs-logger';
import { ClientTable } from 'vue-tables-2';
import VueToast from 'vue-toast-notification';
import VueScrollactive from 'vue-scrollactive';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { Can, abilitiesPlugin } from '@casl/vue';
import styles from '~/vendor/main.scss';
import appworkMixins from '~/mixins/appwork';
import limitAccessPlugin from '@/components/layout/limitAccess/limitAccessPlugin';
import store from './store';
import router from './router';
import { i18n } from './i18n';
import App from './App.vue';

Vue.component('vue-dropzone', vue2Dropzone);
Vue.mixin(appworkMixins);
Vue.use(VueScrollactive);
Vue.use(BootstrapVue);

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
});
Vue.use(ClientTable);
Vue.use(VueToast);
Vue.use(limitAccessPlugin);
Vue.use(VueDOMPurifyHTML);

Vue.use(abilitiesPlugin, store.getters['currentOperator/ability'], {
  useGlobalProperties: true,
}).component(Can.name, Can);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['slot'];
Vue.config.ignoredWarnings = ['v-for takes higher priority'];

store.$log = Vue.$log;
store.$i18n = i18n;

new Vue({
  router,
  store,
  styles,
  i18n,
  render: h => h(App),
}).$mount('#app');
