var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":"System Countries","title-icon":"ios-build"}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"systemCountries/getDefaultCountrySettingsList","fields":_vm.fields,"data-parser":_vm.parseData,"default-sort-direction":"asc"},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":"outline-info"}},[_vm._v(" "+_vm._s(rowData.item.type)+" ")])]}},{key:"agreement1",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.agreement1 && rowData.item.agreement1.name)+" ")]}},{key:"agreement2",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.agreement2 && rowData.item.agreement2.name)+" ")]}},{key:"agreementOnEmail",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"ion",class:{
          'ion-md-checkmark text-primary': rowData.item.agreementOnEmail,
          'ion-md-close text-light': !rowData.item.agreementOnEmail
        }})]}},{key:"actions",fn:function(ref){
        var rowData = ref.rowData;
return [_c('Can',{attrs:{"i":_vm.ActionsTypes.UPDATE,"a":_vm.AbilitiesTypes.SYSTEM_CONFIG_COUNTRIES}},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":"Edit"},on:{"click":function($event){return _vm.editElement(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" Edit ")])],1)]}}])}),_c('cp-system-countries-modal',{ref:"CpSystemCountriesModal",attrs:{"country-data":_vm.countryData,"show-agreements-list":_vm.showAgreementsList},on:{"onUpdateCountry":_vm.updateCountry}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }