<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="container-fluid flex-grow-1 container-p-y opportunities-lobby h-100">
    <template
      v-if="isReady"
    >
      <div class="d-flex flex-row w-100 justify-content-between">
        <div class="w-50">
          <cp-configuration-head
            :page-name="$t('opportunities.title')"
          />
        </div>
        <div>
          <Can
            :i="ActionsTypes.CREATE"
            :a="AbilitiesTypes.ISSUER_OPPORTUNITIES"
          >
            <b-button
              v-if="opportunities && opportunities.length"
              size="lg"
              variant="outline-primary"
              @click="addOpportunityAction"
            >
              <i class="ion ion-ios-add-circle-outline" /> {{ $t('opportunities.opportunitiesList.addButton.label') }}
            </b-button>
          </Can>
        </div>
      </div>
      <template v-if="opportunities && opportunities.length">
        <div>
          <div
            v-for="(opportunity) in opportunities"
            :key="opportunity.id"
            :class="`card mt-5 opportunity-box ${opportunity.enabled ? 'en' : 'dis'}`"
          >
            <div class="row">
              <div class="col-1 order-panel">
                <div
                  style="position:relative; z-index:3"
                  class="d-flex h-100 flex-column  justify-content-md-center text-center"
                >
                  <cp-button
                    variant="outline-none"
                    size="lg"
                    @click="changeOrder(opportunity, true)"
                  >
                    <i class="ion ion-md-arrow-up" />
                  </cp-button>

                  <span>{{ opportunity.order }} </span>
                  <cp-button
                    variant="outline-none"
                    size="lg"
                    :disabled="opportunity.order < 1"
                    @click="changeOrder(opportunity, false)"
                  >
                    <i class="ion ion-md-arrow-down" />
                  </cp-button>
                </div>
              </div>
              <div class="col-11">
                <div class="row">
                  <div
                    class="col-md-3 box-img d-flex flex-column"
                  >
                    <div
                      :class="`img-container ${opportunity.enabled ? 'en' : 'dis'} flex-grow-1 h4 `"
                      :style="{ 'background-image': 'url(' +getImageUrl(opportunity) + ')' }"
                    >
                      <span :class="`badge m-3   badge-${opportunity.enabled ? 'success' : 'dark'}`">
                        {{ (opportunity.enabled) ? $t('opportunities.opportunitiesList.publishedText'): $t('opportunities.opportunitiesList.draftText') }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-7 p-3 box-data">
                    <div class="box-title">
                      <h3>
                        {{ opportunity.title }}
                      </h3>
                    </div>
                    <div
                      class="htm"
                      v-html="sanitize(opportunity.content)"
                    />
                    <div class="mt-3 action">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="d-flex">
                            <div
                              v-for="attr in opportunity.attributes"
                              :key="attr.value"
                              class="attribute-container"
                            >
                              <div
                                v-if="attr.content && attr.title"
                                class="attribute-item"
                              >
                                <p class="attribute-item__title">
                                  {{ attr.title }}
                                </p>
                                <p class="attribute-item__content">
                                  {{ attr.content }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 opportunity-actions-container">
                    <Can
                      :i="ActionsTypes.DELETE"
                      :a="AbilitiesTypes.ISSUER_OPPORTUNITIES"
                    >
                      <cp-button
                        variant="primary"
                        size="sm"
                        custom-class="delete"
                        icon="ion ion-ios-trash"
                        @click="verifyDeleteOpportunity(opportunity)"
                      >
                        {{ $t('opportunities.opportunitiesList.deleteButton.label') }}
                      </cp-button>
                    </Can>
                    <div class="d-flex flex-column justify-content-end align-items-end mx-3">
                      <Can
                        :i="ActionsTypes.UPDATE"
                        :a="AbilitiesTypes.ISSUER_OPPORTUNITIES"
                      >
                        <cp-button
                          v-if="opportunity.enabled"
                          class="m-1 opacity-5 w-100"
                          size="lg"
                          variant="dark"
                          @click="verifyPublishOpportunity(opportunity)"
                        >
                          {{ $t('opportunities.opportunitiesList.unPublishButton.label') }}
                        </cp-button>
                        <cp-button
                          v-if="!opportunity.enabled"
                          size="lg"
                          class="m-1 w-100"
                          variant="primary"
                          @click="verifyPublishOpportunity(opportunity)"
                        >
                          {{ $t('opportunities.opportunitiesList.publishButton.label') }}
                        </cp-button>
                      </Can>
                      <cp-button
                        class="m-1 w-100"
                        variant="outline-primary"
                        size="lg"
                        @click="viewDetails(opportunity)"
                      >
                        {{ $t('opportunities.opportunitiesList.detailButton.label') }}
                      </cp-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <cp-confirm-modal
          ref="cpConfirmDeleteModal"
          :ok-text="$t('opportunities.opportunitiesList.deleteConfirmation.deleteButton')"
          @onOk="runDeleteOpportunityRequest"
        >
          <div class="text-center">
            <h3>
              {{ $t('opportunities.opportunitiesList.deleteConfirmation.title') }}
              <span class="font-weight-light">'{{ opportunitySelected.title }}'</span>
            </h3>
            <p>
              {{ $t('opportunities.opportunitiesList.deleteConfirmation.content') }}
            </p>
          </div>
        </cp-confirm-modal>
        <cp-confirm-modal
          ref="cpConfirmPublishModal"
          :ok-text="(opportunitySelected.enabled)?$t('opportunities.opportunitiesList.unPublishConfirmation.unPublishButton'):$t('opportunities.opportunitiesList.publishConfirmation.publishButton')"
          @onOk="runUpblishOpportunityRequest"
        >
          <div class="text-center">
            <template v-if="!opportunitySelected.enabled">
              <h3>
                {{ $t('opportunities.opportunitiesList.publishConfirmation.title') }}
                <span class="font-weight-light">'{{ opportunitySelected.title }}'</span>
              </h3>
              <p>
                {{ $t('opportunities.opportunitiesList.publishConfirmation.content') }}
              </p>
            </template>
            <template v-if="opportunitySelected.enabled">
              <h3>
                {{ $t('opportunities.opportunitiesList.unPublishConfirmation.title') }}
                <span class="font-weight-light">'{{ opportunitySelected.title }}'</span>
              </h3>
              <p>
                {{ $t('opportunities.opportunitiesList.unPublishConfirmation.content') }}
              </p>
            </template>
          </div>
        </cp-confirm-modal>
      </template>

      <template v-if="!opportunities ||! opportunities.length">
        <div class="d-flex  h-75 justify-content-center align-items-center">
          <div class="text-center w-75">
            <img
              class="ne-image mb-3"
              :src="getImagePath('illustration.png')"
            >
            <h5> {{ $t('opportunities.opportunitiesList.noOpportunities.title') }}</h5>
            <p>{{ $t('opportunities.opportunitiesList.noOpportunities.content') }}</p>
            <Can
              :i="ActionsTypes.CREATE"
              :a="AbilitiesTypes.ISSUER_OPPORTUNITIES"
            >
              <b-button
                size="lg"
                variant="primary"
                @click="addOpportunityAction"
              >
                {{ $t('opportunities.opportunitiesList.noOpportunities.addButton.label') }}
              </b-button>
            </Can>
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="!isReady"
      class="row align-items-center mb-2 spinner"
    >
      <div class="col-md-12 text-center">
        <b-spinner class="align-middle" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { sanitize } from 'dompurify';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import CpConfigurationHead from '../../configuration-head';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import routeNames from '~/utilities/routes';
import CpMixPanel, { TrackingTypes } from '../../../../../mixins/mix-panel';

export default {
  name: 'OpportunitiesList',

  metaInfo: {
    title: 'Opportunities',
  },
  components: {
    Can,
    CpConfigurationHead,
    CpConfirmModal,
    CpButton,
  },
  mixins: [
    staticPathGenerationMixin,
    CpMixPanel,
  ],

  data() {
    return {
      isReady: false,
      opportunities: [],
      opportunitySelected: {},
      ActionsTypes,
      AbilitiesTypes,
    };
  },

  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo']),
    ...mapGetters('rounds', ['rounds']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.tokenId': function () {
      this.loadOpportunitiesList();
    },
  },
  mounted() {
    this.trackEvent(TrackingTypes.ISSUER_CONFIG_OPPORTUNITIES_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },
  async created() {
    this.loadOpportunitiesList();
  },
  methods: {
    ...mapActions('opportunities', ['getOpportunities', 'deleteOpportunity', 'publishOpportunity', 'changeOpportunityOrder']),
    ...mapActions('configToken', ['enableTokenFundraise', 'disableTokenFundraise']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    sanitize,
    getRound(roundId) {
      const founded = (this.rounds || []).filter(({ id }) => id === roundId);
      return ((founded && founded.length) ? founded[0] : {});
    },
    getToken(tokenId) {
      const founded = (this.issuerInfo.tokens || []).filter(({ id }) => id === tokenId);
      return ((founded && founded.length) ? founded[0] : {});
    },
    async loadOpportunitiesList() {
      this.isReady = false;
      const { data } = await this.getOpportunities({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId });
      this.opportunities = data;
      this.isReady = true;
    },
    verifyDeleteOpportunity(opportunity) {
      this.opportunitySelected = { ...opportunity };
      this.$refs.cpConfirmDeleteModal.show();
    },
    runDeleteOpportunityRequest() {
      this.deleteOpportunity({ issuerId: this.$route.params.idIssuer, opportunityId: this.opportunitySelected.id })
        .then(() => {
          this.loadOpportunitiesList();
        });
    },
    verifyPublishOpportunity(opportunity) {
      this.opportunitySelected = { ...opportunity };
      this.$refs.cpConfirmPublishModal.show();
    },
    runUpblishOpportunityRequest() {
      const opportunity = {
        enabled: !this.opportunitySelected.enabled,
        tokenId: this.opportunitySelected.tokenId,
      };
      this.publishOpportunity({ issuerId: this.$route.params.idIssuer, opportunityId: this.opportunitySelected.id, opportunity })
        .then(() => {
          this.loadOpportunitiesList();
        });
    },

    addOpportunityAction() {
      this.$router.push({ name: 'AddOpportunityModal' });
    },
    getImageUrl(opportunity) {
      return (opportunity && opportunity.image) ? opportunity.image : this.getImagePath('fallback-image.jpg');
    },
    changeOrder(opportunity, value) {
      const order = ((value) ? { order: opportunity.order + 1 } : { order: opportunity.order - 1 });
      this.changeOpportunityOrder({ issuerId: this.$route.params.idIssuer, opportunityId: opportunity.id, opportunity: order })
        .then(() => {
          this.loadOpportunitiesList();
        });
    },
    viewDetails({ issuerId, tokenId, id }) {
      const route = routeNames.assetContent(issuerId, tokenId);
      this.$router.push({ path: route, query: { id } });
    },

  },
};
</script>

<style lang="scss" scoped>
  .spinner {
    min-height: 60vh;
  }
  .opportunities-lobby{
    .opportunity-box{
      height: 260px;
    .order-panel{
      font-size: 1.4rem;
    }
    .box-title {
      h3 {
        font-size: 28px;
        font-weight: 500;
        color: #617d8d;
      }
    }
    .box-img{
      height: 260px;
      .img-container{
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 0;
        height: 100%;

        .badge {
          min-width: 80px;
          font-size: 12px;
          font-weight: 500;
          line-height: 0.83;
          padding: 0.75em 1.5em;
        }
      }
    }
      .box-data {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      .htm {
          max-height: 80px;
          overflow: hidden;
        }

        .action {
          min-height: 67px;
        }
      }
    }
    .ne-image{
      height:150px;
    }
  }

  .opportunity-actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    align-items: flex-end;

    .delete {
      width: 40%;
      margin-right: 2em;
    }

    .btn-dark {
      background-color: rgba(10, 23, 39, 0.5);
    }
  }

  .attribute-container {
    max-width: 25%;
    .attribute-item {
      display: flex;
      flex-direction: column;
      margin-right: 1em;
      border-right: 1px solid #d9d8d8;
      padding-right: 1em;
      color: #0a1828;

      &__title, &__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

       &__title {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.38;
      }

      &__content {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }
</style>
