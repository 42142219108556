<template>
  <div>
    <div v-show="isReady">
      <cp-investors-head
        :title="getTitle"
        :country-codes="getCountryCodes"
        :can-create-investor="$can(ActionsTypes.CREATE, AbilitiesTypes.ONBOARDING)"
        :show-import-investors="false"
        :show-import-issuance="$can(ActionsTypes.CREATE, AbilitiesTypes.ONBOARDING)"
      />
      <cp-fundraise-filter
        ref="fundraiseFilter"
        @updateFilter="updateFilter"
      />
      <cp-fundraise-table
        v-if="$refs.fundraiseFilter"
        :ref="tableRef"
        :filters="tableFilters"
        :rounds="rounds"
        @input="handleRoundSelect"
        @onViewElem="toViewPage"
        @onExportList="exportInvestorsList"
        @onRemoveElem="handlerRemoveInvestor"
        @onSort="saveSort"
        @onSelectInvestor="selectInvestor"
        @onEditLabels="editInvestorsLabels"
        @onShowLabels="showFilterLabelsModal"
        @onSelectAll="selectAllInvestors"
        @onMassSelect="handleMassSelect"
      />
    </div>

    <div
      v-show="!isReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t("investorsFundraise.loading") }}
      </div>
    </div>
    <cp-labels-modal
      ref="CpLabelsModal"
      :modal-id="modalId"
      :result-filter-labels="resultFilterLabels"
      :selected-invest="isSelectable"
      :error-field="errorField"
      :hide-create-label="hideCreateLabel"
      :predefined-labels="resultFilterPredefinedLabels"
      @onApplyEditLabels="handleEditLabels"
      @onApplyFilterLabels="handleFilterLabels"
      @onHide="errorField = false"
      @onCreateLabel="handleCreateLabel"
      @searchLabel="handleSearchLabel"
      @onDeleteLabel="handleDeleteLabel"
    />

    <cp-remove-investor
      ref="removeInvestModal"
      :item="deleteItem"
      @deleteIsApproved="deleteIsApproved"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';
import CpLabelsModal from '~/pages/_idIssuer/investors/modals/labelModal';
import CpFundraiseTable from './components/table';
import CpFundraiseFilter from './components/filter';
import CpInvestorsHead from '~/pages/_idIssuer/investors/head-investors';
import CpRemoveInvestor from '~/pages/_idIssuer/investors/remove-investor';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';
import GeneralActionsOfInvestorPages from '~/mixins/general-actions-of-investor-pages';

import routeNames from '~/utilities/routes';

export default {
  name: 'InvestorsFundraise',
  metaInfo: {
    title: 'Fundraise',
  },
  components: {
    CpFundraiseTable,
    CpInvestorsHead,
    CpLabelsModal,
    CpFundraiseFilter,
    CpRemoveInvestor,
  },
  mixins: [GeneralActionsOfInvestorPages, CpMixPanel],
  data() {
    return {
      selectedRound: null,
      tableRef: 'fundraiseTable',
      filterRef: 'fundraiseFilter',
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('investors', ['getInvestorById', 'getSorting', 'labelsList']),
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapGetters('rounds', ['rounds']),
    ...mapGetters('global', ['countries']),
    ...mapState('predefinedLabels', ['predefinedLabels']),
    investorsType() {
      return {
        issuerId: this.$route.params.idIssuer,
        view: 'fundraise',
        'round-id': this.selectedRound,
      };
    },
    getTitle() {
      const res = this.rounds.find(({ id }) => id === this.selectedRound);
      return res
        ? this.$t('investorsFundraise.titleWithRoundName', [res.name])
        : this.$t('investorsFundraise.label.allRounds');
    },
  },
  async mounted() {
    this.trackEvent(TrackingTypes.FUNDRAISE_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  created() {
    this.getSystemPredefinedLabels();
  },
  methods: {
    ...mapActions('investors', [
      'exportList',
      'getIssuerLabels',
      'labelsBatchOperation',
      'deleteInvestorByUserId',
      'deleteIssuerLabels',
    ]),
    ...mapActions('predefinedLabels', ['getSystemPredefinedLabels']),
    ...mapMutations('investors', [
      'SET_INVESTOR_SORTING',
      'SET_LABELS_LIST',
      'CREATE_NEW_LABEL',
    ]),
    handleRoundSelect(round) {
      this.selectedRound = round;
    },
    toViewPage({ id: idFundraise }, $event) {
      const { params: { idIssuer, tokenId }, query: { search } } = this.$route;
      const routeData = this.$router.resolve(routeNames.viewDetails(idIssuer, tokenId, idFundraise, 'fundraise', search));
      if ($event.metaKey) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
    },
  },
};
</script>
