var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-table',{ref:"cpTable",attrs:{"default-sort-by":"totalTokens","default-sort-direction":"desc","checkbox":_vm.$can(_vm.ActionsTypes.UPDATE, _vm.AbilitiesTypes.HOLDERS_INVESTOR),"mass-selectable":_vm.$can(_vm.ActionsTypes.UPDATE, _vm.AbilitiesTypes.HOLDERS_INVESTOR),"filters":_vm.params,"get-data-action":_vm.getUrl,"url-params":_vm.urlParams,"fields":_vm.calculatedTableFields,"data-parser":_vm.listCorrection,"table-title":_vm.$t('holders.title'),"i18n-values":[_vm.mainCurrency]},on:{"tableDataUpdated":_vm.onTableDataUpdated,"onSort":function($event){return _vm.$emit(_vm.onSort, $event)},"onSelectAll":function($event){return _vm.onSelectAll($event)},"onSelect":function($event){return _vm.$emit('onSelectInvestor', $event)},"onMassSelect":_vm.onMassSelect},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [(!_vm.isCustodianHolders(rowData.item))?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('onViewElem', rowData.item, $event)}}},[_vm._v(" "+_vm._s(_vm.getFullName(rowData.item))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getFullName(rowData.item))+" ")])]}},{key:"countryCode",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-country-name',{attrs:{"country-code":rowData.item.countryCode,"locale":_vm.locale}})]}},{key:"investorType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.investorTypeValue)+" ")]}},{key:"totalTokens",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-ellipsis',{attrs:{"data-value":_vm.formatToken(rowData.item.totalTokensToDisplay),"tooltip-value":_vm.formatToken(rowData.item.totalTokensToDisplay)}})]}},{key:"walletAddress",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.walletAddress)+" "),(_vm.isWalletsCountShowing(rowData.item.walletsCount))?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(("+" + (rowData.item.walletsCount - 1)))+" ")]):_vm._e()]}},{key:"walletRegistrationStatus",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.item.walletRegistrationStatusValue)?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":rowData.item.walletRegistrationStatusStyle}},[_vm._v(" "+_vm._s(rowData.item.walletRegistrationStatusValue)+" ")]):_vm._e()]}},{key:"percentage",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"no-break"},[_c('cp-ellipsis',{staticClass:"no-break",attrs:{"data-value":rowData.item.percentage}},[_vm._v(" % ")])],1)]}},{key:"labels",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isLabels(rowData.item))?_c('div',[_vm._l((_vm.getLabelsList(rowData.item)),function(label){return _c('span',{key:label},[(label)?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()],1)}),(rowData.item.labels.length > 3)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.getLabelsTitle(rowData.item)}},[_vm._v(" + "+_vm._s(rowData.item.labels.length - 3)+" ")]):_vm._e()],2):_vm._e()]}},{key:"custodianManagementActions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex",style:({ width: '56px' })},[_c('Can',{attrs:{"i":_vm.ActionsTypes.UPDATE,"a":_vm.AbilitiesTypes.HOLDERS_INVESTOR}},[(!rowData.item.isAggregatedBrokerDealerGroup)?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat"},on:{"click":function($event){return _vm.handleUpdateCustodian(rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-md-create"})]):_vm._e()],1),_c('Can',{attrs:{"i":_vm.ActionsTypes.DELETE,"a":_vm.AbilitiesTypes.HOLDERS_DELETE_INVESTOR}},[_c('cp-button',{attrs:{"disabled":"","variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.$emit('onRemoveElem', rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-ios-trash"})])],1)],1)]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex",style:({ width: '56px' })},[(
          !_vm.isCustodianHolders(rowData.item) &&
            !rowData.item.isAggregatedBrokerDealerGroup
        )?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat"},on:{"click":function($event){return _vm.handleActionClick(rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-ios-eye"})]):_vm._e(),_c('Can',{attrs:{"i":_vm.ActionsTypes.DELETE,"a":_vm.AbilitiesTypes.HOLDERS_DELETE_INVESTOR}},[(
            !_vm.isCustodianHolders(rowData.item) &&
              !rowData.item.isAggregatedBrokerDealerGroup
          )?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.$emit('onRemoveElem', rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-ios-trash"})]):_vm._e()],1)],1)]}}])},[(_vm.showTableHeaderActions)?_c('template',{slot:"tableHeader"},[_c('cp-batch-actions',{attrs:{"disabled":_vm.isEmptyExportList,"can-export-list":_vm.$can(_vm.ActionsTypes.CREATE, _vm.AbilitiesTypes.HOLDERS_EXPORT_LIST),"can-edit-labels":_vm.$can(_vm.ActionsTypes.UPDATE, _vm.AbilitiesTypes.HOLDERS_EDIT_LABELS),"can-show-labels":_vm.$can(_vm.ActionsTypes.READ, _vm.AbilitiesTypes.HOLDERS_SHOW_LABELS)},on:{"onExportList":function($event){return _vm.$emit('onExportList')},"onEditLabels":function($event){return _vm.$emit('onEditLabels')},"onShowLabels":function($event){return _vm.$emit('onShowLabels')}}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }