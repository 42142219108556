export default {
  alertsCount: state => state.notificationsCount,
  notifications: state => state.notifications,
  viewData: state => state.viewData,
  operatorData: state => state,
  authorizationLevel: state => state.authorizationLevel,
  authorizations: state => state.authorizations,
  isAuthenticated: state => state.isAuthenticated,
  authLevel: ({ authorizations }) => authorizations.map(item => ({ value: item, text: item }) || []),
  securitizeIdAlertsCount: state => state.securitizeIdNotificationsCount,
  securitizeIdNotifications: state => state.securitizeIdNotifications,
  ability: state => state.ability,
};
