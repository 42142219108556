import _MixPanel from 'mixpanel';

const MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN;

let mixPanel = null;

if (MIXPANEL_TOKEN) {
  mixPanel = _MixPanel.init(MIXPANEL_TOKEN);
} else {
  console.warn('MixPanel token is not provided. MixPanel will not be initialized.');
}

const SUPER_PROPERTIES = [
  'email',
  'name',
  'id',
  'idUUID',
  'authorizationLevel',
];
const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const getUTMData = () => {
  const query = new URLSearchParams(window.location.href.split('?')[1]);
  return UTM_PARAMS.filter(item => query.has(item)).reduce(
    (acc, item) => ({ [item]: query.get(item), ...acc }),
    {},
  );
};

const getRelevantSuperProperties = userData => Object.keys(userData)
    .filter(key => SUPER_PROPERTIES.includes(key))
    .reduce((acc, item) => ({ [item]: userData[item], ...acc }), {});

// eslint-disable-next-line import/prefer-default-export
export const MixPanel = {
  alias: (operatorIdUUID) => {
    // eslint-disable-next-line no-unused-expressions
    mixPanel && mixPanel.alias(operatorIdUUID);
  },
  identify: (userData) => {
    const superProperties = getRelevantSuperProperties(userData);

    // eslint-disable-next-line no-unused-expressions
    mixPanel && mixPanel.people.set(userData.idUUID, { ...superProperties });
  },
  track: (event, data = {}) => {
    const utmData = getUTMData();

    // eslint-disable-next-line no-unused-expressions
    mixPanel && mixPanel.track(event, { ...data, ...utmData });
  },
  people: {
    set_once: (operatorIdUUID) => {
      // eslint-disable-next-line no-unused-expressions
      mixPanel && mixPanel.people.set_once({
        operatorId: operatorIdUUID,
      });
    },
  },
};
