<template>
  <div class="card mb-5">
    <cp-card-head
      :card-name="$t('configurationJurisdictions.restrictedJurisdictions.title')"
      :edit-mode="editMode"
      :disable-edit="Boolean(editableCard) || !$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_JURISDICTIONS)"
      :without-edit="!$can(ActionsTypes.UPDATE, AbilitiesTypes.ISSUER_JURISDICTIONS)"
      @onSaveChanges="saveChanges"
      @onChangeModeProxy="checkEditBlock"
    />

    <div class="card-body">
      {{ $t('configurationJurisdictions.restrictedJurisdictions.message.description') }}
    </div>
    <hr class="my-0 mx-0">
    <div
      v-if="!editMode"
    >
      <div class="card-body">
        <div class="row">
          <div
            v-for="(countryName, index) in viewRestrictedCountries"
            :key="index"
            class="col-md-3"
          >
            <span class="ion ion-md-close text-danger" /> {{ countryName }}
          </div>
        </div>
      </div>
      <hr class="my-0 mx-0">
      <div class="card-body">
        <div class="row">
          <div
            v-for="({name}, index) in viewRestrictedStates"
            :key="index"
            class="col-md-3"
          >
            <span class="ion ion-md-close text-danger" /> {{ name }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-dual-list-box
        show-search
        :source="getCountryList"
        :destination="getRestCountries"
        :labels="countryBoxNames"
        @onChangeList="onCountryChangeList"
      />
      <cp-dual-list-box
        class="lower-box-select"
        show-search
        :source="allowedStatesAndRegions"
        :destination="stateCodeAndName"
        :labels="stateBoxNames"
        @onChangeList="onStateChangeList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';
import CpCardHead from './card-head';
import CpDualListBox from '~/components/common/standalone-components/cp-dual-list-box';
import CpJurisdictionEditMode from '~/mixins/jurisdiction-edit-mode';
import { getCountryName } from '~/utilities/i18n-util';

export default {
  name: 'Jurisdictions',
  components: {
    CpCardHead,
    CpDualListBox,
  },
  mixins: [
    CpJurisdictionEditMode('restricted'),
  ],
  data() {
    return {
      locale: null,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('global', ['countries']),
    ...mapGetters('jurisdictions', ['jurisdictionsCountries', 'jurisdictionsStatesAndRegions', 'restrictedJurisdictions']),
    ...mapState('jurisdictions', ['restrictedJurisdictionsState']),
    ...mapState('global', ['statesAndRegions']),
    viewRestrictedCountries() {
      return this.restrictedJurisdictions.map(code => getCountryName(code, this.locale));
    },
    viewRestrictedStates() {
      if (!this.restrictedJurisdictionsState.length || !this.statesAndRegions.length) return [];
      return this.restrictedJurisdictionsState.map(restrictedStateCode => this.statesAndRegions.find(stateAndRegion => stateAndRegion.code === restrictedStateCode));
    },
    countryBoxNames() {
      return {
        left: this.$t('configurationJurisdictions.restrictedJurisdictions.label.countries'),
        right: this.$t('configurationJurisdictions.restrictedJurisdictions.label.restrictedCountries'),
      };
    },
    stateBoxNames() {
      return {
        left: this.$t('configurationJurisdictions.restrictedJurisdictions.label.state'),
        right: this.$t('configurationJurisdictions.restrictedJurisdictions.label.restrictedState'),
      };
    },
    getCountryList() {
      const list = [];
      this.countries.forEach(({ countryCode, name }) => {
        if (!this.restrictedJurisdictions.includes(countryCode)) {
          list.push(
            {
              text: name,
              value: countryCode,
            },
          );
        }
      });
      return list;
    },
    allowedStatesAndRegions() {
      const statesRegionsList = [];
      this.jurisdictionsStatesAndRegions.forEach(({ countryName, stateAndRegionName, stateAndRegionCode }) => {
        if (!this.restrictedJurisdictionsState.includes(stateAndRegionCode)) {
          statesRegionsList.push({
            text: `${countryName} - ${stateAndRegionName}`,
            value: stateAndRegionCode,
          });
        }
      });
      return statesRegionsList;
    },
    getRestCountries() {
      return [
        ...((this.restrictedJurisdictions || []).map((code) => {
          const countryName = getCountryName(code, this.locale);
          return {
            text: countryName,
            value: code,
          };
        })),
      ];
    },
    stateCodeAndName() {
      return this.restrictedJurisdictionsState.map((restrictedStateCode) => {
        const stateCodeAndName = this.statesAndRegions.find(({ code }) => code === restrictedStateCode);
        const countryName = getCountryName(stateCodeAndName.countryCode, this.locale);
        return {
          value: restrictedStateCode,
          text: `${countryName} - ${stateCodeAndName.name}`,
        };
      });
    },
  },
  created() {
    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });
    const issuerId = this.$route.params.idIssuer;
    this.getJurisdictionsStatesAndRegions(issuerId);
  },
  methods: {
    ...mapActions('jurisdictions', ['getJurisdictionsStatesAndRegions']),
    initData() {
      this.model.restrictedJurisdictions = [...this.restrictedJurisdictions];
      this.model.restrictedJurisdictionsState = this.restrictedJurisdictionsState;
    },
    onCountryChangeList({ destination }) {
      this.model.restrictedJurisdictions = destination.map(item => item.value);
    },
    onStateChangeList({ destination }) {
      this.model.restrictedJurisdictionsState = destination.map(item => item.value);
    },
  },
};
</script>
<style>
  .lower-box-select {
    margin-top: 30px;
  }
  .spacing-top-bottom {
    margin-top: 24px;
    margin-bottom: 24px;
  }
</style>
