import { i18n } from '~/i18n';
import axiosV2 from '~/utilities/axiosV2';
import kebabToCamel from '~/utilities/convert-object-keys';

const orderDirectionMapper = {
  asc: 'ASC',
  desc: 'DESC',
};

export default {
  getPrivacyControlOperators({ commit }, { params }) {
    const convertedParams = kebabToCamel(params);
    const { issuerId, ...filterParams } = convertedParams;
    const url = `/isr/api/v1/issuers/${issuerId}/privacy-control`;

    filterParams.orderDirection = orderDirectionMapper[filterParams.orderDirection];
    filterParams.offset = filterParams.page * filterParams.limit;
    delete filterParams.page;
    return axiosV2.get(url, { params: filterParams, headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        const dataBuilt = {
          data: res.data.data,
          totalItems: res.data.meta.total,
        };
        commit('SET_PRIVACY_CONTROL_OPERATORS', dataBuilt);
        return dataBuilt;
      })
      .catch((err) => {
        this.$log.error('privacy control operators GET error:', err);
        return err;
      });
  },

  addPrivacyControlOperator({ commit, rootState }, { issuerId, body }) {
    const url = `/isr/api/v1/issuers/${issuerId}/privacy-control`;
    return axiosV2.post(url, body, { headers: { 'Content-Type': 'application/json' } })
    .then((res) => {
      const { data } = res;
        commit('global/CALL_TOASTER', i18n.t('store.toaster.privacyControl.success.addOperator'), { root: true });
        const currentOperatorEmail = rootState.currentOperator.email;
        if (data.email === currentOperatorEmail) {
          commit('issuersInfo/SET_CAN_OPERATOR_VIEW_EDIT_INVESTOR_INFO', true, { root: true });
        }
    })
    .catch((err) => {
        this.$log.error('privacy control operator POST error:', err);
        throw err;
    });
  },

  deletePrivacyControlOperator({ commit }, { issuerId, operatorId }) {
    const url = `/isr/api/v1/issuers/${issuerId}/privacy-control/${operatorId}`;
    return axiosV2.delete(url, { headers: { 'Content-Type': 'application/json' } })
    .then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.privacyControl.success.deleteOperator'), { root: true });
    })
    .catch((err) => {
        this.$log.error('privacy control operator DELETE error:', err);
        throw err;
    });
  },
};
