<template>
  <cp-table
    ref="cpTable"
    get-data-action="operators/getOperatorsList"
    :filters="filters"
    :fields="tableFields"
    :search-value="searchQueryParam"
    :data-parser="convertedInitList"
    @onSort="$emit(onSort, $event)"
  >
    <template
      slot="authorizationLevel"
      slot-scope="{ rowData }"
    >
      <b-badge variant="outline-info">
        {{ rowData.item.authorizationLevel }}
      </b-badge>
    </template>
    <template
      v-if="isMegaadmin"
      slot="organization"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.organization }}
    </template>
    <template
      slot="enabled"
      slot-scope="{ rowData }"
    >
      <b-badge :variant="rowData.item.enabled ? 'success' : 'danger'">
        {{ rowData.item.enabled | activeInactive }}
      </b-badge>
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <Can
        :i="ActionsTypes.UPDATE"
        :a="AbilitiesTypes.PANEL_ADMINISTRATION"
      >
        <b-btn
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          :disabled="!canEditOperator(rowData.item)"
          @click="$emit('onEdit', rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t("common.button.edit") }}
        </b-btn>
        <b-btn
          v-if="canRedirectHSM()"
          variant="default btn-xs md-btn-flat"
          :title="$t('operators.button.operators.hsm')"
          @click="$emit('onHSM', rowData.item)"
        >
          <i
            class="ion ion-md-lock"
            :style="{ color: '#04bec4' }"
          />
          {{ $t("operators.button.operators.hsm") }}
        </b-btn>
        <b-btn
          v-if="canResetAuthentication(rowData.item)"
          :disabled="
            disableEditOperator || operatorData.email === rowData.item.email
          "
          variant="default btn-xs md-btn-flat"
          :title="$t('operators.button.operators.reset')"
          @click="$emit('onReset', rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t("operators.button.operators.reset") }}
        </b-btn>
        <b-btn
          v-if="canEnableDisableOperator(rowData.item)"
          variant="default btn-xs md-btn-flat"
          :title="getActivationStatus(rowData.item.enabled)"
          :disabled="operatorData.email === rowData.item.email"
          @click="$emit('onChangeStatus', rowData.item)"
        >
          <i class="ion ion-md-switch" />
          {{ getActivationStatus(rowData.item.enabled) }}
        </b-btn>
      </Can>
    </template>
  </cp-table>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../utilities/ability/abilities';
import CpTable from '~/components/shared/cp-table';
import {
  operatorFields,
  createEditOperatorsAcceptedAuthorizationLevels,
} from '../fields';

export default {
  name: 'CpOperatorsTable',
  components: {
    Can,
    CpTable,
  },
  props: {
    disableEditOperator: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      operatorFields,
      searchQueryParam: this.$route.query.search || undefined,
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapState('organizations', ['organizationsList']),
    tableFields() {
      let tableFields = this.operatorFields;
      if (this.isBrokerDealer) {
        tableFields = tableFields.filter(
          item => item.key !== 'authorizationLevel',
        );
      }
      if (!this.isMegaadmin) {
        tableFields = tableFields.filter(item => item.key !== 'organization');
      }
      return tableFields;
    },
    isMegaadmin() {
      return (
        this.operatorData
        && this.operatorData.authorizationLevel === 'megaadmin'
      );
    },
    isBrokerDealer() {
      return (
        this.operatorData
        && this.operatorData.authorizationLevel === 'brokerdealer'
      );
    },
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    convertedInitList(data) {
      return data.map((item) => {
        const operator = { ...item };
        const operatorOrganization = this.organizationsList.find(
          organization => organization.id === operator.organizationId,
        );
        operator.organization = operatorOrganization && operatorOrganization.name;
        operator.lastLoginAt = operator.lastLoginAt
          ? moment(operator.lastLoginAt).format('ll')
          : this.$t('operators.label.never');
        operator.createdAt = moment(operator.createdAt).format('ll');
        operator.lastLoginIp = operator.lastLoginIp || '-';
        return operator;
      });
    },
    getActivationStatus(enabled) {
      return enabled
        ? this.$t('common.button.deactivate')
        : this.$t('common.button.activate');
    },
    updateTableData() {
      this.$refs.cpTable.updateTableData();
    },
    isSameOperator(operator) {
      return (
        this.disableEditOperator || this.operatorData.email === operator.email
      );
    },
    canEditOperator(operator) {
      const isAllowedByAuthorizationLevel = createEditOperatorsAcceptedAuthorizationLevels[
          this.operatorData.authorizationLevel
        ].includes(operator.authorizationLevel);
      if (this.isSameOperator(operator)) {
        return false;
      }

      if (this.operatorData.organizationId && this.operatorData.organizationId !== process.env.VUE_APP_SECURITIZE_ORGANIZATION_ID) {
        return (
          this.isSameOrganization(operator) && isAllowedByAuthorizationLevel
        );
      }
      return isAllowedByAuthorizationLevel;
    },
    canResetAuthentication(operator) {
      return this.isMegaadmin || this.isSameOrganization(operator);
    },
    canEnableDisableOperator(operator) {
      return (
        this.isMegaadmin
        || !this.operatorData.organizationId
        || this.isSameOrganization(operator)
      );
    },
    isSameOrganization(operator) {
      const securitizeOrganizationId = process.env.VUE_APP_SECURITIZE_ORGANIZATION_ID;

      return (
        this.operatorData.organizationId
        && (this.operatorData.organizationId === securitizeOrganizationId
          || this.operatorData.organizationId === operator.organizationId)
      );
    },
    canRedirectHSM() {
      return this.operatorData.authorizations.includes('hsm-controller');
    },
  },
};
</script>
