export const transactionTypes = {
  issuance: 'issueDetails.typeSelect.issuance',
  reallocation: 'issueDetails.typeSelect.reallocation',
  'transfer-ok': 'issueDetails.typeSelect.transferOk',
  'transfer-rejected': 'issueDetails.typeSelect.transferRejected',
  reclaim: 'issueDetails.typeSelect.reclaim',
  destroy: 'issueDetails.typeSelect.destroy',
  freeze: 'issueDetails.typeSelect.freeze',
  unfreeze: 'issueDetails.typeSelect.unfreeze',
};
export const tableFields = {
  authorizer: {
    key: 'authoriser', i18nKey: 'issueDetails.table.authorizer', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  createdAt: {
    key: 'createdAt', i18nKey: 'issueDetails.table.date', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  operator: {
    key: 'operator.name', i18nKey: 'issueDetails.table.operator', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  securitiesAuthorizedUpdate: {
    key: 'securitiesAuthorizedUpdate', i18nKey: 'issueDetails.table.securitiesAuthorizedUpdate', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  securitiesIssuedUpdate: {
    key: 'securitiesIssuedUpdate', i18nKey: 'issueDetails.table.securitiesIssuedUpdate', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  updateType: {
    key: 'updateType', i18nKey: 'issueDetails.table.updateType', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  closedTimestamp: {
    key: 'closedTimestamp', i18nKey: 'issueDetails.table.closedTimestamp', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  transactionType: {
    key: 'type', i18nKey: 'issueDetails.table.type', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  quantity: {
    key: 'amount', i18nKey: 'issueDetails.table.quantity', sortable: false, tdClass: 'text-right', thClass: 'border-top-0  text-right pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  sender: {
    key: 'sender.investorName', i18nKey: 'issueDetails.table.sender', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  receiver: {
    key: 'receiver.investorName', i18nKey: 'issueDetails.table.receiver', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  ethTransactionId: {
    key: 'ethTxId', sortable: false, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { width: '209px' },
  },
};

export const tableColumns = {
  controlBookRecords: [
    tableFields.createdAt,
    tableFields.authorizer,
    tableFields.operator,
    tableFields.securitiesAuthorizedUpdate,
    tableFields.securitiesIssuedUpdate,
    tableFields.updateType,
  ],
  tokenTransactions: [
    tableFields.closedTimestamp,
    tableFields.transactionType,
    tableFields.quantity,
    tableFields.sender,
    tableFields.receiver,
    tableFields.ethTransactionId,
  ],
};
const BlockchainNetworkEnum = {
  ALGORAND: 'algorand',
  ALGORAND_ASC_TESTNET: 'algorand-asc-testnet',
  ALGORAND_TESTNET: 'algorand-testnet',
  ARBITRUM: 'arbitrum',
  AVALANCHE: 'avalanche',
  AVALANCHE_TEST: 'avalanche-test',
  BASE: 'base',
  BESU_TEST: 'besu-test',
  ETHEREUM: 'ethereum',
  ETHEREUM_GOERLI: 'ethereum-goerli',
  ETHEREUM_KOVAN: 'ethereum-kovan',
  ETHEREUM_RINKEBY: 'ethereum-rinkeby',
  ETHEREUM_ROPSTEN: 'ethereum-ropsten',
  ETHEREUM_SEPOLIA: 'ethereum-sepolia',
  GANACHE: 'ganache',
  GOERLI: 'goerli',
  OPTIMISM: 'optimism',
  POLYGON: 'polygon',
  QUORUM: 'quorum',
  QUORUM_TEST: 'quorum-test',
  QUORUM_NTT: 'quorum-ntt',
  QUORUM_SBI: 'quorum-sbi',
  XDC: 'xdc',
  GINCO: 'ginco',
  OQI: 'oqi',
  ZKSYNC: 'zksync',
};

export const BLOCKCHAIN_NETWORK_MAPPER = {
  [BlockchainNetworkEnum.ALGORAND]: 'ALG',
  [BlockchainNetworkEnum.ALGORAND_ASC_TESTNET]: 'ALG-ASC-TEST',
  [BlockchainNetworkEnum.ALGORAND_TESTNET]: 'ALG-TEST',
  [BlockchainNetworkEnum.ARBITRUM]: 'ARB',
  [BlockchainNetworkEnum.AVALANCHE]: 'AVA',
  [BlockchainNetworkEnum.AVALANCHE_TEST]: 'AVA-TEST',
  [BlockchainNetworkEnum.BASE]: 'BAS',
  [BlockchainNetworkEnum.BESU_TEST]: 'BES-TEST',
  [BlockchainNetworkEnum.ETHEREUM]: 'ETH',
  [BlockchainNetworkEnum.ETHEREUM_GOERLI]: 'ETH-GOE',
  [BlockchainNetworkEnum.ETHEREUM_KOVAN]: 'ETH-KOV',
  [BlockchainNetworkEnum.ETHEREUM_RINKEBY]: 'ETH-RIN',
  [BlockchainNetworkEnum.ETHEREUM_ROPSTEN]: 'ETH-ROP',
  [BlockchainNetworkEnum.ETHEREUM_SEPOLIA]: 'ETH-SEP',
  [BlockchainNetworkEnum.GANACHE]: 'GAN',
  [BlockchainNetworkEnum.GOERLI]: 'GOE',
  [BlockchainNetworkEnum.OPTIMISM]: 'OPT',
  [BlockchainNetworkEnum.POLYGON]: 'POL',
  [BlockchainNetworkEnum.QUORUM]: 'QUO',
  [BlockchainNetworkEnum.QUORUM_TEST]: 'QUO-TEST',
  [BlockchainNetworkEnum.QUORUM_NTT]: 'QUO-NTT',
  [BlockchainNetworkEnum.QUORUM_SBI]: 'QUO-SBI',
  [BlockchainNetworkEnum.XDC]: 'XDC',
  [BlockchainNetworkEnum.GINCO]: 'QUO',
  [BlockchainNetworkEnum.OQI]: 'OQI',
  [BlockchainNetworkEnum.ZKSYNC]: 'ZK',
};
