<template>
  <div>
    <div v-if="ready">
      <cp-configuration-head
        :page-name="$t('configurationFundraiseToken.title')"
        class="header-element"
      >
        <div class="d-flex align-items-center justify-content-end">
          <Can
            :i="ActionsTypes.CREATE"
            :a="AbilitiesTypes.CONFIGURATION_TOKEN"
          >
            <cp-button
              size
              :title="$t('configurationFundraiseToken.addTokenButtonText')"
              @click="openAddTokenModal"
            >
              {{ $t('configurationFundraiseToken.addTokenButtonText') }}
            </cp-button>
          </Can>
          <Can
            :i="ActionsTypes.UPDATE"
            :a="AbilitiesTypes.CONFIGURATION_TOKEN"
          >
            <cp-button
              v-if="isOnChainManager"
              size
              class="ml-4"
              @click="openTokenManagement"
            >
              {{
                $t(
                  'configurationFundraiseToken.tokenDetails.button.manageToken'
                )
              }}
            </cp-button>
          </Can>
        </div>
      </cp-configuration-head>

      <add-token-modal
        ref="addTokenModalRef"
        @on-token-added="onNewTokenAdded"
      />
      <cp-token-general-properties
        :enable-token-lifecycle-management="
          tokenConfig.enableTokenLifecycleManagement
        "
        :enable-fundraise="tokenConfig.enableFundraise"
      />
      <cp-token-details
        ref="details"
        :token-configuration="tokenConfiguration"
        @on-edit-activated="onEditActivated('details')"
        @onTokenValueChange="handleTokenValueChanges"
      />
    </div>
    <div
      v-else
      class="row align-items-center mb-2 spinner"
    >
      <div class="col-md-12 text-center">
        <b-spinner class="align-middle" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';
import CpConfigurationHead from '../configuration-head';
import CpTokenGeneralProperties from './components/general-properties';
import CpTokenDetails from './components/token-details';
import AddTokenModal from './manage-token/components/add-token-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import routeNames from '@/utilities/routes';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'FundraiseToken',

  metaInfo: {
    title: 'Token',
  },

  components: {
    Can,
    CpButton,
    CpTokenGeneralProperties,
    CpTokenDetails,
    AddTokenModal,
    CpConfigurationHead,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      ready: false,
      ActionsTypes,
      AbilitiesTypes,
    };
  },

  computed: {
    ...mapGetters({
      tokenConfiguration: 'configuration/getTokenConfiguration',
    }),
    ...mapGetters('currentOperator', ['operatorData']),
    tokenConfig() {
      return this.tokenConfiguration || {};
    },
    isOnChainManager() {
      return this.operatorData.authorizations.find(
        auth => auth === 'on-chain-manager',
      );
    },
  },

  watch: {
    $route: {
      handler(to, from) {
        if (!from) {
          this.ready = false;
          this.getTokenConfigurationData();
          this.getTokenDeploymentTokenData();
        } else if (to.params.tokenId !== from.params.tokenId) {
          this.$router.push({ query: { search: undefined } });
          this.getTokenConfigurationData();
          this.getTokenDeploymentTokenData();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.trackEvent(TrackingTypes.CONFIG_TOKEN_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  methods: {
    ...mapActions('configuration', ['getTokenConfiguration']),
    ...mapActions('configToken', ['getTokenDeployment']),
    onEditActivated(type) {
      return type !== 'details' && this.$refs.details.cancelIfActive();
    },
    openTokenManagement($event) {
      const { idIssuer, tokenId } = this.$route.params;
      const routeData = this.$router.resolve(
        routeNames.manageToken(idIssuer, tokenId),
      );
      if ($event.metaKey) {
        window.open(routeData.href);
      } else {
        this.$router.push(routeData.href);
      }
    },
    onNewTokenAdded(newTokenId) {
      const { idIssuer: issuerId } = this.$route.params;
      window.location.pathname = routeNames.tokenConfiguration(
        issuerId,
        newTokenId,
      );
      // TODO remove hard refresh and fix token refresh and nav issue missing id issue
      // this.getTokenConfiguration({
      //   issuerId,
      //   tokenId: newTokenId,
      // })
      //   .finally(() => {
      //     this.ready = true;
      //   });
    },
    openAddTokenModal() {
      this.$refs.addTokenModalRef.show();
    },
    getTokenConfigurationData() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.getTokenConfiguration({
        issuerId,
        tokenId,
      }).finally(() => {
        this.ready = true;
      });
    },
    getTokenDeploymentTokenData() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.getTokenDeployment({ tokenId, issuerId }).then();
    },
    handleTokenValueChanges() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.getTokenConfiguration({
        issuerId,
        tokenId,
      });
    },
  },
};
</script>

<style scoped>
.spinner {
  min-height: 60vh;
}
</style>
