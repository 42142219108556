var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":"System Emails","title-icon":"ios-build"}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"systemEmails/getEmailTemplates","fields":_vm.fields},scopedSlots:_vm._u([{key:"from",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{attrs:{"href":("mailto:" + (rowData.item.from))}},[_vm._v(" "+_vm._s(rowData.item.from)+" ")])]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.createdAt)))])]}},{key:"updatedAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.updatedAt)))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('Can',{attrs:{"i":_vm.ActionsTypes.UPDATE,"a":_vm.AbilitiesTypes.SYSTEM_CONFIG_EMAIL_TEMPLATES}},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":"Edit"},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" Edit ")])],1)]}}])}),_c('cp-system-emails-modal',{ref:"CpSystemEmail",attrs:{"title-data":_vm.titleData,"email-data":_vm.emailData},on:{"onSaveChanges":function($event){return _vm.saveChanges($event, 'updateEmailTemplateById')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }