<template>
  <div>
    <cp-head-table
      title="Issuer Configuration variables"
      title-icon="ios-build"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerConfigurationVariables/getIssuerConfigurationVariables"
      :url-params="urlParams"
      :fields="fields"
      :filters="filtersData"
    >
      <template
        slot="name"
        slot-scope="{ rowData }"
      >
        <a
          href="#"
          @click.prevent="toEditModal(rowData.item)"
        >
          {{ rowData.item.name }}
        </a>
      </template>
      <template
        slot="active"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{ 'ion-md-checkmark text-primary': rowData.item.active, 'ion-md-close text-light': !rowData.item.active }"
        />
      </template>
      <template
        slot="access"
        slot-scope="{ rowData }"
      >
        <b-badge
          variant="outline-info"
        >
          {{ rowData.item.access }}
        </b-badge>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <Can
          :i="ActionsTypes.UPDATE"
          :a="AbilitiesTypes.ISSUER_CONFIGURATION_VARIABLES"
        >
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            title="Edit"
            @click="toEditModal(rowData.item)"
          >
            <i
              class="ion ion-md-create"
              :style="{ color: '#04bec4' }"
            />
            Edit
          </b-btn>
        </Can>
      </template>
    </cp-table>

    <cp-variable-modal
      ref="CpVariableModal"
      :title-data="titleData"
      :variable-data="modalData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Can } from '@casl/vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';
import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
// import CpFilters from '~/components/common/filters';
import CpVariableModal from './issuer-config-variable-modal';
import fields from './fields';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'Configuration',
  metaInfo: {
    title: 'Configuration variables',
  },

  components: {
    Can,
    CpTable,
    CpHeadTable,
    // CpFilters,
    CpVariableModal,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      fields,
      filterFields: {
        access: {
          label: 'Access',
          type: 'dropdown',
          options: [
            { text: 'Public', value: 'public' },
            { text: 'Private', value: 'private' },
          ],
        },
        active: {
          label: 'Active',
          type: 'checkbox',
        },
      },
      filtersData: {},
      modalData: {},
      titleData: {},
      urlParams: { issuerId: this.$route.params.idIssuer },
      requiresEndpointRefreshMap: {
        'privacy-control': {
          params: { issuerId: this.$route.params.idIssuer },
          action: 'issuersInfo/getPermissionsPerIssuer',
        },
      },
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  mounted() {
    this.trackEvent(TrackingTypes.ADVANCED_ISSUER_CONFIGURATION_VARIABLES_LAND, {
      issuerId: this.$route.params.idIssuer,
    });
  },
  methods: {
    ...mapActions('issuerConfigurationVariables', [
      'getById',
      'update',
    ]),

    toEditModal({ id }) {
      this.titleData = {
        type: 'Edit',
        icon: 'md-create',
      };

      this.getById({
        issuerId: this.$route.params.idIssuer,
        id,
      }).then((data) => {
        this.modalData = data;
        this.$refs.CpVariableModal.$refs.issuerVariable.show();
      });
    },

    saveChanges({ action, configVariable }) {
      this[action]({
        issuerId: this.$route.params.idIssuer,
        configVariable,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.CpVariableModal.$refs.issuerVariable.hide();
      }).then(() => {
        const endpointUpdate = this.requiresEndpointRefreshMap[configVariable.name];
        if (action === 'update' && endpointUpdate) this.$store.dispatch(endpointUpdate.action, endpointUpdate.params);
      });
    },
  },
};
</script>
