<template>
  <div>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mb-5"
      @click="$router.push({ name: 'OpportunitiesList'})"
    >
      {{ $t('brokerDealer.opportunitiesPage.buttons.backToList') }}
    </b-button>
    <div class="d-flex header-block mb-4 justify-content-between">
      <div class="d-flex">
        <h4 class="font-weight-bold mb-0">
          {{ $t('brokerDealer.opportunitiesPage.assetContentTitle') }}
        </h4>
        <div class="d-lg-block text-big font-weight-light mx-1">
          |
        </div>
        <h4 class="font-weight-light">
          {{ model.title || '-' }}
        </h4>
      </div>
    </div>
    <edit-tabs />
    <template v-if="isReady">
      <router-view />
    </template>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import EditTabs from '../components/edit-tabs';
import CpMixPanel, { TrackingTypes } from '../../../../../mixins/mix-panel';

export default {
  name: 'EditOpportunityMain',
  metaInfo: {
    title: 'Asset content',
  },
  components: {
    EditTabs,

  },
  mixins: [CpMixPanel],
  data() {
    return {
      isReady: false,
      model: {},
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if (!from || (to.params.tokenId !== from.params.tokenId)) {
          this.loadLanguages();
          this.loadOpportunity();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.trackEvent(TrackingTypes.CONFIG_ASSET_CONTENT_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  methods: {
    ...mapActions('opportunities', ['getOpportunityById', 'createOpportunity', 'getOpportunitiesByToken', 'getLanguages']),
    ...mapMutations('opportunities', ['SET_CURRENT_OPPORTUNITY']),
    onEditActivated(type) {
      return type !== 'details' && this.$refs.details.cancelIfActive();
    },
    async loadOpportunity() {
      this.isReady = false;

      const { data } = this.$route.query.id ? await this.getOpportunityById({
        issuerId: this.$route.params.idIssuer, opportunityId: this.$route.query.id, includeEmptyFields: true, additionalFields: 'attributes,documents,contents,languages', language: 'EN',
      })
        : await this.getOpportunitiesByToken({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId });

      if (Array.isArray(data)) {
        const { data: response } = data.length
          ? await this.getOpportunityById({
            issuerId: this.$route.params.idIssuer,
            opportunityId: data.sort((first, second) => new Date(second.createdAt).getTime() - new Date(first.createdAt).getTime())[0].id,
            includeEmptyFields: true,
            additionalFields: 'attributes,documents,contents,languages',
            language: 'EN',
          })
          : await this.createOpportunity({
            issuerId: this.$route.params.idIssuer,
            opportunity: { tokenId: this.$route.params.tokenId, title: 'Default' },
          });
        this.SET_CURRENT_OPPORTUNITY(response);
        this.model = response;
      } else {
        this.model = data;
      }

      this.isReady = true;
    },
    async loadLanguages() {
      await this.getLanguages({ issuerId: this.$route.params.idIssuer });
    },
  },
};
</script>
