var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":_vm.$t('issuerCurrencies.title'),"title-icon":"ios-build"}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"issuerCurrencies/getIssuerCurrencies","url-params":_vm.urlParams,"fields":_vm.fields},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":"outline-info"}},[_vm._v(" "+_vm._s(_vm.$t(("issuerCurrencies.status." + (rowData.item.type))))+" ")])]}},{key:"active",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"ion",class:{
          'ion-md-checkmark text-primary': rowData.item.active,
          'ion-md-close text-light': !rowData.item.active
        }})]}},{key:"actions",fn:function(ref){
        var rowData = ref.rowData;
return [_c('Can',{attrs:{"i":_vm.ActionsTypes.UPDATE,"a":_vm.AbilitiesTypes.ISSUER_CURRENCIES}},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.edit')},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t('common.button.edit'))+" ")])],1)]}}])}),_c('cp-currency-modal',{ref:"CpIssuerCurrency",attrs:{"modal-data":_vm.modalData},on:{"onSubmit":_vm.saveChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }