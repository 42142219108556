import { SID } from '@securitize/domain';
import countries from 'i18n-iso-countries';

const disableNewEntityTypes = process.env.VUE_APP_DISABLE_NEW_ENTITY_TYPES === 'true';
const availableEntityTypes = [
  SID.InvestorEntityTypes.REVOCABLE_TRUST,
  SID.InvestorEntityTypes.IRREVOCABLE_TRUST,
  SID.InvestorEntityTypes.LIMITED_GENERAL_PARTNERSHIP,
  SID.InvestorEntityTypes.LLC,
  SID.InvestorEntityTypes.CORPORATION,
  SID.InvestorEntityTypes.FUND,
  ...(disableNewEntityTypes ? [] : [
    SID.InvestorEntityTypes.FOR_PROFIT_FOUNDATION,
    SID.InvestorEntityTypes.NON_PROFIT_FOUNDATION,
  ]),
  SID.InvestorEntityTypes.OTHER,
];

const getCountriesList = () => {
  const countriesObject = countries.getNames('en');
  return Object.keys(countriesObject)
    .map(countryCode => ({
      value: countryCode,
      text: countriesObject[countryCode],
    }));
};
export const legalSignerOptionsList = {
  genderTypesList: [
    {
      value: 'male',
      text: 'Male',
    },
    {
      value: 'female',
      text: 'Female',
    },
  ],
  signerType: [
    {
      value: 'individual',
      text: 'Individual',
    },
    {
      value: 'entity',
      text: 'Entity',
    },
  ],
  entityTypesList: availableEntityTypes.map(value => ({
    value,
    i18nKey: `securitizeId.entityType["${value}"]`,
  })),
  uboOptionsList: [
    { text: 'No', value: 'none' },
    { text: 'Yes (10%+)', value: '10+' },
    { text: 'Yes (25%+)', value: '25+' },
  ],
  isLegalSignerList: [
    { text: 'No', value: false },
    { text: 'Yes', value: true },
  ],
};
export const optionsList = {
  riskLevelTypesList: [
    {
      value: 'none',
      text: 'None',
    },
    {
      value: 'low',
      text: 'Low',
    },
    {
      value: 'medium',
      text: 'Medium',
    },
    {
      value: 'high',
      text: 'High',
    },
  ],
  genderTypesList: [
    {
      value: 'male',
      text: 'Male',
    },
    {
      value: 'female',
      text: 'Female',
    },
  ],
  investorsTypesList: [
    {
      value: 'entity',
      text: 'Entity Account',
    },
    {
      value: 'individual',
      text: 'Individual Account',
    },
    {
      value: 'hidden-individual',
      text: 'Individual Without an Account',
    },
  ],
  individualInvestorTypes: [
    {
      value: false,
      text: 'Individual Account',
    },
    {
      value: true,
      text: 'Individual Without an Account',
    },
  ],
  entityTypesList: availableEntityTypes.map(value => ({
    value,
    i18nKey: `securitizeId.entityType["${value}"]`,
  })),
  phoneIsVerifiedList: [
    { text: 'Not Verified', value: false },
    { text: 'Verified', value: true },
  ],
};

export const countriesList = getCountriesList();

export const tableFields = [
  { key: 'checkbox', tdClass: 'text-nowrap align-middle' },
  {
    key: 'investorName', label: 'Name / Entity', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '200px', maxWidth: '200px' }, tdClass: 'align-middle text-break',
  },
  {
    key: 'countryCode', label: 'Country', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '90px' }, tdClass: 'align-middle',
  },
  {
    key: 'investorType', label: 'Type', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '50px' }, tdClass: 'align-middle',
  },
  {
    key: 'kycStatus', label: 'KYC Status', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '110px' }, tdClass: 'align-middle',
  },
  {
    key: 'issuers', label: 'Issuers', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '100px' }, tdClass: 'align-middle',
  },
  {
    key: 'labels', label: 'Labels', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '50px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'createdAt', label: 'Creation date', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '120px' }, tdClass: 'align-middle',
  },
  {
    key: 'lastLoggedIn', label: 'Logged in', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '97px' }, tdClass: 'align-middle',
  },
  {
    key: 'kycStatusChangeDate', label: 'Last Updated', sortable: true, thClass: 'text-nowrap', thStyle: { minWidth: '117px' }, tdClass: 'align-middle',
  },
  {
    key: 'assignedAttorneyOperatorId', label: 'Assignee', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '140px' }, tdClass: 'align-middle text-center',
  },
  { key: 'actions', label: '', thClass: 'text-nowrap', tdClass: 'align-middle' },
];

export const kycStatuses = {
  none: 'badge-grey',
  processing: 'badge-orange',
  verified: 'badge-green',
  'updates-required': 'badge-orange',
  'manual-review': 'badge-yellow',
  rejected: 'badge-red',
  expired: 'badge-orange',
};

export const filterLists = {
  investorType: [
    { text: 'Individual', value: 'individual' },
    { text: 'Entity', value: 'entity' },
  ],
  kycStatus: [
    { text: 'None', value: 'none' },
    { text: 'Processing', value: 'processing' },
    { text: 'Update required', value: 'updates-required' },
    { text: 'Verified', value: 'verified' },
    { text: 'Manual Review', value: 'manual-review' },
    { text: 'Rejected', value: 'rejected' },
    { text: 'Expired', value: 'expired' },
    { text: 'Verified[documents-expired]', value: 'verified-documents-expired' },
    { text: 'Verified[expired]', value: 'verified-expired' },
    { text: 'Enhance Requested', value: 'enhance-requested' },
    { text: 'Enhance Verified', value: 'enhance-verified' },
    { text: 'Rejected[blocked]', value: 'rejected-blocked' },
  ],
  verificationErrors: [
    { text: 'Forgery', value: 'FORGERY' },
    { text: 'Bad selfie', value: 'BAD_SELFIE' },
    { text: 'Bad selfie matching', value: 'BAD_FACE_MATCHING' },
    { text: 'Bad POA', value: 'BAD_PROOF_OF_ADDRESS' },
    { text: 'Unsatisfactory photos', value: 'UNSATISFACTORY_PHOTOS' },
    { text: 'Screenshots', value: 'SCREENSHOTS' },
    { text: 'Black and white', value: 'BLACK_AND_WHITE' },
    { text: 'Graphic editor', value: 'GRAPHIC_EDITOR' },
    { text: 'Document page missing', value: 'DOCUMENT_PAGE_MISSING' },
    { text: 'Invalid ID', value: 'ID_INVALID' },
    { text: 'Document damaged', value: 'DOCUMENT_DAMAGED' },
    { text: 'Problematic data', value: 'PROBLEMATIC_APPLICANT_DATA' },
    { text: 'Spam', value: 'SPAM' },
    { text: 'Wrong address', value: 'WRONG_ADDRESS' },
    { text: 'Front side missing', value: 'FRONT_SIDE_MISSING' },
    { text: 'Back side missing', value: 'BACK_SIDE_MISSING' },
    { text: 'Incompatible language', value: 'INCOMPATIBLE_LANGUAGE' },
    { text: 'Document expired', value: 'EXPIRATION_DATE' },
    { text: 'Regulations violations', value: 'REGULATIONS_VIOLATIONS' },
    { text: 'Age requirement mismatch', value: 'AGE_REQUIREMENT_MISMATCH' },
    { text: 'Duplicate', value: 'DUPLICATE' },
    { text: 'Wrong user region', value: 'WRONG_USER_REGION' },
    { text: 'Bad ID proof', value: 'BAD_PROOF_OF_IDENTITY' },
    { text: 'Blacklist', value: 'BLACKLIST' },
    { text: 'Selfie mismatch', value: 'SELFIE_MISMATCH' },
    { text: 'Fraudulent patters', value: 'FRAUDULENT_PATTERNS' },
    { text: 'Compromised person', value: 'COMPROMISED_PERSONS' },
    { text: 'Pep', value: 'PEP' },
    { text: 'Sanctions', value: 'SANCTIONS' },
    { text: 'Criminal', value: 'CRIMINAL' },
    { text: 'Adverse media', value: 'ADVERSE_MEDIA' },
  ],
};

export const kyc = {
  statuses: [
    { value: 'none', i18nKey: 'securitizeId.kyc.option.status.none' },
    { value: 'processing', i18nKey: 'securitizeId.kyc.option.status.processing' },
    { value: 'updates-required', i18nKey: 'securitizeId.kyc.option.status.updateRequired' },
    { value: 'verified', i18nKey: 'securitizeId.kyc.option.status.verified' },
    { value: 'verified-documents-expired', i18nKey: 'securitizeId.kyc.option.status.verifiedDocumentsExpired' },
    { value: 'verified-expired', i18nKey: 'securitizeId.kyc.option.status.verifiedExpired' },
    { value: 'rejected-blocked', i18nKey: 'securitizeId.kyc.option.status.rejectedBlocked' },
    { value: 'manual-review', i18nKey: 'securitizeId.kyc.option.status.manualReview' },
    { value: 'rejected', i18nKey: 'securitizeId.kyc.option.status.rejected' },
    { value: 'expired', i18nKey: 'securitizeId.kyc.option.status.expired' },
  ],
};

export const kyb = {
  statuses: [
    { value: 'none', i18nKey: 'securitizeId.kyc.option.status.none' },
    { value: 'processing', i18nKey: 'securitizeId.kyc.option.status.processing' },
    { value: 'manual-review', i18nKey: 'securitizeId.kyc.option.status.manualReview' },
    { value: 'updates-required', i18nKey: 'securitizeId.kyc.option.status.updateRequired' },
    { value: 'verified', i18nKey: 'securitizeId.kyc.option.status.verified' },
    { value: 'enhance-verified', i18nKey: 'securitizeId.kyc.option.status.enhanceVerified' },
    { value: 'rejected', i18nKey: 'securitizeId.kyc.option.status.rejected' },
    { value: 'expired', i18nKey: 'securitizeId.kyc.option.status.expired' },
  ],
};

export const kycLogTableFields = [
  { key: 'date', label: 'Date', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'kycStatus', label: 'KYC Status', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'operator', label: 'Operator', sortable: true, tdClass: 'align-middle w-25' },
];

export const updatesRequiredTypes = {
  ADDITIONAL_LEGAL_SIGNERS: 'ADDITIONAL_LEGAL_SIGNERS',
  ADDITIONAL_DOCUMENTATION: 'ADDITIONAL_DOCUMENTATION',
  COMMENT_TO_INVESTOR_COMMENT: 'COMMENT_TO_INVESTOR_COMMENT',
  LEGAL_SIGNERS_MISSING_INFO: 'LEGAL_SIGNERS_MISSING_INFO',
};
