<template>
  <div
    id="investor-engagement"
    :class="{'vertical-center': !enabledFeature && !isLoading}"
  >
    <div v-if="enabledFeature">
      <cp-loading-modal
        ref="viewSendedModal"
        :message="message"
        :is-loading="isLoading"
        :modal-icon="modalIcon"
      />
      <cp-general-modal
        ref="cpConfirmModal"
        :ok-text="$t('outreach.dashboard.message.confirmationButton')"
        :hide-header="true"
        size="sm"
        @onOk="resetDomain"
      >
        {{ $t('outreach.dashboard.message.confirmation') }}
      </cp-general-modal>
      <cp-outreach-head
        :title="$t('outreach.title')"
        :domain="savedDomain"
        :is-authenticated="savedIsAuthenticated"
        @onDelete="confirmationResetDomain"
      />
      <out-tabs
        v-model="selectedTab"
        :tabs="tabs"
      />
      <br>
      <cp-outreach-dashboard
        v-if="selectedTab == 'dashboard'"
        ref="dashboard"
      />
      <cp-outreach-contacts
        v-else-if="selectedTab == 'contacts'"
      />
      <cp-outreach-email
        v-else-if="selectedTab == 'send-email'"
      />
    </div>
    <div
      v-if="isLoading"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('outreach.loading') }}
      </div>
    </div>
    <div
      v-if="!enabledFeature && !isLoading"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center vertical-center">
        <div>
          <cp-hubspot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { has } from 'lodash';
import OutTabs from './components/shared/out-tabs';
import CpOutreachHead from './components/head';
import CpOutreachDashboard from './components/domain/index';
import CpOutreachContacts from './components/contacts/index';
import CpOutreachEmail from './components/email/index';
import CpLoadingModal from './components/shared/loading-modal';
import CpHubspot from './components/hubspot';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';

export default {
  name: 'Outreach',
  metaInfo: {
    title: 'Investor Engagement',
  },
  components: {
    OutTabs,
    CpOutreachHead,
    CpOutreachDashboard,
    CpOutreachContacts,
    CpOutreachEmail,
    CpLoadingModal,
    CpGeneralModal,
    CpHubspot,
  },
  mixins: [CpMixPanel],
  data() {
    return {
      selectedTab: '',
      isLoading: false,
      message: '',
      modalIcon: '',
    };
  },
  computed: {
    ...mapGetters('outreach', ['getSavedDomain', 'getIsAuthenticated', 'getOutreachIsEnabled']),
    savedDomain() {
      return this.getSavedDomain;
    },
    enabledFeature() {
      return this.getOutreachIsEnabled;
    },
    savedIsAuthenticated() {
      return this.getIsAuthenticated;
    },
    tabs() {
      return [
        { text: this.$t('outreach.label.tab.dashboard'), value: 'dashboard', enabled: true },
        { text: this.$t('outreach.label.tab.contacts'), value: 'contacts', enabled: true },
        { text: this.$t('outreach.label.tab.sendEmail'), value: 'send-email', enabled: this.savedIsAuthenticated, tooltip: this.$t('outreach.label.tab.tooltipEmail') },
      ];
    },
  },
  async mounted() {
    this.trackEvent(TrackingTypes.INVESTOR_ENGAGEMENT_CONTACT_SALES_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  beforeMount() {
    this.getDomainData();
  },
  methods: {
    ...mapActions('outreach', ['validateDomain', 'deleteDomain', 'clearSavedDomain', 'getDomain', 'saveDomainData']),
    getDomainData() {
      this.isLoading = true;
      this.getDomain({ issuerId: this.$route.params.idIssuer }).then(({ data }) => {
        if (has(data, 'isAuthenticated')) {
          this.saveDomainData({ domain: `@${data.name}`, isAuthenticated: data.isAuthenticated });
        } else {
          this.clearSavedDomain();
        }
        this.isLoading = false;
      }).catch(
        () => {
          this.isLoading = false;
        },
      );
    },
    confirmationResetDomain() {
      this.$refs.cpConfirmModal.show();
    },
    resetDomain() {
      this.$refs.cpConfirmModal.hide();
      this.isLoading = true;
      this.$refs.viewSendedModal.$refs.viewSendedModal.show();
      this.deleteDomain({ issuerId: this.$route.params.idIssuer }).then(() => {
        this.message = this.$t('outreach.dashboard.message.reset');
        this.modalIcon = 'ion-md-checkmark-circle';
        this.isLoading = false;
        this.clearSavedDomain();
        this.getDomainData();
      })
        .catch(() => {
          this.message = 'Error in reset domain';
          this.modalIcon = 'ion-md-close-circle';
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

</style>
