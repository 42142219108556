<template>
  <div>
    <cp-head-table
      :title="$t('issuerList.title')"
      :show-button="showAddIssuerButton"
      title-icon="ios-business"
      button-icon="ios-add-circle-outline"
      :button-title="checkAuthLevel ? $t('issuerList.button.issuer.create') : null"
      @onCreateElem="showCreateIssuerModal"
    />
    <b-card
      no-body
      class="mb-4 search-card"
    >
      <b-card-body class="search-wrapper">
        <i18n
          path="component.cpTableControls.label.show"
          tag="div"
          class="col inner-flex"
        >
          <b-select
            v-model="queryParams.limit"
            size="sm"
            :options="limitOptions"
            class="w-auto limit-select"
            @change="limitIssuers"
          />
        </i18n>
        <b-input-group>
          <b-input
            v-model="queryParams.search"
            size="m"
            :placeholder="$t('issuerList.searchByName')"
            @input="searchIssuers"
          />
        </b-input-group>

        <sec-id-multi-select
          ref="labels"
          :options="labelsToFilter"
          :placeholder="$t('issuerList.filterByLabel')"
          badge-class="label-filter-badge"
          name="labels"
          :searchable="true"
          class="filter-labels-component"
          :default-selected="((queryParams && queryParams.labels) || []).map(label => ({value: label, name: label}))"
          @filtersChanged="filterLabels"
        />
      </b-card-body>
    </b-card>
    <div
      v-if="isLoaded"
      class="row"
    >
      <div
        v-for="issuer in filteredIssuers"
        :key="issuer.id"
        class="col-sm-6 col-md-6 col-xl-4"
      >
        <cp-issuer-box
          :issuer="issuer"
          @onViewIssuer="toIssuerDashboard"
          @onRemoveIssuer="removeIssuerModal"
          @onManageLabels="manageIssuerLabelsModal"
        />
      </div>
      <div
        v-if="isError"
        class="col-12 text-center"
      >
        <b-alert
          variant="danger mt-3"
          show
        >
          {{ $t('issuerList.message.error') }}
        </b-alert>
      </div>
      <div
        v-else
        class="col-12 text-center mt-4"
      >
        <div v-if="!filteredIssuers.length">
          {{ $t('issuerList.message.noIssuers') }}
        </div>
      </div>
    </div>
    <cp-pagination
      v-show="isLoaded && showPaginationBar"
      :active-page="currentPage"
      :total-items="issuerList.totalItems"
      :per-page="queryParams.limit"
      @onChangePage="changePage"
    />
    <div
      v-if="!isLoaded"
      class="row"
    >
      <div class="col-12 text-center mt-4">
        {{ $t('issuerList.message.loading') }}
      </div>
    </div>
    <cp-create-issuer-modal
      ref="CpCreateIssuerModal"
      @onCreateIssuer="createNewIssuer"
    />
    <modal
      ref="deleteIssuerModal"
      :title="$t('issuerList.deleteIssuerModal.title')"
      :ok-disabled="isDeleteDisabled"
      @onOk="handleDelete"
    >
      <i18n
        path="issuerList.deleteIssuerModal.message"
        tag="span"
      >
        <template v-slot:name>
          <strong>{{ issuerToDeleteName }}</strong>
        </template>
      </i18n>
      <div>&nbsp;</div>
      <cp-input
        :input-class="deleteInputClass"
        @paste.prevent
        @input="handleDeleteInput"
      />
    </modal>
    <cp-issuer-labels-modal
      ref="CpIssuerLabelsModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import _ from 'lodash';
import Cookies from 'js-cookie';
import CpPagination from '~/components/common/table/pagination';
import CpCreateIssuerModal from '~/components/common/modals/create-issuer-modal';
import Modal from '~/components/common/modals-components/general-modal';
import CpIssuerBox from '~/components/issuer-list/issuerBox';
import CpHeadTable from '~/components/common/head';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpIssuerLabelsModal from '~/components/issuer-list/issuerLabelsModal';
import SecIdMultiSelect from '@/pages/securitize-id/components/sec-id-multiselect';
import CpMixPanel, { TrackingTypes } from '../../mixins/mix-panel';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import routeNames from '~/utilities/routes';

export default {
  name: 'IssuerList',
  components: {
    Modal,
    CpInput,
    CpIssuerBox,
    CpHeadTable,
    CpCreateIssuerModal,
    CpPagination,
    CpIssuerLabelsModal,
    SecIdMultiSelect,
  },
  metaInfo: {
    title: 'Issuer list',
  },
  mixins: [staticPathGenerationMixin, CpMixPanel],
  data() {
    return {
      isError: false,
      isLoaded: false,
      issuerSelectedToDelete: null,
      modalInput: '',
      currentPage: 1,
      limitOptions: [
        { value: 6, text: '6' },
        { value: 9, text: '9' },
        { value: 12, text: '12' },
        { value: 15, text: '15' },
        { value: 999, text: 'All' },
      ],
      queryParams: {
        page: 0,
        limit: (Cookies.get('issuerListPerPage') && parseInt(Cookies.get('issuerListPerPage'))) || 6,
        search: this.$route.query && this.$route.query.search ? this.$route.query.search : null,
        labels: this.$route.query && this.$route.query.labels ? Array.isArray(this.$route.query.labels) ? this.$route.query.labels : [this.$route.query.labels] : [],
      },
      deleteProcess: false,
    };
  },
  computed: {
    ...mapState('issuerList', ['issuerList']),
    ...mapState('issuerLabels', ['issuerLabels', 'labels']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    labelsToFilter() {
      return this.labels.map(label => ({ value: label, text: label }));
    },
    deleteInputClass() {
      if (this.isDeleteDisabled && this.modalInput !== '') {
        return 'border border-danger';
      }
      return '';
    },
    showAddIssuerButton() {
      return this.authorizationLevel !== 'brokerdealer';
    },
    issuerToDeleteName() {
      return this.issuerSelectedToDelete ? this.issuerSelectedToDelete.name : null;
    },
    isDeleteDisabled() {
      return this.issuerSelectedToDelete && this.issuerSelectedToDelete.name.toLowerCase() !== this.modalInput.toLowerCase();
    },
    filteredIssuers() {
      return (this.issuerList && this.issuerList.data) || [];
    },
    checkAuthLevel() {
      return this.authorizationLevel !== 'basic' && this.authorizationLevel !== 'admin';
    },
    showPaginationBar() {
      return this.issuerList.totalItems > this.queryParams.limit;
    },
  },
  created() {
    this.isLoaded = Boolean((this.issuerList && this.issuerList.data));
  },
  async mounted() {
    if (window.Intercom) {
      const { company, ...theRest } = window.intercomSettings;
      window.intercomSettings = {
        ...theRest,
        company: {
          id: 'issuerList',
          name: 'Issuer List',
        },
      };
      window.Intercom('update');
    }
    await this.fetchIssuerLabels();

    this.trackEvent(TrackingTypes.ISSUER_LIST_LAND);
  },
  methods: {
    ...mapActions('issuerList', ['createIssuer', 'removeIssuer']),
    ...mapActions('issuerLabels', ['fetchLabelsForIssuer', 'fetchIssuerLabels']),
    ...mapActions('global', ['fetchIssuersList']),
    ...mapMutations('issuerList', ['SELECTED_ISSUER']),
    filterLabels(selectedLabels) {
      this.queryParams.labels = selectedLabels.filters.map(label => label.value);
      return this.searchFunction();
    },
    handleDeleteInput(input) {
      this.modalInput = input;
    },
    async handleDelete() {
      if (this.issuerSelectedToDelete && !this.deleteProcess) {
        this.deleteProcess = true;
        await this.removeIssuer(this.issuerSelectedToDelete.id).then(() => {
          this.getIssuers(this.queryParams);
          this.$refs.deleteIssuerModal.hide();
          this.deleteProcess = false;
        });
      }
    },
    onHide() {
      this.issuerSelectedToDelete = null;
    },
    async manageIssuerLabelsModal(issuer) {
      // this is going to be removed on future iteration
      await this.fetchLabelsForIssuer(issuer.id);
      this.$refs.CpIssuerLabelsModal.show({ ...issuer, labels: this.issuerLabels });
    },
    removeIssuerModal(issuer) {
      this.issuerSelectedToDelete = issuer;
      this.$refs.deleteIssuerModal.show();
    },
    showCreateIssuerModal() {
      this.$refs.CpCreateIssuerModal.$refs.createIssuerModal.show();
    },
    createNewIssuer(params) {
      this.isLoaded = false;
      this.createIssuer(params).then((issuerId) => {
        if (issuerId) {
          // reload the page to the new issuer. let everything to refresh
          this.$refs.CpCreateIssuerModal.$refs.createIssuerModal.hide();
          window.location.href = routeNames.generalConfiguration(issuerId);
        } else {
          this.isLoaded = true;
        }
      });
    },
    toIssuerDashboard(issuer) {
      this.SELECTED_ISSUER(issuer);
      window.location.href = routeNames.dashboard(issuer.id, issuer.defaultTokenId);
    },
    getIssuers(params) {
      this.isLoaded = false;
      params.view = 'issuer-list';
      this.fetchIssuersList(params).finally(() => { this.isLoaded = true; });
    },
    searchFunction() {
      this.queryParams.page = undefined;
      if (!this.search) {
        this.queryParams.search = undefined;
        this.search = undefined;
      }

      let query = { ...this.$route.query, search: this.search };
      if (this.queryParams.labels.length) {
        query = { ...query, labels: this.queryParams.labels };
      } else {
        query = { ...query, labels: [] };
      }
      this.$router.replace({ query });
      this.getIssuers(this.queryParams);
    },
    // eslint-disable-next-line func-names
    debounceSearch: _.debounce(function () {
      return this.searchFunction();
    }, 500),
    searchIssuers(search) {
      search = search.trim();
      this.search = search;
      this.debounceSearch();
    },
    limitIssuers(limit) {
      Cookies.set('issuerListPerPage', limit);
      this.queryParams.limit = limit;
      this.queryParams.page = 0;
      this.currentPage = 1;

      this.getIssuers(this.queryParams);
    },
    changePage(page) {
      this.currentPage = page;
      this.queryParams.page = page - 1;

      this.getIssuers(this.queryParams);
    },
  },
};
</script>
<style lang="scss">
  .search-wrapper {
    display: flex;
    .inner-flex {
      margin-right: 8px;
      display: flex;
      align-items: center;
    }
    .limit-select {
      margin: 0 6px;
    }
  }

  .filter-labels-component{
    margin-left: 30px;
    min-height: calc(1.54em + 0.876rem + 2px);
    font-size: 0.894rem;
    font-weight: 400;
    line-height: 1.54;
    color: #627d8e;
  }

  .label-filter-badge{
    padding: 0.1em 0.3em;
    margin: 0 0 0 0.1em;
    font-size: 14px;
    color: #627d8e;
  }
</style>
